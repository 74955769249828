import React, { Component } from 'react';

import { tableRows } from './services/constant';

import Manage                         from './components/Manage';
import { PaginationViewModel }        from '../../../../platform/view-models';
import AppPageToolbar                 from '../../../../platform/components/PageToolbar';
import AppTable                       from '../../../../platform/components/table/Table';
import TableToolbar, { ViewModeEnum } from '../../../../platform/components/table/TableToolbar';
import MediaService                   from '../../../../platform/api/media';
import Modal                          from '../../../../platform/components/Modal';
import Closer                         from '../../../../platform/components/Closer';
import settings from '../../../../platform/services/settings';

export default class Videos extends Component {

  state = {
    itemList              : [],
    selectedItemsId       : [],
    pageCount             : 0,
    modifyModalOpen       : false,
    modifyData            : null,
    viewMode              : ViewModeEnum.Rows,
    itemCount             : 0,
    loading               : false,
    detailsData           : null,
    manageDatePickerState : false,
  }

  paginationData;

  componentDidMount() {
    settings.emtyAllPageNos();
    this.paginationData = new PaginationViewModel();
    this.getItemList();
  }

  getItemList = () => this.setState({ loading: true }, async () => {
    const { viewMode, itemList } = this.state;
    const res = await MediaService.VideoList(this.paginationData.getBaseModel());
    res.success && res.data && res.data.itemList && this.setState({
      selectedItemsId : [],
      itemList        : viewMode === ViewModeEnum.Items ? [...itemList, ...res.data.itemList] : res.data.itemList,
      itemCount       : res.data.itemCount,
      pageCount       : res.data.pageCount,
      loading         : false,
    });
  });
  
  changePage = page => {
    this.paginationData.pageNo = page;
    this.getItemList();
  }

  changeLimit = limit => {
    this.paginationData.limit = +(limit);
    this.getItemList();
  }

  onInfiniteScroll = e => {
    const { loading, pageCount } = this.state;
    if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 200 &&
        pageCount !== this.paginationData.pageNo && !loading) {
      this.paginationData.pageNo++;
      this.getItemList();
    }
  }

  openEdit = data => this.setState({ modifyModalOpen: true, modifyData: data });

  getSelectedItems = items => this.setState({ selectedItemsId: items });

  handleChange = name => event => {
    let data = this.state.data;
    data[name] = event.target.value;
    this.setState({ data });
  };

  deleteChosen = async () => {
    const { selectedItemsId, itemList } = this.state;
    const res = await MediaService.DeleteVideo(selectedItemsId);
    if (res.success) {
      if (itemList.length - selectedItemsId.length === 0 && this.paginationData.pageNo !== 1) {
        this.paginationData.pageNo--;
      }
      this.getItemList();
    }
  }

  openModifyModal = () => {
    this.setState({ modifyModalOpen: true });
  }

  closeModifyModal = (form, updateList) => {
    if (form) {
      const { itemList } = this.state;
      const item = itemList.find(item => item._id === form.id);
      item.nameEn = form.nameEn;
      item.nameHy = form.nameHy;
      this.setState({ itemList, modifyModalOpen: false, modifyData: null });
    } else if (updateList) this.setState({ modifyModalOpen: false, modifyData: null, itemList: [], itemCount: 0, pageCount: 0 }, this.getItemList);
    else this.setState({ modifyModalOpen: false, modifyData: null });
  }

  onViewModeChange = viewMode => {
    this.paginationData = new PaginationViewModel();
    this.setState({
      viewMode,
      itemList: [],
      selectedItemsId: [],
    }, this.getItemList);
  }

  closeDetails = () => this.setState({ detailsData: null });

  changeManageDatePickerState = state => {
    this.setState({ manageDatePickerState: state });
  }

  render() {
    const { selectedItemsId, modifyModalOpen, itemList, itemCount, viewMode, modifyData, detailsData, manageDatePickerState } = this.state;

    return <div className="App-page">
      <AppPageToolbar>
        <TableToolbar
          usingViewMode
          title={'Videos list'}
          count={itemCount}
          viewMode={viewMode}
          viewModeChange={this.onViewModeChange}
          numSelected={selectedItemsId.length}
          deleteBtn
          deleteItem={this.deleteChosen}
        >
          <div className="G-flex">
            <button className="G-btn App-add-btn" onClick={this.openModifyModal}>+Add new</button>
          </div>
        </TableToolbar>
      </AppPageToolbar>
      <div className="App-page-content-box">
        {viewMode === ViewModeEnum.Rows ? <div className="App-page-content">
          <AppTable
            rows={tableRows}
            list={itemList}
            itemCount={itemCount}
            clearSelectedItem={!selectedItemsId.length}
            changePage={page => this.changePage(page)}
            changeLimit={limit => this.changeLimit(limit)}
            editClick={this.openEdit}
            getSelectedItems={this.getSelectedItems}
          />
        </div> : <div className="App-page-content App-media-items-view-content" onScroll={this.onInfiniteScroll}>
          {itemList.map(item => (
            <div className="App-media-list-item App-uploaded-image" key={item._id}>
              <div className="image" style={{ backgroundImage: `url(${item.cover})` }} />
              <div className="opacity G-flex">
                <h4 className="G-mr-2" onClick={() => this.openEdit(item)}>EDIT</h4>
              </div>
            </div>
          ))}
        </div>}
      </div>
      {modifyModalOpen && <Modal onClickOutside={() => !manageDatePickerState && this.closeModifyModal()}>
        <Manage close={() => !manageDatePickerState && this.closeModifyModal()} onOpenDatePicker={this.changeManageDatePickerState} closeModal={form => this.closeModifyModal(form, true)} data={modifyData} />
      </Modal>}
      {detailsData && <Modal onClickOutside={this.closeDetails}>
        <div className="App-media-details" style={{ background: `url("${detailsData.path}") center/contain no-repeat` }}>
          <Closer close={this.closeDetails} /> 
        </div>
      </Modal>}
    </div>
  }
}