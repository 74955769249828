import React  from 'react';
import Switch from '@material-ui/core/Switch';

import { actionType } from '../../../../events/services/constant';

 const Action = ({
  change,
  isNews,
  onChange
 }) =>  (
  <div className="App-event-news-action">
    <div className="G-flex G-mb-2">
      <h6 className="title">Action</h6>
    </div>
    <div className="G-flex G-justify-between">
      <div className="G-flex G-align-center G-justify-between">
        <p className="G-mr-2">Save as news</p>
        <Switch
          checked={isNews}
          onChange={() => onChange && onChange({
            type  : actionType.isNews,
            value : !isNews,
          })}
        />
      </div>
    </div>
  </div>
);

export default Action;