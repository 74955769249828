import { connection, upload } from '../../services/connection';

const controllerName = 'News';

export default class NewsService {

  //#region POST

  static List = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}/listAdmin`,
      method : 'POST',
      body   : data
    });
  }

  static Create = (data) => {
    return connection({
      url    : `${controllerName}`,
      method : 'POST',
      body   : data
    });
  }

  static Main = (data) => {
    return upload({
      url    : `${controllerName}/main`,
      method : 'POST',
      body   : data
    });
  }

  static File = (data) => {
    return upload({
      url    : `${controllerName}/file`,
      method : 'POST',
      body   : data
    });
  }

  //#endregion

  static Delete = (data) => {
    return connection({
      url    : `${controllerName}`,
      method : 'DELETE',
      body   : data
    });
  }

  static Save = (data) => {
    return connection({
      url    : `${controllerName}/save`,
      method : 'PUT',
      body   : data
    });
  }

  static Get = id => {
    return connection({
      url    : `${controllerName}/detailsAdmin?id=${id}`,
      method : 'GET',
    });
  }
}