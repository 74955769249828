import React, { Component } from 'react';

import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow  from '@material-ui/core/TableRow';
import Checkbox  from '@material-ui/core/Checkbox';

export default class TableHeader extends Component {
  render() {
    const {
      onSelectAllClick,
      numSelected,
      rowCount,
      rows,
      hideCheckbox,
    } = this.props;
    return (
      <TableHead>
        <TableRow>

          {!hideCheckbox && <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={numSelected === rowCount && rowCount !== 0}
              onChange={onSelectAllClick}
            />
          </TableCell>}

          {rows.map((row, index) => (
            <TableCell key={index} align={!row.numeric ? 'left' : 'right'}>
              {row.label}
            </TableCell>
          ), this, )}

        </TableRow>
      </TableHead>
    );
  }
}