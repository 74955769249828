import React from 'react';
import Switch from '@material-ui/core/Switch';

import { actionType } from '../services/constant';

const Action = ({
	change,
	shownInWeb,
	shownInHome,
	onChange,
}) => (
		<div className="App-event-news-action">
			<div className="G-flex G-mb-2">
				<h6 className="title">Action</h6>
			</div>
			<div className="G-flex G-flex-start">
				<div className="G-flex G-align-center G-justify-between G-mr-2">
					<p className="G-mr-2">Show in web</p>
					<Switch
						checked={shownInWeb}
						onChange={() => onChange && onChange({
							type: actionType.web,
							value: !shownInWeb,
						})}
					/>
				</div>
				<div className="G-flex G-align-center G-justify-between">
					<p className="G-mr-2">Show in home page</p>
					<Switch
						checked={shownInHome}
						onChange={() => onChange && onChange({
							type: actionType.homePage,
							value: !shownInHome,
						})}
					/>
				</div>
			</div>
		</div>
	);

export default Action;