import React, { Component }         from 'react';
import classnames                   from 'classnames';
import { WithContext as ReactTags } from 'react-tag-input';
import { Switch, Checkbox }         from '@material-ui/core';

import { publishLanguageEnum } from './services/constant';
import AddFile                 from './components/AddFile';
import DefaultImageModel       from './components/DefaultImageModal';
import { HISTORY }             from '../../../routes/ClientRoutes';
import { LanguageEnum }        from '../../../platform/enums';
import { languageCodeList }    from '../../../platform/services/constant';
import NotificationSystem      from '../../../platform/services/notificationSystem';
import DropDown                from '../../../platform/components/DropDown';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import ImageUpload             from '../../../platform/components/ImageUpload';
import LanguageSelector        from '../../../platform/components/LanguageSelector';
import Input                   from '../../../platform/components/Input';
import Modal                   from '../../../platform/components/Modal';
import Closer                  from '../../../platform/components/Closer';
import { BooksMainViewModel }  from '../../../platform/api/book/view-model';
import BookService             from '../../../platform/api/book';
import { to }                  from '../../../routes';

export default class BooksManage extends Component {

  state = {
    selectedLanguage  : LanguageEnum.hy,
    data              : new BooksMainViewModel(),
    pdf               : null,
    defaultCovers     : null,
    defaultModelState : false,
    categoriesOpen    : false,
    categories        : [],
  }

  getDetails = async () => {
    const { id } = this.state;
    const res = await BookService.Details(id);
    if (res.success && res.data) {
      const data = new BooksMainViewModel(res.data);
      data.coverPath = res.data.cover;
      this.setState({ data, defaultCovers: res.data.covers || null });
    } else HISTORY.goBack();
  }

  getCategories = async () => {
    const result = await BookService.CategoryDrop();
    this.setState({ categories: result.data });
  }

  toggleCategoriesModal = () => {
    const { categoriesOpen } = this.state;
    this.setState({ categoriesOpen: !categoriesOpen });
  }

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  getMainImages = images => {
    const data = this.state.data;
    data.image = images;
    delete data.coverPath;
    this.setState({ data });
  }

  save = async () => {
    const data = this.state.data;
    if (data.validate()) {
      const formData = new FormData();
      formData.append('id', this.state.id);
      const baseModel = data.getBaseModel();
      for (const key in baseModel) {
        if (baseModel.hasOwnProperty(key)) {
          const item = baseModel[key];
          if (item) {
            if ((key === 'categories' || key === 'tagsHy' || key === 'tagsEn')) {
              if (item.length) {
                item.forEach(element => {
                  formData.append(`${key}[]`, element);
                });
              }
            } else {
              formData.append(key, item);
            }
          } else if (key === 'isCfoaDeveloped') {
            formData.append(key, item);
          }
        }
      }
      const res = await BookService.Main(formData);

      if (res && res.success) {
        NotificationSystem.showNotification('Message', 'Success');
        HISTORY.push(to.bookList);
      } else {
        NotificationSystem.showNotification('Message', res.message);
      }
    }
  }
  
  getFile = pdf => {
    const { data } = this.state;
    if (pdf) {
      data.pdfId = pdf.id;
      delete data.pdfPath;      
      this.setState({ data, defaultCovers: pdf.covers });
    } else {
      delete data.pdfId;
      delete data.pdfPath;
      this.setState({ data, defaultCovers: null });
    }
  }

  toggleDefaultModelState = event => {
    event.preventDefault()
    event.stopPropagation();
    this.setState({ defaultModelState: !this.state.defaultModelState });
  }

  getDefaultImage = index => {
    const { data, defaultCovers } = this.state;
    data.coverPath = defaultCovers[index];
    this.setState({
      data,
      defaultModelState: !this.state.defaultModelState
    });
  }

  tagDelete = (name, index) => {
    const { data } = this.state;
    data[name].splice(index, 1);
    this.setState({ data });
  }

  tagAdd = (name, item) => {
    const { data } = this.state;
    data[name].push(item.text);
    this.setState({ data });
  }

  setPublishLanguage = language => {
    const { data } = this.state;
    data.publishLanguage = language;
    this.setState({ data });
  }

  toggleCategory = id => {
    const { data } = this.state;
    const itemIndex = data.categories.indexOf(id);

    if (itemIndex !== -1) data.categories.splice(itemIndex, 1);
    else data.categories.push(id);

    this.setState({ data });
  }

  toggleCfoaDeveloped = () => {
    const { data } = this.state;
    data.isCfoaDeveloped = !data.isCfoaDeveloped;
    this.setState({ data });
  }

  get chosenCategories() {
    const { categories, data } = this.state;
    return categories.filter(item => data.categories.includes(item._id)).map(item => item.nameEn).join(', ');
  }

  componentDidMount() {
    this.getCategories();
    const params = new URLSearchParams(HISTORY.location.search);
    const draftId = params.get('draftId');
    const bookId = params.get('id');
    this.setState({ id: draftId || bookId }, () => bookId && this.getDetails());
  }

  render() {
    const {
      id,
      data,
      selectedLanguage,
      defaultCovers,
      defaultModelState,
      categoriesOpen,
      categories
    } = this.state;
    return (
      <div className="App-page App-add-page-news-event">
        <AppPageToolbar className="G-pl-2 G-pr-2 G-flex G-justify-between">
          <h6>Books/ <span className="G-page-active-title">Add books</span></h6>
          <div className="G-flex G-align-center">
            <LanguageSelector
              languageList={languageCodeList}
              selectedLanguage={selectedLanguage}
              selectLanguage={value => this.setState({ selectedLanguage: value })}
            />
            <button className="G-btn App-save-btn G-ml-4" onClick={this.save}>Save</button>
          </div>
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-page-content G-flex G-justify-between">

            <div className="G-w-25 App-manage-book-image G-flex G-justify-center">
              <ImageUpload imageUrl={data.coverPath || data.image} output={this.getMainImages}>
                {data.pdfId && defaultCovers.length && <p>Select image or <b onClick={this.toggleDefaultModelState}>choose default</b></p>}
              </ImageUpload>
            </div>

            <div className="G-w-75">

              <div className="block">

                <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
                  <div className="G-flex G-justify-between">
                    <Input
                      title="Title"
                      name="titleEn"
                      value={data.titleEn}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="G-input-box">
                    <p>Description</p>
                    <textarea cols="5" rows="5"
                      value={data.descriptionEn || ''}
                      onChange={event => this.handleChange({ name: 'descriptionEn', value: event.target.value })}
                    />
                  </div>
                  <div className="G-flex">
                    <div className="G-input-box">
                      <p>Tags</p>
                      <ReactTags
                        allowDragDrop={false}
                        tags={data.tagsEn.map(item => ({ id: item, text: item }))}
                        handleDelete={index => this.tagDelete('tagsEn', index)}
                        handleAddition={index => this.tagAdd('tagsEn', index)}
                        placeholder=""
                      />
                    </div>
                  </div>
                </div>

                <div className={classnames('G-hide',  { 'G-show': selectedLanguage === LanguageEnum.hy })}>
                  <div className="G-flex G-justify-between">
                    <Input
                      title="Title"
                      name="titleHy"
                      value={data.titleHy}
                      required
                      errors={data.errors.titleHy}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="G-input-box">
                    <p>Description <span>*</span></p>
                    <textarea cols="5" rows="5"
                      className={classnames({ 'G-error': data.errors.descriptionHy })}
                      value={data.descriptionHy || ''}
                      onChange={event => this.handleChange({ name: 'descriptionHy', value: event.target.value })}
                    />
                  </div>
                  <div className="G-flex">
                  <div className="G-input-box">
                    <p>Tags</p>
                    <ReactTags
                      allowDragDrop={false}
                      tags={data.tagsHy.map(item => ({ id: item, text: item }))}
                      handleDelete={index => this.tagDelete('tagsHy', index)}
                      handleAddition={index => this.tagAdd('tagsHy', index)}
                      placeholder=""
                    />
                  </div>
                </div>
                </div>
              </div>

              <div className="block G-flex G-justify-between G-half-input-box">
                <div className="G-input-box">
                  <p>Publish language</p>
                  <DropDown
                    headerClassName={data.errors.publishLanguage ? 'G-error' : ''}
                    bodyClickDisabled={false}
                    Header={() => <div>{publishLanguageEnum[data.publishLanguage] || ''}</div>}
                    Body={() => <div className="App-drop-down-list">
                      <div onClick={() => this.setPublishLanguage(publishLanguageEnum.armenian)}>Armenian</div>
                      <div onClick={() => this.setPublishLanguage(publishLanguageEnum.russian)}>Russian</div>
                      <div onClick={() => this.setPublishLanguage(publishLanguageEnum.english)}>English</div>
                    </div>}
                  />
                </div>
                <div className="G-input-box">
                  <p>Categories</p>
                  <div
                    className={`G-input G-cursor App-book-categories-input ${data.errors.categories ? 'G-error' : ''}`}
                    onClick={this.toggleCategoriesModal}
                  >{this.chosenCategories}</div>
                </div>
              </div>

              <div className="block">
                <AddFile
                  id={id}
                  output={this.getFile}
                  defaultPath={data.pdfPath}
                />
              </div>

              <div className="block G-flex G-align-center">
                <p className="G-mr-2">CFOA Developed</p>
                <Switch
                  checked={data.isCfoaDeveloped}
                  onChange={this.toggleCfoaDeveloped}
                />
              </div>

              {defaultModelState && <DefaultImageModel
                  defaultCovers={defaultCovers}
                  closeModal={this.toggleDefaultModelState}
                  getDefaultImage={this.getDefaultImage}
                />
              }

              {categoriesOpen && <Modal className="App-book-choose-categories" onClickOutside={this.toggleCategoriesModal}>
                <h3>Choose book categories</h3>
                <Closer close={this.toggleCategoriesModal} />
                {categories.map(item => <div key={item._id} className="G-flex G-justify-between G-align-center">
                  {item.nameEn}
                  <Checkbox
                    onClick={() => this.toggleCategory(item._id)}
                    checked={data.categories.includes(item._id)}
                  />
                </div>)}
              </Modal>}

            </div>
          </div>
        </div>
      </div>
    )
  }
} 