import { connection, upload } from '../../services/connection';

//#region JSDoc types

 /**
 * @typedef {Object} UserList
 * @property {number} pageNo
 * @property {number} limit
 */

//#endregion

const controllerName = 'User';

export default class UserService {

  //#region POST

  /**
   * @param {UserList} data */
  static List = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'POST',
      body   : data
    });
  }

  static Invite = (data) => {
    return connection({
      url    : `${controllerName}/invite`,
      method : 'POST',
      body   : data
    });
  }

  static Check = (data) => {
    return connection({
      url    : `${controllerName}/check`,
      method : 'POST',
      body   : data
    });
  }

  static ListAuto = (data) => {
    return connection({
      url            : `${controllerName}/auto`,
      method         :  'POST',
      body           : data,
      withoutLoading : true,
    });
  }

  //#endregion

  //#region PUT

  static Web = (data) => {
    return connection({
      url    : `${controllerName}/web`,
      method : 'PUT',
      body   : data
    });
  }

  static Block = ids => {
    return connection({
      url    : `${controllerName}/block`,
      method : 'PUT',
      body   : {
        idList: ids
      }
    });
  }


  static Unblock = ids => {
    return connection({
      url    : `${controllerName}/unblock`,
      method : 'PUT',
      body   : {
        idList: ids
      }
    });
  }

  
  static WebAction = id => {
    // web
    return connection({
      url    : `${controllerName}/web`,
      method : 'PUT',
      body   : {
        id
      }
    });
  }

  //#endregion

  static Update = (formData) => {
    return upload({
      url    : `${controllerName}`,
      method : 'PUT',
      body   : formData,
    });
  }

  static Details = (id = null) => {
    return connection({
      url    : `${controllerName}/details${id ? `?id=${id}` : ''}`,
      method : 'GET'
    });
  }
}