import { connection, upload } from '../../services/connection';

const controllerName = 'task';

export default class TaskService {

  static List = id => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'POST',
      body   : { activityId: id },
    });
  }

  static Add = (data) => {
    return upload({
      url    : `${controllerName}/main`,
      method : 'POST',
      body   : data
    });
  }

  static Edit = (data) => {
    return upload({
      url    : controllerName,
      method : 'PUT',
      body   : data
    });
  }

  static File = (data) => {
    return upload({
      url    : `${controllerName}/files`,
      method : 'POST',
      body   : data
    });
  }

  static Get = id => {
    return connection({
      url    : `${controllerName}/details?id=${id}`,
      method : 'GET'
    });
  }

  static Delete = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'DELETE'
    });
  }

  static Approve = id => {
    return connection({
      url    : `${controllerName}/approve?id=${id}`,
      method : 'PUT',
    });
  }

}
