import React, { Component } from 'react';

import AddNewspaper            from './components/Add';
import { tableRows }           from './services/constant';
import { PaginationViewModel } from '../../../platform/view-models';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import AppTable                from '../../../platform/components/table/Table';
import Modal                   from '../../../platform/components/Modal';
import TableToolbar            from '../../../platform/components/table/TableToolbar';
import NewspaperService        from '../../../platform/api/newspaper';
import settings from '../../../platform/services/settings';

export default class Newspapers extends Component {

  state = {
    list            : [],
    selectedItemsId : [],
    itemCount       : 0,
    addModalState   : false,
    modifyData      : null,
  }

  //#region Table

  paginationData;

  getList = async () => {
    const res = await NewspaperService.List(this.paginationData.getBaseModel());
    if (res.success && res.data) {
      this.setState({
        selectedItemsId : [],
        list            : res.data.itemList || [],
        itemCount       : res.data.itemCount || 0,
      });
    }
  }
  
  changePage = page => {
    this.paginationData.pageNo = page;
    this.getList();
  }

  changeLimit = limit => {
    this.paginationData = new PaginationViewModel();
    this.paginationData.limit = +(limit);
    this.getList();
  }

  
  //#region Selected Items

  getSelectedItems = items => this.setState({ selectedItemsId: items });

  delete = async () => {
    const { selectedItemsId, list } = this.state;
    const res = await NewspaperService.Delete(selectedItemsId);
    if (res.success) {
      if (list.length - selectedItemsId.length === 0 && this.paginationData.pageNo !== 1) {
        this.paginationData.pageNo--;
      }
      this.getList();
    }
  }

  //#endregion Selected Items

  //#endregion Table

  toggleAddModalState = () => this.setState({ addModalState: !this.state.addModalState });

  closeAddModal = () => {
    if (!this.state.datePickerState) {
      this.setState({
        addModalState : false,
        modifyData    : null
      }, this.getList)
    }
  }

  openEdit = async data => {
    // Get details, redirect to add, with editing item
    const result = await NewspaperService.GetDetails(data._id);
    if (result && result.success) {
      this.setState({
        modifyData    : result.data,
      }, () => this.setState({ addModalState : true }));
    }
  }

  changeDatePickerState = state => {
    this.setState({ datePickerState: state });
  }

  componentDidMount() {
    settings.emtyAllPageNos();
    this.paginationData = new PaginationViewModel();
    this.getList();
  }

  render() {
    const { list, selectedItemsId, itemCount, addModalState, modifyData } = this.state;
    return <div className="App-page">
      <AppPageToolbar>
        <TableToolbar title={'Newspapers list'} count={itemCount}
          numSelected={selectedItemsId.length}
          deleteBtn
          deleteItem={this.delete}
        >
          <div className="G-flex">
            <button className="G-btn App-add-btn" onClick={this.toggleAddModalState}>+Add new</button>
          </div>
        </TableToolbar>
      </AppPageToolbar>
      <div className="App-page-content-box">
        <div className="App-page-content">
          <AppTable
            rows={tableRows}
            list={list}
            itemCount={itemCount}
            clearSelectedItem={!selectedItemsId.length}
            changePage={this.changePage}
            changeLimit={this.changeLimit}
            getSelectedItems={this.getSelectedItems}
            editClick={this.openEdit} />
        </div>
      </div>
      {addModalState && <Modal onClickOutside={this.closeAddModal}>
        <AddNewspaper
          onOpenDatePicker={this.changeDatePickerState}
          closeModal={this.closeAddModal}
          data={modifyData}
        />
      </Modal>}
    </div>
  }
}
