export const eventTypeEnum = {
  login      : 'LOGIN',
  logout     : 'LOGOUT',
  loaderShow : 'LOADER-SHOW',
  loaderHide : 'LOADER-HIDE',
}

export const customEvent = data => {
  const NOTIFICATION = new CustomEvent(data.name, {
    'detail': data.value || {}
  });
  document.dispatchEvent(NOTIFICATION);
}
