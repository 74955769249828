const objHandler = {
  get: (target, name) => {
    return target.hasOwnProperty(name) ? target[name] : target.en;
  }
};

export const globalErrorMassage = new Proxy({
  hy: 'Ինչ որ բան այնպես չգնաց',
  ru: 'Что-то пошло не так',
  en: 'Something went wrong',
}, objHandler);

export const loginErrorMessage = new Proxy({
  hy: 'Էլ. հասցեն կամ գաղտնաբառը սխալ են մուտք արված',
  ru: 'Эл. почта или пароль введены неправильно',
  en: 'Email or Password is entered wrong',
}, objHandler);

export const emailErrorMessage = new Proxy({
  en: 'Wrong email address'
}, objHandler);


export const confirmErrorMessage = new Proxy({
  en: 'Password and confirm password not matched'
}, objHandler);

export const fieldErrorMessage = new Proxy({
  en: 'All fields are required'
}, objHandler);

//#region Success Messages

export const forgotPassSuccessMessage = new Proxy({
  en: 'Restore email sent'
}, objHandler);

export const registrationSuccessMessage = new Proxy({
  en: 'Registered successfully'
}, objHandler);

//#endregion
