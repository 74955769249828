import React, { Component } from 'react';

import Input                    from '../../../../platform/components/Input';
import AuthService              from '../../../../platform/api/auth';
import { ForgotEmailViewModel } from '../../../../platform/api/auth/view-model';
import * as Messages            from '../../../../platform/lang/messages';
import NotificationSystem       from '../../../../platform/services/notificationSystem';

export default class ForgotPassEmail extends Component {
  
  state = {
    data : new ForgotEmailViewModel(),
  }

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  send = async () => {
    const data = this.state.data;
    if (data.validate()) {
      const res = await AuthService.ForgotEmail(data.getBaseModel());
      if (res.success) {
        NotificationSystem.showNotification('Success message', Messages.forgotPassSuccessMessage.en);
        this.props.showLoginPage();
      } else {
        NotificationSystem.showNotification('Message', Messages.emailErrorMessage.en);
        data.errors.email = true;
        this.setState({ data });
      }
    } else this.setState({ data });
  }

  render() {
    const { data } = this.state;
    return (
      <div className="form">
        <h4 className="G-mb-2">FORGOT PASSWORD</h4>
        <Input
          title="Email"
          name="email"
          value={data.email}
          errors={data.errors.email}
          onChange={this.handleChange}
          enterKeyPress={this.send}
        />
        <button className="G-btn App-btn" onClick={this.send}>Send</button>
      </div>
    )
  }
}