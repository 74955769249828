import React, { Component, Fragment } from 'react';
import classnames                     from 'classnames';

import { LanguageEnum }           from '../../../../platform/enums';
import { languageCodeList }       from '../../../../platform/services/constant';
import LanguageSelector           from '../../../../platform/components/LanguageSelector';
import ImageUpload                from '../../../../platform/components/ImageUpload';
import Closer                     from '../../../../platform/components/Closer';
import SectionService             from '../../../../platform/api/section';
import { CreateSectionViewModel } from '../../../../platform/api/section/view-model';

export default class SectionManage extends Component {

  state = {
    selectedLanguage : LanguageEnum.en,
    data             : new CreateSectionViewModel(),
    edit             : false,
    editImage        : false,
  }

  handleChange = (event, name) => {
    const data = this.state.data;
    data[name] = event.target.value;
    this.setState({ data });
  };

  getFile = file => {
    const data = this.state.data;
    data.image = file;
    if (this.props.data) {
      this.setState({
        editImage : true,
        data,
      });
    } else this.setState({ data });
  }

  save = async () => {
    const data = this.state.data;
    const isValid = data.validate();
    if (isValid) {
      this.setState({ data });
      const formData = new FormData();
      const baseModel = data.getBaseModel();
      for (const key in baseModel) {
        if (baseModel.hasOwnProperty(key)) {
          const item = baseModel[key];
          formData.append(key, item)
        }
      }
      if (this.state.edit && !this.state.editImage) formData.delete('image'); 
      const res = await SectionService.Create(formData);
      console.log(res); // TODO - show success message
      if (res.success) this.props.closeModal();
    } else {
      if (data.errors.titleHy || data.errors.bodyHy) {
        this.setState({
          selectedLanguage: LanguageEnum.hy,
          data,
        });
      } else if (data.errors.titleEn || data.errors.bodyEn) {
        this.setState({
          selectedLanguage: LanguageEnum.en,
          data,
        });
      } else this.setState({ data });
    }
  }

  componentDidMount() {
    let data, edit;
    const propsData = this.props.data;
    if (propsData) {
      data = new CreateSectionViewModel({ ...propsData });
      edit = true;
    } else {
      data = this.state.data;
      data.position = this.props.position + 1;
      edit = false;
    }
    this.setState({
      data: data,
      edit
    });
  }
  
  render() {
    const { selectedLanguage, data, edit } = this.state;
    return (
      <div className="App-add-section">
        <div className="G-flex G-justify-between G-align-center G-mb-2">
          <h4>{`${edit ? 'Edit' : 'Add new'} section`}</h4>
          <Closer close={this.props.closeModal} />
          <LanguageSelector
            languageList={languageCodeList}
            selectedLanguage={selectedLanguage}
            selectLanguage={value => this.setState({ selectedLanguage: value })}
          />
        </div>
        <div className="G-flex G-mb-2">
          <div className="image-upload-box">
            <div className="image-upload G-pr-2">
              <ImageUpload imageUrl={data.image} error={data.errors.image} output={file => this.getFile(file)} />
            </div>
          </div>
          <div className="form">

            {selectedLanguage === LanguageEnum.hy ? 
              <Fragment>
                <div className="G-input-box">
                  <p>Title</p>
                  <input
                    type="text"
                    className={classnames({ 'G-error': data.errors.titleHy })}
                    value={data.titleHy}
                    onChange={event => this.handleChange(event, 'titleHy')}
                  />
                </div>
                <div className="G-input-box">
                  <p>Description</p>
                  <textarea cols="5" rows="5"
                    className={classnames({ 'G-error': data.errors.bodyHy })}
                    value={data.bodyHy}
                    onChange={event => this.handleChange(event, 'bodyHy')}
                  />
                </div>
              </Fragment>
              :
              <Fragment>
                <div className="G-input-box">
                  <p>Title</p>
                  <input
                    type="text"
                    className={classnames({ 'G-error': data.errors.titleEn })}
                    value={data.titleEn}
                    onChange={event => this.handleChange(event, 'titleEn')}
                  />
                </div>
                <div className="G-input-box">
                  <p>Description</p>
                  <textarea cols="5" rows="5"
                    className={classnames({ 'G-error': data.errors.bodyEn })}
                    value={data.bodyEn}
                    onChange={event => this.handleChange(event, 'bodyEn')}
                  />
                </div>
              </Fragment>
            }

            <div className="G-input-box">
              <p>Link</p>
              <input
                type="text"
                className={classnames({ 'G-error': data.errors.link })}
                value={data.link}
                onChange={event => this.handleChange(event, 'link')}
              />
            </div>
          </div>
        </div>
        <div className="G-flex G-align-center G-justify-end">
          <button className="G-btn App-save-btn" onClick={this.save}>SAVE</button>
        </div>
      </div>
    )
  }
}
