import React, { Component } from 'react';
import classnames           from 'classnames';

export default class FileUpload extends Component {

  state = {
    uploadFile : false,
    fileName   : '',
    uniqueId   : null,
  }

  input = React.createRef();

  componentDidMount() {
    const uniqueId = `App-file-upload-${new Date().getTime()}-${this.props.uniqueKey || ''}`;
    this.setState({ uniqueId });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.fileName && nextProps.fileName !== this.state.fileName) {
      this.setState({
        uploadFile : true,
        fileName    : nextProps.fileName
      })
    }
  }

  fileSelectedHandler = file => {
    if (file.target && file.target.files && file.target.files[0]) {
      const READER = new FileReader();
      const IMG = file.target.files[0];
      READER.readAsDataURL(IMG);
      READER.onload = () => {
        this.setState({
          uploadFile : true,
          fileName    : IMG.name
        })
        this.props.output(IMG);
      };
    }
  }

  deletePhoto = e => {
    e.preventDefault();
    this.input.current.value = null;
    this.setState({
      uploadFile : false,
      fileName   : ''
    });
    this.props.output(null);
  }

  render() {
    const { className, showDeleteIcon, defaultName, onClick } = this.props;
    const { uploadFile, fileName, uniqueId } = this.state;
    return (
      <div className="App-file-upload-component" onClick={onClick}>
        <input accept={`${this.props.accept ? this.props.accept : '.pdf,.doc,.docx,.xlsx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'}`} type="file" id={uniqueId} ref={this.input} onChange={this.fileSelectedHandler} />
        {!uploadFile ? (
          <label className={'G-flex G-align-center G-justify-between'} htmlFor={uniqueId}>
            <div className={classnames(`input G-flex G-align-center G-cursor ${className || ''}`, { "G-error": this.props.error })}>
              <i className="icon-attach" />
              <p className="G-cursor">{defaultName || 'Choose file'}</p>
            </div>
            {showDeleteIcon && <i className="icon-close G-cursor" onClick={this.deletePhoto} />}
          </label>
        ) : (
          <div className="App-file-upload G-flex G-align-center G-justify-between">
            <div className={classnames("input G-flex G-align-center", { "G-error": this.props.error })}>
              <i className="icon-attach" />
              <p>{fileName}</p>
            </div>
            {showDeleteIcon && <i className="icon-close G-cursor" onClick={this.deletePhoto} />}
          </div>
        )}
      </div>
    );
  }
}