export class ModifyBookCategoryModel {
  
  id = '';
  nameEn = '';
  nameHy = '';

  image = null;
  showImage = '';

  errors = {
    image  : false,
    nameEn : false,
    nameHy : false,
  };

  constructor(data) {
    if (data) {
      this.id        = data._id;
      this.showImage = data.cover;
      this.nameEn    = data.nameEn;
      this.nameHy    = data.nameHy;
    }
  }

  getBaseModel = () => {
    const model = {
      nameEn: this.nameEn,
      nameHy: this.nameHy
    };
    if (this.image) model.image = this.image;
    return model;
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      nameEn : false,
      nameHy : false,
      image  : false
    };

    if (!this.showImage) {
      ERRORS.image = true;
      isValid = false;
    }

    if (!this.nameEn || this.nameEn.length < 5) {
      ERRORS.nameEn = true;
      isValid = false;
    }

    if (!this.nameHy || this.nameHy.length < 5) {
      ERRORS.nameHy = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class BooksMainViewModel {
  pdfId;
  pdfPath
  titleHy = '';
  descriptionHy = '';
  titleEn = '';
  descriptionEn = '';
  publishLanguage;
  isCfoaDeveloped =  false;
  categories = [];
  tagsHy = [];
  tagsEn = [];
  coverPath;
  image;
  cover;

  errors = {
    titleHy         : false,
    descriptionHy   : false,
    publishLanguage : false,
    categories      : false,
  };

  constructor(data) {
    if (data) {
      this.titleHy = data.titleHy;
      this.descriptionHy = data.descriptionHy;
      this.tagsHy = data.tagsHy;
      this.titleEn = data.titleEn;
      this.descriptionEn = data.descriptionEn;
      this.tagsEn = data.tagsEn;
      this.publishLanguage = data.publishLanguage;
      this.categories = data.categories.map(item => typeof item === 'string' ? item : item._id);
      this.pdfId = data.pdfId;
      this.pdfPath = data.pdfPath;
      this.isCfoaDeveloped = data.isCfoaDeveloped;
      this.coverPath = data.coverPath;
      this.image = data.image;
      this.cover = data.cover;
    }
  }

  getBaseModel = () => {
    return {
      pdfId           : this.pdfId,
      titleHy         : this.titleHy,
      descriptionHy   : this.descriptionHy,
      titleEn         : this.titleEn,
      descriptionEn   : this.descriptionEn,
      publishLanguage : this.publishLanguage,
      isCfoaDeveloped : this.isCfoaDeveloped,
      categories      : this.categories,
      tagsHy          : this.tagsHy,
      tagsEn          : this.tagsEn,
      coverPath       : this.coverPath,
      image           : this.image,
    };
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      titleHy         : false,
      descriptionHy   : false,
      publishLanguage : false,
      categories      : false,
      pdf             : false,
    };

    if (!this.titleHy) {
      ERRORS.titleHy = true;
      isValid = false;
    }

    if (!this.descriptionHy) {
      ERRORS.descriptionHy = true;
      isValid = false;
    }

    if (!this.publishLanguage) {
      ERRORS.publishLanguage = true;
      isValid = false;
    }

    if (!this.categories || !this.categories.length) {
      ERRORS.categories = true;
      isValid = false;
    }

    if (!this.pdfId) {
      ERRORS.pdf = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}
