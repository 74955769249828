import React, { Component }         from 'react';

import { HISTORY }             from '../../../routes/ClientRoutes';
import { LanguageEnum }        from '../../../platform/enums';
import { languageCodeList }    from '../../../platform/services/constant';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import LanguageSelector        from '../../../platform/components/LanguageSelector';
import { UserItemViewModel }   from '../../../platform/api/userItem/view-model';
import UserService             from '../../../platform/api/user';

export default class UserDetails extends Component {

  checkImage = true;

  state = {
    selectedLanguage : LanguageEnum.hy,
    data             : new UserItemViewModel(),

    details          : {},
    deleteImage      : false,
  }

  getDetails = async () => {
    const { id } = this.state;
    const res = await UserService.Details(id);
    if (res.success && res.data) {
      // this.checkImage = false;
      // const data = new UserItemViewModel(res.data);
      this.setState({ details: res.data });
    } else HISTORY.goBack();
  }

  componentDidMount() {
    const params = new URLSearchParams(HISTORY.location.search);
    const id = params.get('id');
    this.setState({ id }, () => id && this.getDetails());
  }

  render() {
    const {
      // id,
      details,
      selectedLanguage
    } = this.state;

    console.log(selectedLanguage);

    return (
      <div className="App-page App-add-page-news-event">
        <AppPageToolbar className="G-pl-2 G-pr-2 G-flex G-justify-between">
          <h6>Users/ <span className="G-page-active-title">Details</span></h6>
          <div className="G-flex G-align-center">
            <LanguageSelector
              languageList={languageCodeList}
              selectedLanguage={selectedLanguage}
              selectLanguage={value => this.setState({ selectedLanguage: value })}
            />
          </div>
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-page-content G-flex G-justify-between" style={{ minHeight: '600px' }}>

            <div className="G-w-25 App-manage-book-image G-flex G-justify-center G-flex-wrap G-align-content-start">
              <div className="App-image-upload-component">
                <div className="App-image-upload">
                  <div className="App-uploaded-image">
                    <div className="image" style={{ backgroundImage: `url('${details.image}')` }}></div>
                  </div>
                </div>
              </div>

              <h6 className="G-w-f G-details-upload-label"><b>Manager</b>{` in ${details.managerCount} program(s)`}</h6>
              <h6 className="G-w-f G-details-upload-label"><b>User</b>{` in ${details.userCount} activitie(s)`}</h6>
            </div>

            <div className="G-w-75">

              <div className="block">

                <div>
                  <div className="G-flex G-justify-betweengit">
                    <div className="G-input-box">
                      <h4 className="G-details-title-label">Name</h4>
                      <p>{ selectedLanguage === LanguageEnum.hy ? details.nameHy : details.nameEn}</p>
                    </div>
                  </div>
                  <div className="G-flex G-justify-between">
                    <div className="G-input-box">
                      <h4 className="G-details-title-label">Profession</h4>
                      <p>{ selectedLanguage === LanguageEnum.hy ? details.proffessionHy : details.proffessionEn}</p>
                    </div>
                  </div>
                  <div className="G-input-box">
                    <h4 className="G-details-title-label">Description</h4>
                    <p className="G-break-word">{ selectedLanguage === LanguageEnum.hy ? details.descriptionHy : details.descriptionEn}</p>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
} 