import React, { useState, useEffect } from 'react';
import classnames          from 'classnames';

// import FileUpload from '../../../../platform/components/FileUpload';
import FileUpload from '../../../../../../platform/components/FileUpload';
import {
  FileViewModel,
  UploadedFileViewModel,
} from '../../../../../../platform/api/task/view-model';

const AddFiles = ({
  language,
  output,
  uploadedFiles,
  outputDeletedFiles,
}) => {

  const [ files, setFiles ] = useState([ new FileViewModel() ]);
  const [ uploadedFileList, setUploadedFileList ] = useState([]);

  const deletedFilesId = [];

  useEffect(() => {
    if (uploadedFiles) {
      const list = uploadedFiles.map(item => new UploadedFileViewModel({
        name : item.name,
        id   : item._id,
        language
      }));
      setUploadedFileList(list);
    }
  }, [uploadedFiles, language]);

  const handlerChangeUploadedFile = (event, index) => {
    const newFiles = [ ...uploadedFileList ];
    const item = newFiles[index];
    if (item) {
      item['name'] = event.target && event.target.value;
      item.validate();
    }
    setUploadedFileList(newFiles);
    output && output(newFiles);
  };

  const deleteUploadedFile = index => {
    const newFiles = [ ...uploadedFileList ];
    deletedFilesId.push(newFiles[index].id);
    newFiles.splice(index, 1);
    setUploadedFileList(newFiles);
    outputDeletedFiles && outputDeletedFiles(deletedFilesId);
  }

  const handleChange = (event, name, index) => {
    const newFiles = [ ...files ];
    if (event) {
      newFiles[index]['language'] = language;
      newFiles[index][name] = event && event.target ? event.target.value : event;
    } else {
      newFiles.splice(index, 1);
      if (!newFiles.length) newFiles.push(new FileViewModel());
    }
    setFiles(newFiles);
    output && output(newFiles);
  };

  const addNew = () => {
    const newFiles = [ ...files ];
    const isValid = newFiles[newFiles.length - 1].validate();
    if (isValid) {
      newFiles.push(new FileViewModel());
    }
    setFiles(newFiles);
  }

  return (
    <div className="App-add-files">
      <div className="G-flex G-justify-between G-mb-2">
        <h6 className="title">Add file(s)</h6>
        <button className="G-btn App-add-btn-2" onClick={addNew}>+ Add new</button>
      </div>
      <div className="body">
        {uploadedFileList.map((item, index) => (
          <div key={index}>
            <div className="G-line" />
            <div className="G-flex G-flex-wrap G-justify-between G-half-input-box">
              <div className="G-input-box">
                <p>Name</p>
                <input
                  type="text"
                  value={item.name}
				          className={classnames({ 'G-error': item.errors.name })}
                  onChange={event => handlerChangeUploadedFile(event, index)}
                />
              </div>
              <div className="G-input-box">
                <p>File</p>
                <div className="G-input-delete-box">
                  <input
                    type="text"
                    disabled
                    value="File"
                  />
                  <i className="icon-close G-cursor" onClick={() => deleteUploadedFile(index)} />
                </div>
              </div>
            </div>
          </div>
        ))}
        {files.map((item, index) => (
          <div key={index}>
            <div className="G-line" />
            <div className="G-flex G-flex-wrap G-justify-between G-half-input-box">
              <div className="G-input-box">
                <p>Name</p>
                <input
                  type="text"
                  value={item.name}
				          className={classnames({ 'G-error': item.errors.name })}
                  onChange={event => handleChange(event, 'name', index)}
                />
              </div>
              <div className="G-input-box">
                <p>File</p>
                <FileUpload
                  uniqueKey={language || ''}
                  fileName={item && item.file && item.file.name}
                  error={item.errors.file}
                  showDeleteIcon={files.length > 1}
                  output={file => handleChange(file, 'file', index)}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AddFiles;
