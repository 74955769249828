import { connection } from '../../services/connection';

//#region JSDoc types

 /**
 * @typedef {Object} CoverList
 */

//#endregion

const controllerName = 'File';

export default class FileService {

  //#region POST

  static setCalc = (data) => {
    return connection({
      url         : `${controllerName}/calc`,
      method      : 'POST',
      body        : data,
      contentType : undefined,
    });
  }

  //#endregion

  //#region GET

  /**
   * @param {UserList} data */
  static CoverList = () => {
    return connection({
      url    : `${controllerName}/coverList`,
      method : 'GET',
    });
  }

  static getCalc = () => {
    return connection({
      url    : `${controllerName}/calc`,
      method : 'GET',
    });
  }

  //#endregion

  //#region PUT

  static UpdateCover = (data) => {
    return connection({
      url         : `${controllerName}/cover`,
      method      : 'PUT',
      body        : data,
      contentType : undefined,
    });
  }

  //#endregion
}