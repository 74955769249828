import React, { Component }  from 'react';
import classnames            from 'classnames';
import Autocomplete          from 'react-google-autocomplete';

import Action                  from './components/Action';
import AddFiles                from './components/AddFiles';
import Images                  from './components/Images';
import TaskInfo                from './components/TaskInfo';
import { actionType }          from './services/constant';
import { HISTORY }             from '../../../../../routes/ClientRoutes';
import { LanguageEnum }        from '../../../../../platform/enums';
import { languageCodeList }    from '../../../../../platform/services/constant';
import AppPageToolbar          from '../../../../../platform/components/PageToolbar';
import LanguageSelector        from '../../../../../platform/components/LanguageSelector';
import MultiImageUpload        from '../../../../../platform/components/MultiImageUpload';
import DatePicker              from '../../../../../platform/components/DatePicker';
import AppGoogleMap            from '../../../../../platform/components/GoogleMap';
import { TaskMainViewModel }   from '../../../../../platform/api/task/view-model';
import TaskServices            from '../../../../../platform/api/task';
import ActivityService         from '../../../../../platform/api/activity';

import ManagersInfo            from '../../components/Managersinfo';

export default class TaskManage extends Component {

  taskId; enInfo; hyInfo; hyFiles; enFiles; deletedImageList;
  mainImage; coverImage; uploadedFilesHy; uploadedFilesEn; deletedFileList; userIdList;

  state = {
    selectedLanguage : LanguageEnum.hy,
    data             : null,
    imagesUrl        : [],
    activityId       : undefined,
    id               : undefined,
    startMinDate     : null,
    startMaxDate     : null,
    endMinDate       : null,
    endMaxDate       : null,
    hideUsers        : false,
  } 

  fetchDetails = async () => {
    const res = await TaskServices.Get(this.state.id);
    if (res.success && res.data) {
      const data = new TaskMainViewModel(this.state.hideUsers, res.data.details);
      const imagesUrl = res.data.details.images;
      this.mainImage = res.data.details.image;
      this.coverImage = res.data.details.cover;
      this.uploadedFilesHy = res.data.details.filesHy;
      this.uploadedFilesEn = res.data.details.filesEn;
      data.mainImage = null;
      data.cover = null;
      data.image = null;
      this.setState({ data, dataFetched: true, imagesUrl });
    } else HISTORY.goBack();
  }

  //#region Info

  getFullInfoEn = info => {
    const data = this.state.data;
    data.titleEn = info.title;
    data.descriptionEn = info.description;
    data.shortDescriptionEn = info.shortDescription;
    this.setState({ data });
  }

  getFullInfoHy = info => {
    const isValid = info.isValid;
    const data = this.state.data;
    data.titleHy = info.title;
    data.descriptionHy = info.description;
    data.shortDescriptionHy = info.shortDescription;
    this.setState({ data });

    if (!isValid) {
      this.setState({ selectedLanguage: LanguageEnum.hy });
    }
  }

  //#endregion

  getMainImages = images => {
    const data = this.state.data;
    if (typeof images.mainImage !== 'string') data.mainImage = images.mainImage;
    if (!images.cover) {
      data.deleteCover = true;
    } else if (typeof images.cover !== 'string') {
      data.cover = images.cover;
    }
    this.setState({ data });
  }

  getImages = images => {
    const data = this.state.data;
    data.image = images;
    this.setState({ data });
  }

  getDate = (key, date) => {
    const data = this.state.data;
    data[key] = date ? new Date(date) : null;
    this.setState({ data });
  }

  getActionType = obj => {
    const data = this.state.data;
    const value = obj.value;
    switch (obj.type) {
      case actionType.isEvent:
        data.isEvent = value;
        break;
      default:
        break;
    }
    this.setState({ data });
  }

  getHyFiles = files => this.hyFiles = files;
  getEnFiles = files => this.enFiles = files;

  // uploadFiles = async (fileList) => {
  //   let isValid = true;
  //   const files = fileList.map(file => file.getBaseModel());
  //   files.forEach(async baseModel => {
  //     const formData = new FormData();
  //     formData.append('eventId', this.eventId || this.draftId);
  //     for (const key in baseModel) {
  //       const item = baseModel[key];
  //       if (baseModel.hasOwnProperty(key)) {
  //         item && formData.append(key, item);
  //       }
  //     }
  //     const res = await EventService.File(formData);
  //     if (!res.success) {
  //       isValid = false;
  //       // TODO - show error message - baseModel.name file upload error
  //     }
  //   });
  //   return isValid;
  // }

  getDeletedImagesId = (ids, images) => {
    this.deletedImageList = ids;
    this.imagesUrl = images;
  }

  getDeletedFiles = (ids) => {
    this.deletedFileList = ids;
  }

  getAddress = info => {
    const data = this.state.data;
    data.address = info.formatted_address;
    data.lat = info.geometry.location.lat();
    data.lng = info.geometry.location.lng();
    this.setState({ data });
  }

  getProgramId = id => {
    const data = this.state.data;
    data.programId = id;
    this.setState({ data });
  }

  uploadFiles = async (fileList, taskId) => {
    let isValid = true;
    const files = fileList.map(file => file.getBaseModel());
    files.forEach(async baseModel => {
      const formData = new FormData();
      formData.append('taskId', taskId);
      formData.append('type', this.state.id ? 2 : 1); // ! TODO Change this
      for (const key in baseModel) {
        const item = baseModel[key];
        if (baseModel.hasOwnProperty(key)) {
          item && formData.append(key, item);
        }
      }
      const res = await TaskServices.File(formData);
      if (!res.success) {
        isValid = false;
        // TODO - show error message - baseModel.name file upload error
      }
    });
    return isValid;
  }
  
  save = async () => {
    this.enInfo.fullInfo();
    this.hyInfo.fullInfo();
    if (this.userIdList) this.userIdList.fullInfo();

    const data = this.state.data;
    data.deletedImageList = this.deletedImageList;
    data.deletedFileList = this.deletedFileList;

    if (data.validate()) {
      const formData = new FormData();
      const baseModel = data.getBaseModel();
      formData.append('activityId', this.state.activityId);
      this.state.id && formData.append('id', this.state.id);
      if (this.userIdList && this.userIdList.length) {
        this.userIdList.forEach((userId, index) => {
          formData.append(`userIdList[${index}]`, userId);
        });
      }
      for (const key in baseModel) {
        if (baseModel.hasOwnProperty(key)) {
          const item = baseModel[key];
          if (item) {
            if (key === 'image' && item.length) {
              item.forEach((img, index) => {
                formData.append(key, img);
              });
            } else if ((key === 'deletedImageList' || key === 'deletedFileList' || key === 'userIdList') && item.length) {
              item.forEach((img, index) => {
                formData.append(`${key}[${index}]`, img);
              });
            } else {
              formData.append(key, item);
            }
          } else if (key === 'isEvent') {
            formData.append(key, item || false);
          }
        }
      }
      const addResponse = this.state.id ? await TaskServices.Edit(formData) : await TaskServices.Add(formData);
      if (addResponse && addResponse.success) {
        const taskId = addResponse.data;
        this.hyFiles && this.uploadFiles(this.hyFiles, taskId);
        this.enFiles && this.uploadFiles(this.enFiles, taskId);
        HISTORY.goBack();
      }
    }
  }

  membersInfoChange = info => {
    const { data } = this.state;
    
    if (info && info.selectedList) {
      data.userIdList = info.selectedList.map(item => item._id);
      this.setState({ data });
    }
  }

  componentDidMount() {

    const params = new URLSearchParams(HISTORY.location.search);
    const activityId = params.get('activityId');
    const id = params.get('id');    

    if (!activityId) {
      HISTORY.goBack();
    }

    this.setState({ id, activityId }, async () => {
      await this.fetchDateRanges();
      id && this.fetchDetails();
    });
  }

  fetchDateRanges = async () => {
    const { id, activityId } = this.state;
    const result = await ActivityService.Range(activityId);

    if (result.data) {
      this.setState({
        startMinDate: result.data.range.startDate.min || undefined,
        startMaxDate: result.data.range.startDate.max || undefined,
        endMinDate: result.data.range.endDate.min || undefined,
        endMaxDate: result.data.range.endDate.max || undefined,
        hideUsers: result.data.hideUsers,
        data: new TaskMainViewModel(result.data.hideUsers),
      });

      !id && this.setState({ dataFetched: true });
    }
  }

  render() {
    const {
      id,
      data,
      selectedLanguage,
      imagesUrl,
      activityId,
      startMinDate,
      startMaxDate,
      endMinDate,
      endMaxDate,
      hideUsers,
      dataFetched,
    } = this.state;

    return data && dataFetched ? (
      <div className="App-page App-add-page-news-event">
        <AppPageToolbar className="G-pl-2 G-pr-2 G-flex G-justify-between">
          <h6>Tasks/ <span className="G-page-active-title">{id ? 'Edit task' : 'Add task'}</span></h6>
          <div className="G-flex G-align-center">
            <LanguageSelector
              languageList={languageCodeList}
              selectedLanguage={selectedLanguage}
              selectLanguage={value => this.setState({ selectedLanguage: value })}
            />
            <button className="G-btn App-save-btn G-ml-4" onClick={this.save}>Save</button>
          </div>
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-page-content">

            <div className="block App-manage-event-news-image">
              <Images mainImage={this.mainImage} cover={this.coverImage} output={this.getMainImages}/>
            </div>

            <div className="line"/>

            <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
              <TaskInfo
                info={{
                  title            : data.titleEn,
                  description      : data.descriptionEn,
                  shortDescription : data.shortDescriptionEn,
                }}
                ref={instance => { this.enInfo = instance }}
                getFullInfo={this.getFullInfoEn}
              />
            </div>

            <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.hy })}>
              <TaskInfo
                requiredFields
                info={{
                  title            : data.titleHy,
                  description      : data.descriptionHy,
                  shortDescription : data.shortDescriptionHy,
                }}
                ref={instance => { this.hyInfo = instance }}
                getFullInfo={this.getFullInfoHy} 
              />
            </div>


            <div className="block google">
              <div className="G-input-box">
			          <p>Place</p>
                <Autocomplete
                  defaultValue={data.address || ''}
                  onPlaceSelected={this.getAddress}
                  types={['address']}
                />
              </div>
              <AppGoogleMap
                lat={data.lat && +(data.lat)}
                lng={data.lng && +(data.lng)}
              />
            </div>

            <div className="App-manage-event-location-block G-flex G-justify-between G-half-input-box">
              <DatePicker
                title="Select task start date"
                minDate={startMinDate}
                maxDate={startMaxDate}
                defaultValue={data.startDate}
                error={data.errors.startDate}
                onChange={date => this.getDate('startDate', date)}
              />

              <DatePicker
                title="Select task end date"
                minDate={endMinDate}
                maxDate={endMaxDate}
                defaultValue={data.endDate}
                error={data.errors.endDate}                
                onChange={date => this.getDate('endDate', date)}
              />
            </div>

            {!hideUsers && <ManagersInfo
              info={{ managerList: data.userIdList }}
              withoutResponsible
              error={data.errors.userIdList}
              activityId={activityId}
              name='User'
              ref={instance => { this.userIdList = instance }}
              changeData={this.membersInfoChange}
            />}

            <div className={classnames('block', 'G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
              <AddFiles
                uploadedFiles={this.uploadedFilesEn}
                outputDeletedFiles={ids => this.getDeletedFiles(ids)}
                language={LanguageEnum.en}
                output={this.getEnFiles}
              />
            </div>

            <div className={classnames('block', 'G-hide', { 'G-show': selectedLanguage === LanguageEnum.hy })}>
              <AddFiles
                uploadedFiles={this.uploadedFilesHy}
                outputDeletedFiles={ids => this.getDeletedFiles(ids)}
                language={LanguageEnum.hy}
                output={this.getHyFiles}
              />
            </div>

            <div className="line"/>

            <div className="block">
              <div className="G-flex G-mb-2">
                <h6 className="title">Add image(s)</h6>
              </div>
              <MultiImageUpload
                images={imagesUrl}
                uniqueKey="multi-image-upload"
                output={this.getImages}
                getDeletedImagesId={this.getDeletedImagesId}
              />
            </div>

            <div className="line"/>

            <div className="block">
              <Action
                isEvent={data.isEvent}
                onChange={this.getActionType}
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  }
}