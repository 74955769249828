import React, { Component } from 'react';

import { tableRows } from './services/constant';

import { to }                  from '../../../routes';
import { HISTORY }             from '../../../routes/ClientRoutes';
import BookService             from '../../../platform/api/book';
import { PaginationViewModel } from '../../../platform/view-models';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import AppTable                from '../../../platform/components/table/Table';
import TableToolbar            from '../../../platform/components/table/TableToolbar';
import settings from '../../../platform/services/settings';

export default class Books extends Component {

  state = {
    list            : [],
    selectedItemsId : [],
    itemCount       : 0,
    page: 1,
    limit: 10
  }

  //#region Table

  paginationData;

  getList = async () => {
    const res = await BookService.List(this.paginationData.getBaseModel());
    this.setState({ page: this.paginationData.pageNo, limit: this.paginationData.limit });
    if (res.success && res.data) {
      this.setState({
        selectedItemsId : [],
        list            : res.data.itemList || [],
        itemCount       : res.data.itemCount || 0,
      });
    }
  }
  
  changePage = page => {
    this.paginationData.pageNo = page;
    settings.setBookPageNo(page);
    this.setState({ page: this.paginationData.pageNo });
    this.getList();
  }

  changeLimit = limit => {
    this.paginationData = new PaginationViewModel();
    this.paginationData.limit = +(limit);
    this.setState({ limit: this.paginationData.limit });
    this.getList();
  }

  
  //#region Selected Items

  getSelectedItems = items => this.setState({ selectedItemsId: items });

  delete = async () => {
    const { selectedItemsId, list } = this.state;
    const res = await BookService.Delete(selectedItemsId);
    if (res.success) {
      if (list.length - selectedItemsId.length === 0 && this.paginationData.pageNo !== 1) {
        this.paginationData.pageNo--;
      }
      this.getList();
    }
  }

  //#endregion Selected items

  //#endregion Table
  
  goAddPage = async () => {
    const res = await BookService.Create();
    if (res.success && res.data) {
      HISTORY.push(`${to.booksManage}?draftId=${res.data}`);
    } else {
      // TODO - Send Error message
    }
  }

  changeSwitchStatus = async id => {
    const res = await BookService.favoriteOrUnFavorite(id);
    if (res.success) this.getList();
  }

  edit = item => {
    if (item && item._id) HISTORY.push(`${to.booksManage}?id=${item._id}`)
  }

  componentDidMount() {
    settings.emptyAllExept('book');
    this.paginationData = new PaginationViewModel();
    const pageNo = settings.getBookPageNo();
    if (pageNo) {
      this.paginationData.pageNo = pageNo;
      this.setState({ page: this.paginationData.pageNo });
    } else {
      settings.setBookPageNo(1);
      this.setState({ page: 1 });
    }
    this.getList();
  }

  render() {
    const { list, selectedItemsId, itemCount } = this.state;
    return <div className="App-page">
      <AppPageToolbar>
        <TableToolbar title={'Book list'} count={itemCount}
          numSelected={selectedItemsId.length}
          deleteBtn deleteItem={this.delete}
        >
          <div className="G-flex">
            <button className="G-btn App-add-btn" onClick={this.goAddPage}>+Add new</button>
          </div>
        </TableToolbar>
      </AppPageToolbar>
      <div className="App-page-content-box">
        <div className="App-page-content">
          <AppTable
            // page={page}
            // limit={limit}
            rows={tableRows}
            itemCount={itemCount}
            list={list}
            editClick={this.edit}
            clearSelectedItem={!selectedItemsId.length}
            changePage={page => this.changePage(page)}
            changeLimit={limit => this.changeLimit(limit)}
            getSelectedItems={this.getSelectedItems}
            changeSwitchStatus={this.changeSwitchStatus}/>
        </div>
      </div>
    </div>
  }
}
