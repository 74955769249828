import React, { Component } from 'react';

import { tableRows } from './services/constant';

import Manage                  from './components/Manage';
import { PaginationViewModel } from '../../../../platform/view-models';
import AppPageToolbar          from '../../../../platform/components/PageToolbar';
import AppTable                from '../../../../platform/components/table/Table';
import TableToolbar            from '../../../../platform/components/table/TableToolbar';
import Modal                   from '../../../../platform/components/Modal';
import BookService             from '../../../../platform/api/book';
import settings from '../../../../platform/services/settings';

export default class BooksCategory extends Component {

  state = {
    list            : [],
    selectedItemsId : [],
    itemCount       : 0,
    modifyModalOpen : false,
    modifyData      : null,
    detailsData     : null,
  }

  //#region Table

  paginationData;

  getList = async () => {
    const res = await BookService.CategoryList(this.paginationData.getBaseModel());
    if (res.success && res.data) {
      this.setState({
        selectedItemsId : [],
        list            : res.data.itemList || [],
        itemCount       : res.data.itemCount || 0,
      });
    }
  }
  
  changePage = page => {
    this.paginationData.pageNo = page;
    this.getList();
  }

  changeLimit = limit => {
    this.paginationData = new PaginationViewModel();
    this.paginationData.limit = +(limit);
    this.getList();
  }
  
  //#region Selected Items

  getSelectedItems = items => this.setState({ selectedItemsId: items });

  delete = async () => {
    const { selectedItemsId, list } = this.state;
    const res = await BookService.DeleteCategories(this.state.selectedItemsId);
    if (res.success) {
      if (list.length - selectedItemsId.length === 0 && this.paginationData.pageNo !== 1) {
        this.paginationData.pageNo--;
      }
      this.getList();
    }
  }

  //#endregion Selected items

  //#endregion Table

  openEdit = data => this.setState({
    modifyModalOpen : true,
    modifyData      : data
  });

  openModifyModal = () => this.setState({ modifyModalOpen: true });

  closeModifyModal = (form, updateList) => {
    if (form) {
      const { list } = this.state;
      const item = list.find(item => item._id === form.id);
      item.nameEn = form.nameEn;
      item.nameHy = form.nameHy;
      this.setState({
        modifyModalOpen : false,
        modifyData      : null,
        list,
      });
    } else if (updateList) {
      this.paginationData = new PaginationViewModel();
      this.setState({
        modifyModalOpen : false,
        modifyData      : null,
      }, this.getList);
    } else {
      this.setState({
        modifyModalOpen : false,
        modifyData      : null,
      });
    }
  }

  componentDidMount() {
    settings.emtyAllPageNos();
    this.paginationData = new PaginationViewModel();
    this.getList();
  }

  render() {
    const { list, selectedItemsId, itemCount, modifyModalOpen, modifyData } = this.state;
    return <div className="App-page">
      <AppPageToolbar>
        <TableToolbar title={'Books category list'} count={itemCount}
          numSelected={selectedItemsId.length}
          deleteBtn
          deleteItem={this.delete}
        >
          <div className="G-flex">
            <button className="G-btn App-add-btn" onClick={this.openModifyModal}>+Add new</button>
          </div>
        </TableToolbar>
      </AppPageToolbar>
      <div className="App-page-content-box">
        <div className="App-page-content">
          <AppTable
            rows={tableRows}
            list={list}
            itemCount={itemCount}
            fullCellClick={this.itemClick}
            clearSelectedItem={!selectedItemsId.length}
            changePage={page => this.changePage(page)}
            changeLimit={limit => this.changeLimit(limit)}
            editClick={this.openEdit}
            getSelectedItems={this.getSelectedItems}
          />
        </div>
      </div>
      {modifyModalOpen && <Modal onClickOutside={() => this.closeModifyModal()}>
        <Manage close={() => this.closeModifyModal()} closeModal={form => this.closeModifyModal(form, true)} data={modifyData} />
      </Modal>}
    </div>
  }
}
