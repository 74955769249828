import React, { Component } from 'react'

export default class Card extends Component {

    render() {
        const { property, responsive, handleManagerClick, deleteClick, chosen } = this.props;

        return (
            <li onClick={() => handleManagerClick(property)} >
                <div
                    className="App-manager-info-autocomplete-image"
                    style={{
                        backgroundImage: `url("${property.avatar}")`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat'
                    }}
                    key={property.avatar}
                />
                <div className="App-manager-info-autocomplete-texts">
                    <p key={property.name}>{property.name}</p>
                    <span key={property.email}>{property.email}</span>
                </div>
                {responsive && <div className="App-manager-info-responsive-text">
                    <span>&#9733;</span>
                    Responsible
                </div>}
                {chosen && <div className="App-manager-info-delete-icon" onClick={() => deleteClick(property)}>
                    <span>&times;</span>
                </div>}
            </li>
        )
    }
}
