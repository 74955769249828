import React, { Component } from 'react';
import classnames           from 'classnames';
import { Editor }           from 'react-draft-wysiwyg';
import draftToHtml          from 'draftjs-to-html';
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js';

import Input              from '../../../../../../platform/components/Input';
import NotificationSystem from '../../../../../../platform/services/notificationSystem';

class EventInfoViewModel {
  isValid = true;
  title = '';
  shortDescription = '';
  description = '';

  errors = {
    title            : false,
    shortDescription : false,
    description      : false,
  }

  constructor(data) {
    if (data) {
      this.title = data.title;
      this.description = data.description;
      this.shortDescription = data.shortDescription;
    }
  }

  validate = () => {
    const errorsMessages = [];

    const ERRORS = {
      title            : false,
      shortDescription : false,
      description      : false,
    };

    if (!this.title) {
      errorsMessages.push('Title field is required');
      ERRORS.title = true;
      this.isValid = false;
    }

    if (!this.shortDescription) {
      errorsMessages.push('Short description field is required');
      ERRORS.shortDescription = true;
      this.isValid = false;
    }

    if (!this.description) {
      errorsMessages.push('Event content field is required');
      ERRORS.description = true;
      this.isValid = false;
    }

    if (!this.isValid) {
      NotificationSystem.showNotification('Validation', errorsMessages[0]);
    }

    this.errors = ERRORS;
    return this.isValid;
  }
}

export default class EventInfo extends Component {

  state = {
    data        : new EventInfoViewModel(),
    info        : null,
    editorState : EditorState.createEmpty(),
  }

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  handleEventChange = (event, name) => {
    const data = this.state.data;
    data[name] = event.target.value;
    this.setState({ data });
  };

  // ! The parent component calls this function.
  fullInfo = () => {
    const data = this.state.data;
    if (this.props.requiredFields) {
      data.validate();
      this.setState({ data });
    }
    this.props.getFullInfo(data);
  }

  setEditorState = text => {
    if (text) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(text)
        )
      );
      this.setState({ editorState });
    }
  }

  onEditorStateChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const data = this.state.data;
    data.description = html;
    this.setState({ data, editorState });
  };

  componentDidMount() {
    const { info } = this.props;
    if (info) {
      const data = new EventInfoViewModel(info);
      this.setEditorState(data.description);
      this.setState({ data, info });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { info } = nextProps;
    if (JSON.stringify(this.state.info) !== JSON.stringify(info)) {
      const data = new EventInfoViewModel(info);
      this.setEditorState(data.description);
      this.setState({ info, data });
    }
  }

  render() {
    const { data, editorState } = this.state;
    const { requiredFields } = this.props;
    return (
      <div className="App-manage-event-news-info">
        <Input
          title="Title"
          name="title"
          value={data.title}
          required={requiredFields}
          errors={requiredFields && data.errors.title}
          onChange={this.handleChange}
        />

        <div className="G-input-box">
			    <p>Short description {requiredFields && <span>*</span>}</p>
          <textarea cols="5" rows="5"
            className={classnames({ 'G-error': data.errors.shortDescription })}
            value={data.shortDescription}
            onChange={event => this.handleEventChange(event, 'shortDescription')}
          />
        </div>

        <div className="G-input-box G-input-box-desc">
			    <p>Description {requiredFields && <span>*</span>}</p>
          <Editor
            wrapperClassName={classnames({ 'G-error': data.errors.description })}
            editorState={editorState}
            toolbar={{
              options: ['inline', 'textAlign'],
              inline: {
                options: [
                  'bold',
                  'italic',
                  'underline',
                ],
              },
            }}
            onEditorStateChange={this.onEditorStateChange}
          />
        </div>

      </div>
    )
  }
}
