import React, { Component, Fragment } from 'react';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import AppPageToolbar          from '../../../platform/components/PageToolbar';
import TableToolbar            from '../../../platform/components/table/TableToolbar';
import { getShortDate }        from '../../../platform/services/helpers';

import BookService             from '../../../platform/api/book';
import settings from '../../../platform/services/settings';

const DragHandle = sortableHandle(() => <div style={{ cursor: 'pointer', fontSize: '30px' }}>:::</div>);
const SortableItem = sortableElement(({ onClick, value }) => <div className="App-page-favorite-item" onClick={onClick}><DragHandle />{value}</div>);
const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);

export default class BookFavorites extends Component {

  state = {
    itemList: []
  }

  componentDidMount() {
    settings.emtyAllPageNos();
    this.getList();
  }

  getList = async() => {
    const result = await BookService.FavoriteList();
    if (result && result.success) {
      this.setState({ itemList: result.data });
    }
  }
 
  onSortEnd = async({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let { itemList } = this.state;
      const id = itemList[oldIndex]._id;
      const position = newIndex + 1;
      const result = await BookService.Position({ id, position });
      if (result && result.success) {
        this.setState({ itemList: result.data });
      }
    }
  };

  getItem = (item) => {
    return (
      <Fragment>
        <div style={{ width: '200px' }}>{item.position}</div>
        <div style={{ width: '200px' }}>{item.nameEn ? item.nameEn : '-'}</div>
        <div style={{ width: '200px' }}>{item.nameHy}</div>
        <div style={{ width: '200px' }}>{getShortDate(item.createdDt)}</div>
      </Fragment>
    )
  }

  render() {
    const { itemList } = this.state;
    return (
      <div className="App-page">
        <AppPageToolbar>
          <TableToolbar title={'Favorite books'}></TableToolbar>
        </AppPageToolbar>
        <div className="App-page-favorite-box">
          <div className="App-page-favorite-content">
            <div className="App-page-favorite-header">
              <div className="short"></div>
              <div style={{ width: '200px' }}>Position</div>
              <div style={{ width: '200px' }}>English name</div>
              <div style={{ width: '200px' }}>Armenian name</div>
              <div style={{ width: '200px' }}>Creation date</div>
            </div>
            <div style={{ maxHeight: 'calc(100vh - 250px)' }}>
              <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                {itemList.map((item, index) => <SortableItem 
                index={index}
                key={item._id}
                value={this.getItem(item)}/>)}
              </SortableContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
