import React, { Component } from 'react';

import DatePickerYearMonth from './DatePicker';
import Input               from '../../../../platform/components/Input';
import FileUpload          from '../../../../platform/components/FileUpload';
import ImageUpload         from '../../../../platform/components/ImageUpload';
import Notification        from '../../../../platform/services/notificationSystem';

import newspaperApis from '../../../../platform/api/newspaper';

import Switch          from '@material-ui/core/Switch';

import Closer from '../../../../platform/components/Closer';

export default class AddNewspaper extends Component {

  state = {

    id: '',
    oldImageUrl: '',

    pdfId: '',
    defaultCoverPath: '',

    uploadedImage: '',

    nameHy: 'ՀաՄաՏեղ ծրագրի պաշտոնաթերթ',
    nameEn: 'Official Newspaper of CELoG program',

    date: '',
    number: '',

    deleteCover: false,

    isActive: true,
    submitted: false,

    errors: {
      pdfId: false,
      image: false,
      nameHy: false,
      nameEn: false,
      date: false,
      number: false
    }

  }

  componentDidMount = async() => {
    const data = this.props.data;
    if (data) {
      this.setState({
        id: data._id,
        defaultCoverPath: data.defaultCoverPath,
        nameHy: data.nameHy,
        nameEn: data.nameEn,
        date: data.date,
        number: data.number,
        isActive: data.isActive,
        oldImageUrl: data.cover || ''
      });
    } else {
      const result = await newspaperApis.GetNumber();
      if (result && result.success) this.setState({ number: result.data });
    }
  }

  handleChange = ({ name, value }) => {
    this.setState({ [name]: value, submitted: false });
  };

  getDate = (date) => {
    this.setState({ date: new Date(date), submitted: false });
  }

  submit = async() => {
    if (!this.state.submitted) {
      this.setState({ submitted: true }, async() => {
        const Err = this.validate();
        if (!Err) {
          const { id, pdfId, uploadedImage, nameEn, nameHy, number, isActive, date, deleteCover } = this.state;
          const DATA = new FormData();
          if (id) DATA.append('id', id);
          if (id && deleteCover) DATA.append('deleteCover', true);
          if (pdfId) DATA.append('fileId', pdfId);
          DATA.append('titleEn', nameEn);
          DATA.append('titleHy', nameHy);
          DATA.append('isActive', isActive);
          DATA.append('date', date);
          if (number) DATA.append('number', number);
          if (uploadedImage) DATA.append('image', uploadedImage);
          let result;
          if (!id) result = await newspaperApis.Create(DATA)
          else result =  await newspaperApis.Update(DATA)
          if (result && result.success) {
            // close modal refresh 
            this.props.closeModal();
          } else {
            Notification.showNotification('Error', result.message);
            this.setState({ submitted: false });
          }
        }
      });
    }
  }

  validate = () => {
    const { id, pdfId, nameHy, nameEn, date } = this.state;
    const errors = {
      image: false,
      nameHy: false,
      nameEn: false,
      date: false,
      number: false
    };
    let isErr = false;
    if (!id && !pdfId) {
      errors.pdfId = true;
      isErr = true;
    }
    if (!nameHy) {
      errors.nameHy = true;
      isErr = true;
    }
    if (!nameEn) {
      errors.nameEn = true;
      isErr = true;
    }
    if (!nameEn) {
      errors.nameEn = true;
      isErr = true;
    }
    if (!date) {
      errors.date = true;
      isErr = true;
    }
    this.setState({ errors });
    return isErr;
  }

  handleUpload = async (file) => {
    this.setState({ submitted: false }, async () => {
      if (file) {
        const DATA = new FormData();
        DATA.append('file', file)
        const result = await newspaperApis.Draft(DATA);
        if (result && result.success) {
          const data = result.data;
          this.setState({ pdfId: data.id, defaultCoverPath: this.state.uploadedImage ? '' : data.path });
        }
      } else {
        this.setState({ pdfId: '', defaultCoverPath: '' });
      }
    });
  }

  handleImage = (image) => {
    this.setState({ uploadedImage: image });
    if (!image && this.state.oldImageUrl) this.setState({ deleteCover: true, oldImageUrl: '' });
  }

  changeActivity = () => {
    this.setState({ isActive: !this.state.isActive, submitted: false });
  }
  
  render() {

    const { nameHy, nameEn, number, date, isActive, errors, uploadedImage, oldImageUrl, defaultCoverPath, submitted } = this.state;
    
    return(
      <div className="App-newspaper-add">
        <div className="G-flex G-align-center G-mb-2">
          <h4>Add newspapers</h4>
          <Closer close={this.props.closeModal}/>
        </div>
        <div className="App-newspaper-add-body">
          <div className="App-newspaper-add-body-image-container">
            <div className="App-newspaper-add-body-image-box">
              <ImageUpload
                imageUrl={uploadedImage ? '' : oldImageUrl ? oldImageUrl : defaultCoverPath}
                uniqueKey="image"
                output={file => this.handleImage(file)}
              />
            </div>
          </div>
          <div className="App-newspaper-add-body-main">
            <Input
              title="Name in armenian"
              name="nameHy"
              value={nameHy}
              onChange={this.handleChange}
              errors={submitted && errors.nameHy}
            />
            <Input
              title="Name in english"
              name="nameEn"
              value={nameEn}
              errors={submitted && errors.nameEn}
              onChange={this.handleChange}
            />
            <div className="App-newspaper-add-date-number">
              <DatePickerYearMonth
                defaultValue={date}
                title="Date"
                onOpen={state => this.props.onOpenDatePicker && this.props.onOpenDatePicker(state)}
                onChange={date => this.getDate(date)}
                error={submitted && errors.date}
              />
              <Input
                title="Paper number"
                name="number"
                className="G-ml-2"
                value={number}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <p>Upload Book</p>
              <FileUpload
                accept='.pdf'
                output={file => this.handleUpload(file)}
                error={submitted && errors.pdfId}
              />
            </div>
            <div className="App-newspaper-add-activity">
              <p>Is Active</p>
              <Switch
                checked={isActive}
                onChange={() => this.changeActivity()}
              />
            </div>
          </div>
        </div>
        <div className="App-newspaper-add-footer">
          <button className="active" onClick={this.submit}>Save</button>
        </div>
      </div>
    )
  }

}