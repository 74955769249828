import React, { Component } from 'react';
import classnames           from 'classnames';
import draftToHtml          from 'draftjs-to-html';
import {
  convertToRaw,
  EditorState,
} from 'draft-js';

import Input              from '../../../../platform/components/Input';
import NotificationSystem from '../../../../platform/services/notificationSystem';
import ReactQuill from 'react-quill'; 
import 'react-quill/dist/quill.snow.css';

class EventInfoViewModel {
  isValid = true;
  title = '';
  shortDescription = '';
  description = '';

  errors = {
    title            : false,
    shortDescription : false,
    description      : false,
  }

  constructor(data) {
    if (data) {
      this.title = data.title;
      this.description = data.description;
      this.shortDescription = data.shortDescription;
    }
  }

  validate = () => {
    const errorsMessages = [];

    const ERRORS = {
      title            : false,
      shortDescription : false,
      description      : false,
    };

    if (!this.title) {
      errorsMessages.push('Title field is required');
      ERRORS.title = true;
      this.isValid = false;
    }


    if (!this.shortDescription) {
      errorsMessages.push('Short description field is required');
      ERRORS.shortDescription = true;
      this.isValid = false;
    }

    if (!this.description) {
      errorsMessages.push('Event content field is required');
      ERRORS.description = true;
      this.isValid = false;
    }

    if (!this.isValid) {
      NotificationSystem.showNotification('Validation', errorsMessages[0]);
    }

    this.errors = ERRORS;
    this.isValid = !Object.values(this.errors).find(item => !item);

    return this.isValid;
  }
}

export default class EventInfo extends Component {

  state = {
    data        : new EventInfoViewModel(),
    info        : null,
    editorState : EditorState.createEmpty(),
  }

  modules = {
    toolbar: [
      [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      ['link', 'image', 'video'],
      ['clean'],[{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    ],
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    }
  }
  /* 
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  formats = [
    'header', 'font', 'size','link',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'bullet', 'indent','align', 'list',
    'image', 'video'
  ]

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  handleEventChange = (event, name) => {
    const data = this.state.data;
    data[name] = event.target.value;
    this.setState({ data });
  };

  // ! The parent component calls this function.
  fullInfo = () => {
    const data = this.state.data;
    if (this.props.requiredFields) {
      data.validate();
      this.setState({ data });
    }
    this.props.getFullInfo(data);
  }

  setEditorState = text => {
    // console.log(text)
    // if (text) {
    //   const editorState = EditorState.createWithContent(
    //     ContentState.createFromBlockArray(
    //       convertFromHTML(text)
    //     )
    //   );
    //   this.setState({ editorState });
    // }

    this.state.data.description = text;
  }

  onEditorStateChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const data = this.state.data;
    data.description = html;
    this.setState({ data, editorState });
  };

  componentDidMount() {
    const { info } = this.props;
    if (info) {
      const data = new EventInfoViewModel(info);
      this.setEditorState(data.description);
      this.setState({ data, info });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { info } = nextProps;
    if (JSON.stringify(this.state.info) !== JSON.stringify(info)) {
      const data = new EventInfoViewModel(info);
      this.setEditorState(data.description);
      this.setState({ info, data });
    }
  }

  render() {
    const { data, editorState } = this.state;
    const { requiredFields } = this.props;
    return (
      <div className="App-manage-event-news-info">
        <Input
          title="Title"
          name="title"
          value={data.title}
          required={requiredFields}
          errors={requiredFields && data.errors.title}
          onChange={this.handleChange}
        />

        <div className="G-input-box">
			    <p>Short description {requiredFields && <span>*</span>}</p>
          <textarea cols="5" rows="5"
            className={classnames({ 'G-error': data.errors.shortDescription })}
            value={data.shortDescription}
            onChange={event => this.handleEventChange(event, 'shortDescription')}
          />
        </div>

        <div className="G-input-box G-input-box-desc">
			    <p>Description {requiredFields && <span>*</span>}</p>

          
          <ReactQuill value={this.state.data.description || this.state}
                  onChange={this.setEditorState} 
                  modules={this.modules}
                  formats={this.formats}
          />

        </div>

      </div>
    )
  }
}
