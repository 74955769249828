import NotificationSystem from '../../services/notificationSystem';

export class TaskMainViewModel {
  id;
  isEvent = false;
  address = '20 Ervand Qochar str.';
  lat = 40.1692799;
  lng = 44.515708399999994;
  startDate; endDate;
  cover; mainImage; image; deletedImageList; deletedFileList; deleteCover;
  titleHy; titleEn; shortDescriptionHy; shortDescriptionEn; descriptionHy; descriptionEn;
  programId; programName;
  userIdList;
  hideUsers;

  errors = {
    descriptionHy      : false,
    shortDescriptionHy : false,
    startDate          : false,
    endDate            : false,
    titleHy            : false,
    userIdList         : false,
  };

  constructor(hideUsers, data) {
    this.hideUsers = hideUsers;
    if (data) {
      this.address = data.address;
      this.cover = data.cover;
      this.deleteCover = data.deleteCover;
      this.deletedFileList = data.deletedFileList;
      this.deletedImageList = data.deletedImageList;
      this.descriptionEn = data.descriptionEn;
      this.descriptionHy = data.descriptionHy;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.id = data.id;
      this.image = data.image;
      this.lat = data.lat;
      this.lng = data.lng;
      this.mainImage = data.mainImage;
      this.userIdList = data.userIdList;
      this.shortDescriptionEn = data.shortDescriptionEn;
      this.shortDescriptionHy = data.shortDescriptionHy;
      this.titleEn = data.titleEn;
      this.titleHy = data.titleHy;
      this.isEvent = data.isEvent;
    }
  }

  getBaseModel = () => {
    return {
      cover              : this.cover,
      deleteCover        : this.deleteCover,
      deletedFileList    : this.deletedFileList,
      deletedImageList   : this.deletedImageList,
      descriptionEn      : this.descriptionEn,
      descriptionHy      : this.descriptionHy,
      endDate            : this.endDate,
      id                 : this.id,
      image              : this.image,
      lat                : this.lat,
      lng                : this.lng,
      mainImage          : this.mainImage,
      shortDescriptionEn : this.shortDescriptionEn,
      shortDescriptionHy : this.shortDescriptionHy,
      userIdList         : this.userIdList,
      startDate          : this.startDate,
      titleEn            : this.titleEn,
      titleHy            : this.titleHy,
      address            : this.address,
      programId          : this.programId,
      isEvent            : this.isEvent
    }
  }

  validate = () => {
    let isValid = true;

    const errorsMessages = [];

    const ERRORS = {
      descriptionHy      : false,
      shortDescriptionHy : false,
      startDate          : false,
      endDate            : false,      
      titleHy            : false,
      userIdList         : false,
    };

    if (!this.descriptionHy) {
      errorsMessages.push('Description Hy is not valid');
      ERRORS.descriptionHy = true;
      isValid = false;
    }

    if (!this.shortDescriptionHy) {
      errorsMessages.push('Short Description Hy is not valid');
      ERRORS.shortDescriptionHy = true;
      isValid = false;
    }

    if (!this.hideUsers && (!this.userIdList || !this.userIdList.length)) {
      errorsMessages.push('Attach users');
      ERRORS.userIdList = true;
      isValid = false;
    }

    if (!this.startDate) {
      errorsMessages.push('Start Date is not valid');
      ERRORS.startDate = true;
      isValid = false;
    }

    if (!this.endDate) {
      errorsMessages.push('End Date is not valid');
      ERRORS.endDate = true;
      isValid = false;
    }

    if (!this.titleHy) {
      errorsMessages.push('Title Hy is not valid');
      ERRORS.titleHy = true;
      isValid = false;
    }

    if (!isValid) {
      NotificationSystem.showNotification('Validation', errorsMessages[0]);
    }   

    this.errors = ERRORS;
    return isValid;
  }
}

export class FileViewModel {
  id;
  language;
  name = '';
  file;

  errors = {
    file: false,
    name: false
  };

  constructor(data) {
    if (data) {
      this.id = data.id;
      this.language = data.language;
      this.name = data.name;
      this.file = data.file;
    }
  }

  getBaseModel = () => {
    return {
      id       : this.id,
      language : this.language,
      name     : this.name,
      file     : this.file,
    }
  }

  validate = () => {
    let isValid = true;

    const errorsMessages = [];

    const ERRORS = {
      file : false,
      name : false
    };

    if (!this.name) {
      errorsMessages.push('Name field is required');
      ERRORS.name = true;
      isValid = false;
    }

    if (!this.file) {
      errorsMessages.push('Choose file');
      ERRORS.file = true;
      isValid = false;
    }

    if (!isValid) {
      NotificationSystem.showNotification('Validation', errorsMessages[0]);
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class UploadedFileViewModel {
  id;
  language;
  name = '';

  errors = {
    name: false
  };

  constructor(data) {
    if (data) {
      this.id = data.id;
      this.language = data.language;
      this.name = data.name;
    }
  }

  getBaseModel = () => {
    return {
      id       : this.id,
      language : this.language,
      name     : this.name,
    }
  }

  validate = () => {
    let isValid = true;

    const errorsMessages = [];

    const ERRORS = {
      name : false
    };

    if (!this.name) {
      errorsMessages.push('Name field is required');
      ERRORS.name = true;
      isValid = false;
    }

    if (!isValid) {
      NotificationSystem.showNotification('Validation', errorsMessages[0]);
    }

    this.errors = ERRORS;
    return isValid;
  }
}