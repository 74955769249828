import NotificationSystem from '../../services/notificationSystem';
import {
  emailErrorMessage,
  globalErrorMassage,
  confirmErrorMessage,
} from '../../lang/messages';

export class LoginViewModel {
  email = '';
  password = '';

  errors = {
    email    : false,
    password : false,
  };

  constructor(data) {
    if (data) {
      this.email = data.email;
      this.password = data.password;
    }
  }

  getBaseModel = () => {
    return {
      email    : this.email,
      password : this.password,
    }
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      email    : false,
      password : false,
    };

    const emailValid = this.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!emailValid) {
      ERRORS.email = true;
      isValid = false;
      NotificationSystem.showNotification('Warning', emailErrorMessage.en);
    }

    if (!this.password) {
      ERRORS.password = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class RegistrationViewModel {
  avatar = null; // Type - Avatar enum
  avatarImage = null; // Type - File
  code = '';
  descriptionEn = '';
  descriptionHy = '';
  email = '';
  firstNameEn = '';
  firstNameHy = '';
  lastNameEn = '';
  lastNameHy = '';
  password = '';
  repeatPassword = '';
  specialtyEn = '';
  specialtyHy = '';

  errors = {
    code           : false,
    email          : false,
    firstNameEn    : false,
    lastNameEn     : false,
    password       : false,
    repeatPassword : false,
  };

  constructor(data) {
    if (data) {
      this.avatar = data.avatar;
      this.avatarImage = data.avatarImage;
      this.code = data.code;
      this.descriptionEn = data.descriptionEn;
      this.descriptionHy = data.descriptionHy;
      this.email = data.email;
      this.firstNameEn = data.firstNameEn;
      this.firstNameHy = data.firstNameHy;
      this.lastNameEn = data.lastNameEn;
      this.lastNameHy = data.lastNameHy;
      this.password = data.password;
      this.repeatPassword = data.repeatPassword;
      this.specialtyEn = data.specialtyEn;
      this.specialtyHy = data.specialtyHy;
    }
  }

  getBaseModel = () => {
    return {
      avatar        : this.avatar,
      avatarImage   : this.avatarImage,
      code          : this.code,
      descriptionEn : this.descriptionEn,
      descriptionHy : this.descriptionHy,
      email         : this.email,
      firstNameEn   : this.firstNameEn,
      firstNameHy   : this.firstNameHy,
      lastNameEn    : this.lastNameEn,
      lastNameHy    : this.lastNameHy,
      password      : this.password,
      repeatPassword: this.repeatPassword,
      specialtyEn   : this.specialtyEn,
      specialtyHy   : this.specialtyHy,
    }
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      firstNameEn    : false,
      lastNameEn     : false,
      password       : false,
      repeatPassword : false,
    };

    if (!this.firstNameEn) {
      ERRORS.firstNameEn = true;
      isValid = false;
    }

    if (!this.lastNameEn) {
      ERRORS.lastNameEn = true;
      isValid = false;
    }

    if (!this.password) {
      ERRORS.password = true;
      isValid = false;
    }

    if (!this.repeatPassword || (this.repeatPassword && this.repeatPassword !== this.password)) {
      ERRORS.repeatPassword = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class ForgotEmailViewModel {
  email = '';

  errors = {
    email : false,
  };

  constructor(data) {
    if (data) {
      this.email = data.email;
    }
  }

  getBaseModel = () => {
    return {
      email : this.email,
    }
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      email : false
    };

    const emailValid = this.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!emailValid) {
      ERRORS.email = true;
      isValid = false;
      NotificationSystem.showNotification('Warning', emailErrorMessage.en);
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class ForgotCheckViewModel {
  email = '';
  code = '';

  errors = {
    code : false,
  };

  constructor(data) {
    if (data) {
      this.email = data.email;
      this.code = data.code;
    }
  }

  getBaseModel = () => {
    return {
      email    : this.email,
      code : this.code,
    }
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      code : false,
    };

    if (!this.code) {
      ERRORS.code = true;
      isValid = false;
      NotificationSystem.showNotification('Error', globalErrorMassage.en);
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class ForgotViewModel {
  email = '';
  code = '';
  password = '';
  repeatPassword = '';

  errors = {
    password       : false,
    repeatPassword : false,
  };

  constructor(data) {
    if (data) {
      this.email = data.email;
      this.code = data.code;
      this.password = data.password;
    }
  }

  getBaseModel = () => {
    return {
      email    : this.email,
      code     : this.code,
      password : this.password
    }
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      password       : false,
      repeatPassword : false,
    };

    if (!this.password) {
      ERRORS.password = true;
      isValid = false;
    }

    if (!this.repeatPassword || (this.repeatPassword && this.repeatPassword !== this.password)) {
      ERRORS.repeatPassword = true;
      isValid = false;
      NotificationSystem.showNotification('Warning', confirmErrorMessage.en);
    }

    this.errors = ERRORS;
    return isValid;
  }
}
