import { connection } from '../../services/connection';

const controllerName = 'Notification';

export const NotificationTypeEnum = {
  managerInProgram: 1,              
  responsibleManagerInProgram: 2,        
  removedManagerInProgram: 3,            
  removedResponsibleManagerInProgram: 4, 
  responsibleUserInActivity: 5,
  userInActivity: 6,
  removedUserInActivity: 7,
  removedResponsibleUserInActivity: 8,
  userInTask: 9,
  removedUserInTask: 10,
  reminderTommorow: 11,
  reminderToday: 12,
  reminderOneHour: 13,
  outDatedReminderOneHour: 14,
};

export default class NotificationService {

  //#region POST

  //#endregion

  //#region PUT

  //#endregion

  //#region GET

  static List = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}?pageNo=${data.pageNo}&limit=${data.limit}`,
      method : 'GET',
    });
  }

  static Badge = () => {
    return connection({
      url    : `${controllerName}/badge`,
      method : 'GET',
    });
  }

  //#endregion

  //#region DELETE

  //#endregion
}