import { connection } from '../../services/connection';

const controllerName = 'Newspaper';

export default class NewspaperService {

  //#region POST

  static List = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}/adminList`,
      body   : data,
      method : 'POST',
    });
  }

  static Draft = (formData) => {
    return connection({
      url         : `${controllerName}/draft`,
      method      : 'POST',
      body        : formData,
      contentType : undefined,
    });
  }

  static Create = (formData) => {
    return connection({
      url         : `${controllerName}`,
      method      : 'POST',
      body        : formData,
      contentType : undefined,
    });
  }

  //#endregion

  //#region PUT

  static Update = (formData) => {
    return connection({
      url         : `${controllerName}`,
      method      : 'PUT',
      body        : formData,
      contentType : undefined,
    });
  }

  //#endregion

  //#region GET
  
  static GetDetails = (id) => {
    return connection({
      url         : `${controllerName}?id=${id}`,
      method      : 'GET'
    });
  }

  static GetNumber = () => {
    return connection({
      url         : `${controllerName}/last`,
      method      : 'GET'
    });
  }


  //#endregion

  //#region DELETE

  static Delete = ids => {
    return connection({
      url    : `${controllerName}`,
      method : 'DELETE',
      body   : { idList: ids },
    });
  }

  //#endregion
}