export const tableRowsForCovers = [
  {
    key   : 'path',
    label : 'Photo',
    photo : true,
  },
  {
    key   : 'name',
    label : 'Page',
  },
  {
    key   : 'type',
    label : 'Type',
  },
  {
    key   : 'updatedDt',
    date  : true,
    label : 'Last update date',
  },
  {
    key      : '',
    editIcon : true,
    label    : 'Actions',
  },
];
