import React, { Component } from 'react';
import classNames           from 'classnames';

// import { DatePickerInput } from 'rc-datepicker';
import DatePicker from '../../../../../platform/components/DatePicker';

import { ModifyVideoViewModel } from '../../../../../platform/api/media/view-model';
import MediaService from '../../../../../platform/api/media';

import Closer from '../../../../../platform/components/Closer';

export default class Manage extends Component {

  state = {
    form: new ModifyVideoViewModel(),
  }

  componentDidMount() {
    const { data } = this.props;
    data && this.setState({ form: new ModifyVideoViewModel(data) });
  }

  handleChange = event => {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  dateChange = date => {
    const { form } = this.state;
    form.date = date.toISOString();
    this.setState({ form });
  };

  save = async () => {
    const { data } = this.props;
    const { form } = this.state;
    const valid = form.validate();
    if (valid) {
      if (data) {
        const editForm = { id: data._id, ...form.getBaseModel() };        
        const res = await MediaService.EditVideo(editForm);      
        res.success && this.props.closeModal(editForm);
      } else {
        const res = await MediaService.CreateVideo(form.getBaseModel());
        res.success && this.props.closeModal();
      }
    } else this.setState({ form });
  }

  render() {
    const { data } = this.props;
    const { form } = this.state;

    return (
      <div className="App-modify-media">
        <div className="G-flex G-align-center G-mb-2">
          <h4>{data ? 'Update video' : 'Add video'}</h4>
          <Closer close={this.props.close}/>
        </div>
        <div className="App-modify-media-content G-flex G-justify-between">
          <div className="G-input-box">
            <p>English name</p>
            <input
              type="text"
              name="nameEn"
              value={form.nameEn}
              className={classNames({ 'G-error': form.errors.nameEn })}
              onChange={this.handleChange} />
          </div>
          <div className="G-input-box"> 
            <p>Armenian name</p>
            <input
              type="text"
              name="nameHy"
              value={form.nameHy}
              className={classNames({ 'G-error': form.errors.nameHy })}
              onChange={this.handleChange} />
          </div>
          <DatePicker
            defaultValue={form.date}
            title="Date"
            onOpen={state => this.props.onOpenDatePicker && this.props.onOpenDatePicker(state)}
            onChange={date => this.dateChange(date)}
            error={form.errors.date}
          />
          <div className="G-input-box"> 
            <p>Youtube URL</p>
            <input
              type="text"
              name="path"
              value={form.path}
              className={classNames({ 'G-error': form.errors.path })}
              onChange={this.handleChange} />
          </div>
        </div>
        <div className="G-flex G-justify-end">
          <button className="G-btn App-btn" onClick={this.save}>Save</button>
        </div>
      </div>
    )
  }
}