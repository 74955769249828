import React, { Component } from 'react';

class Approve extends Component {

  render() {
    return (
      <div className="App-program-modal-delete">
        <div className="G-flex G-align-center G-mb-2">
          <h5>{this.props.data.title}</h5>
        </div>
        <div className="App-program-modal-delete-body">{this.props.data.body}</div>
        <div className="App-program-modal-delete-footer">
          <button onClick={this.props.cancel}>Cancel</button>
          <button className="active" onClick={this.props.approve}>{this.props.data.approve}</button>
        </div>
      </div>
    );
  }

}

export default Approve;