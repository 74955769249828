import React, { Component } from 'react';

import Input from '../../../../platform/components/Input';
import GoalService from '../../../../platform/api/goal';
import NotificationSystem from '../../../../platform/services/notificationSystem';

import Closer from '../../../../platform/components/Closer';

class GoalAdd extends Component {

  state = {
    submited: false,
    form: {
      titleHy: '',
      titleEn: '',
      descriptionHy: '',
      descriptionEn: '',
    },
  };

  async componentDidMount() {
    const { id } = this.props;

    if (id) {
      const result = await GoalService.Details(id);
      if (result.data) {
        delete result.data._id;
        this.setState({ form: result.data });
      }
    }
  }

  handleChange = e => {
    const { form } = this.state;
    form[e.target ? e.target.name : e.name] = e.target ? e.target.value : e.value;
    this.setState({ form });
  }

  submit = () => {
    this.setState({ submited: true }, async () => {
      const { id, programId } = this.props;
      const { form } = this.state;
      
      if (id) form.id = id;
      else form.programId = programId;

      if (form.titleEn && form.titleHy && form.descriptionEn && form.descriptionHy) {
        const result = id ? await GoalService.Edit(form) : await GoalService.Add(form);
        if(result.success) this.props.onUpdate();
        else NotificationSystem.showNotification('Error', result.message);
      }
    })
  }

  render() {
    const { id } = this.props;
    const { form, submited } = this.state;

    return (
      <div className="App-program-modal-add">
        <div className="G-flex G-align-center G-mb-2">
          <h4>{id ? 'Edit Goal' : 'Add Goal'}</h4>
          <Closer close={this.props.close} />
        </div>
        <div>
          <Input
            title="Title in armenian"
            name="titleHy"
            value={form.titleHy}
            onChange={this.handleChange}
            errors={submited && !form.titleHy}
          />
          <Input
            title="Title in english"
            name="titleEn"
            value={form.titleEn}
            errors={submited && !form.titleEn}
            onChange={this.handleChange}
          />
          <div className="G-input-box">
            <p>Description in armenian</p>
            <textarea cols="5" rows="5"
              name="descriptionHy"
              className={submited && !form.descriptionHy ? 'G-error' : ''}
              value={form.descriptionHy}
              onChange={this.handleChange}
            />
          </div>
          <div className="G-input-box">
            <p>Description in english</p>
            <textarea cols="5" rows="5"
              name="descriptionEn"
              className={submited && !form.descriptionEn ? 'G-error' : ''}
              value={form.descriptionEn}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="App-program-modal-add-footer">
          <button className="active" onClick={this.submit}>Save</button>
        </div>
      </div>
    );
  }
}

export default GoalAdd;