import React    from 'react';
import ReactDOM from 'react-dom';

// import ClickOutside from './ClickOutside';

class Modal extends React.Component {
  render() {
    return ReactDOM.createPortal(
      <div className="App-modal">
        {/* <ClickOutside onClickOutside={e => { console.log(window.loaderState); !window.loaderState && this.props.onClickOutside(e) }}> */}
          <div className={`App-modal-content ${this.props.className || ''}`}>
            {this.props.children}
          </div>
        {/* </ClickOutside> */}
      </div>,
    document.getElementById('modal')
  );
}}

export default Modal;