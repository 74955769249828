export class CreateSectionViewModel {
  position = '1';
  titleEn = '';
  titleHy = '';
  bodyEn = '';
  bodyHy = '';
  link = '';
  image = null;

  errors = {
    titleEn  : false,
    titleHy  : false,
    bodyEn   : false,
    bodyHy   : false,
    link     : false,
    image    : false,
  };

  constructor(data) {
    if (data) {
      this.position = data.position;
      this.titleEn = data.titleEn;
      this.titleHy = data.titleHy;
      this.bodyEn = data.bodyEn;
      this.bodyHy = data.bodyHy;
      this.link = data.link;
      this.image = data.image;
    }
  }

  getBaseModel = () => {
    return {
      position : this.position,
      titleEn  : this.titleEn,
      titleHy  : this.titleHy,
      bodyEn   : this.bodyEn,
      bodyHy   : this.bodyHy,
      link     : this.link,
      image    : this.image,
    }
  }


  validate = () => {
    let isValid = true;

    const ERRORS = {
      titleEn  : false,
      titleHy  : false,
      bodyEn   : false,
      bodyHy   : false,
      link     : false,
      image    : false,
    };

    if (!this.titleEn) {
      ERRORS.titleEn = true;
      isValid = false;
    }

    if (!this.titleHy) {
      ERRORS.titleHy = true;
      isValid = false;
    }

    if (!this.bodyEn) {
      // TODO - create validator for bodyEn length 25
      ERRORS.bodyEn = true;
      isValid = false;
    }

    if (!this.bodyHy) {
      // TODO - create validator for bodyHy length 25
      ERRORS.bodyHy = true;
      isValid = false;
    }

    if (!this.link) {
      ERRORS.link = true;
      isValid = false;
    }

    if (!this.image) {
      ERRORS.image = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}