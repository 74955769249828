export class PaginationViewModel {
  pageNo = 1;
  limit =  10;

  constructor(data) {
    if (data) {
      this.pageNo = data.pageNo;
      this.limit = data.limit;
    }
  }

  next = () => ++this.pageNo;
  prev = () => --this.pageNo;
  changeLimit = limit => this.limit = limit;
  defaultValues = () => {
    this.pageNo = 1;
    this.limit = 20;
  }

  getBaseModel = () => {
    return {
      pageNo: this.pageNo,
      limit: this.limit,
    }
  }
}
