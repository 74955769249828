export const tableRows = [
  {
    key   : 'avatar',
    label : 'Photo',
    photo : true,
  },
  {
    key   : 'name',
    label : 'Full name',
  },
  {
    key   : 'specialty',
    label : 'Profession',
  },
  {
    key   : 'createdDt',
    date  : true,
    label : 'Registered date',
  },
  {
    key    : 'shownInWeb',
    label  : 'Is on web',
    switch : true,
  },
  {
    key              : 'blocked',
    label            : 'Is blocked',
    status           : true,
    statusTitleTrue  : 'Blocked',
    statusTitleFalse : 'Unblocked',
    colorTrue        : 'red',
    colorFalse       : 'green'
  },
];
