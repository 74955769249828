import NotificationSystem from '../../services/notificationSystem';

export class NewsMainViewModel {
  id;
  isActive = true;
  shownInHome = true;
  shownInSlider = false;
  isNews = false;
  dateIsShown = true;
  date;
  cover; mainImage; image; deletedImageList; deletedFileList; deleteCover;
  titleHy; titleEn; shortDescriptionHy; shortDescriptionEn; descriptionHy; descriptionEn;
  programId; programName;

  errors = {
    descriptionHy      : false,
    shortDescriptionHy : false,
    titleHy            : false,
  };

  constructor(data) {
    if (data) {
      this.cover = data.cover;
      this.deleteCover = data.deleteCover;
      this.deletedFileList = data.deletedFileList;
      this.deletedImageList = data.deletedImageList;
      this.descriptionEn = data.descriptionEn;
      this.descriptionHy = data.descriptionHy;
      this.id = data.id;
      this.image = data.image;
      this.isActive = data.isActive;
      this.mainImage = data.mainImage;
      this.shortDescriptionEn = data.shortDescriptionEn;
      this.shortDescriptionHy = data.shortDescriptionHy;
      this.shownInHome = data.shownInHome;
      this.shownInSlider = data.shownInSlider;
      this.isNews = data.isNews;
      this.dateIsShown = data.dateIsShown;
      this.date = data.date;
      this.titleEn = data.titleEn;
      this.titleHy = data.titleHy;
      this.programId = data.programId;
      this.programName = data.programName;
    }
  }

  getBaseModel = () => {
    return {
      cover              : this.cover,
      date               : this.date,
      dateIsShown        : this.dateIsShown,
      deleteCover        : this.deleteCover,
      deletedFileList    : this.deletedFileList,
      deletedImageList   : this.deletedImageList,
      descriptionEn      : this.descriptionEn,
      descriptionHy      : this.descriptionHy,
      id                 : this.id,
      image              : this.image,
      isActive           : this.isActive,
      isNews             : this.isNews || false,
      mainImage          : this.mainImage,
      shortDescriptionEn : this.shortDescriptionEn,
      shortDescriptionHy : this.shortDescriptionHy,
      shownInHome        : this.shownInHome,
      shownInSlider      : this.shownInSlider,
      titleEn            : this.titleEn,
      titleHy            : this.titleHy,
      programId          : this.programId,
    }
  }

  validate = () => {
    let isValid = true;

    const errorsMessages = [];

    const ERRORS = {
      descriptionHy      : false,
      shortDescriptionHy : false,
      titleHy            : false,
    };

    if (!this.descriptionHy) {
      errorsMessages.push('descriptionHy is not valid');
      ERRORS.descriptionHy = true;
      isValid = false;
    }

    if (!this.shortDescriptionHy) {
      errorsMessages.push('shortDescriptionHy is not valid');
      ERRORS.shortDescriptionHy = true;
      isValid = false;
    }

    if (!this.titleHy) {
      errorsMessages.push('titleHy is not valid');
      ERRORS.titleHy = true;
      isValid = false;
    }

    if (!isValid) {
      NotificationSystem.showNotification('Validation', errorsMessages[0]);
    }

    this.errors = ERRORS;
    return isValid;
  }
}
