import React      from 'react';
import classnames from 'classnames';

import ClickOutside from './ClickOutside';

export default class DropDown extends React.Component {

  state = {
    isOpen: false
  };

  closeDropDown = () => this.setState({ isOpen: false });

  toggleDropDown = () => {
    if (this.props.click) this.props.click();
    this.setState({ isOpen: !this.state.isOpen });
  }

  render() {
    const { Header, Body, className = '', headerClassName = '', left = 0, bodyClickDisabled = true } = this.props;
    return (
      <ClickOutside onClickOutside={this.closeDropDown}>
        <div className={classnames("App-drop-down-component", className)}>
          <div className={`App-drop-down-header ${headerClassName}`} onClick={this.toggleDropDown} >
            <Header />
          </div>
          {this.state.isOpen &&
            <div className="App-drop-down-body" style={{ left }} onClick={() => bodyClickDisabled ? {} : this.closeDropDown()}>
              <Body />
            </div>}
        </div>
      </ClickOutside>
    );
  }
}