export const to = {
  bookList          : '/books/list',
  books             : '/books',
  booksCategory     : '/books/category',
  booksManage       : '/books/manage',
  booksFavorite     : '/books/favorite',
  builder           : '/builder',
  builderCovers     : '/builder/covers',
  builderSections   : '/builder/sections',
  builderCalculator : '/builder/calculator',
  dashboard         : '/dashboard',
  profile           : '/profile',
  events            : '/events',
  eventsManage      : '/events/manage',
  forgot            : '/forgot',
  media             : '/media',
  news              : '/news',
  notifications     : '/notifications',
  newsManage        : '/news/manage',
  newspapers        : '/newspapers',
  partner           : '/partner',
  photos            : '/media/photos',
  programs          : '/programs',
  programsManage    : '/programs/manage',
  registration      : '/registration',
  signIn            : '/sign-in',
  usersWebList      : '/users/web',
  usersAdminList    : '/users/list',
  usersAdminDetails : '/users/details',
  usersManage       : '/users/manage',
  videos            : '/media/videos',
  taskManage        : '/programs/task-manage',
  reportManage      : '/programs/report-manage',  
};

export const navigation = [
  // {
  //   name : 'Dashboard',
  //   path : to.dashboard,
  // },
  // {
  //   name : 'User list',
  //   path : to.users,
  // },
  {
    name : 'Programs',
    path : to.programs,
  },
  {
    name  : 'Users',
    path  : '/users',
    child : [
      {
        name : 'Web section',
        path : to.usersWebList
      },
      {
        name : 'User list',
        path : to.usersAdminList
      },
    ]
  },
  {
    name : 'News',
    path : to.news,
  },
  {
    name : 'Events',
    path : to.events,
  },
  {
    name : 'Newspapers',
    path : to.newspapers,
  },
  {
    name  : 'Media',
    path  : to.media,
    child : [
      {
        name : 'Photos',
        path : to.photos
      },
      {
        name : 'Videos',
        path : to.videos
      },
    ]
  },
  {
    name  : 'Books',
    path  : to.books,
    child : [
      {
        name : 'Book list',
        path : to.bookList
      },
      {
        name : 'Books Category',
        path : to.booksCategory
      },
      {
        name : 'Favorite Books',
        path : to.booksFavorite
      }
    ]
  },
  {
    name  : 'Builder',
    path  : '/builder',
    child : [
      {
        name : 'Home section',
        path : to.builderSections
      },
      {
        name : 'Covers',
        path : to.builderCovers
      },
      {
        name: 'Calculator',
        path: to.builderCalculator
      }
    ]
  },
  {
    name               : 'Notifications',
    path               : to.notifications,
    notificationsBadge : true,
  },
];

export const navigationForUser = [
  {
    name : 'Programs',
    path : to.programs,
  },
  {
    name : 'Profile',
    path : to.profile,
  },
  {
    name               : 'Notifications',
    path               : to.notifications,
    notificationsBadge : true,    
  },
]