import React, { Fragment } from 'react';

import 'rc-datepicker/lib/style.css';
import './assets/styles/main.scss';

import { Socket }         from './platform/services/connection';
import ClientRoutes       from './routes/ClientRoutes';
import UnauthorizedRoutes from './routes/Unauthorized';

import { LoginProvider, Login } from './platform/contexts/Login';
import Loader from './platform/components/Loader';
import NotificationSystem from './platform/services/notificationSystem';

function App() {
  Socket.init();
  Socket.connection.on('notification', data => {
    NotificationSystem.showNotification('New Notification', data);    
  });

  return (
    <LoginProvider>
      <Login.Consumer>
        {context => (
          <Fragment>
            {!context.loginState ?
              <UnauthorizedRoutes context={context} /> :
              <ClientRoutes context={context} />
            }
            {context.loaderState && <Loader/>}
          </Fragment>
        )}
      </Login.Consumer>
    </LoginProvider>
  );
}

export default App;
