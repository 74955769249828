import React  from 'react';
import Switch from '@material-ui/core/Switch';

import { actionType } from '../services/constant';

 const Action = ({
  change,
  shownInHome,
  shownInSlider,
  isActive,
  dateIsShown,
  showDateSwitch,
  isNews,
  report,
  onChange,
 }) => (
  <div className="App-event-news-action">
    <div className="G-flex G-mb-2">
      <h6 className="title">Action</h6>
    </div>
    <div className="G-flex G-justify-between">
      {!report && <div className="G-flex G-align-center G-justify-between">
        <p className="G-mr-2">Show in home page</p>
        <Switch
          checked={shownInHome}
          onChange={() => onChange && onChange({
            type  : actionType.homePage,
            value : !shownInHome,
          })}
        />
      </div>}
      {report && <div className="G-flex G-align-center G-justify-between">
        <p className="G-mr-2">Is news</p>
        <Switch
          checked={isNews}
          onChange={() => onChange && onChange({
            type  : actionType.isNews,
            value : !isNews,
          })}
        />
      </div>}
      {!report && <div className="G-flex G-align-center G-justify-between">
        <p className="G-mr-2">Pin on slider</p>
        <Switch
          checked={shownInSlider}
          onChange={() => onChange && onChange({
            type  : actionType.slide,
            value : !shownInSlider,
          })}
        />
      </div>}
      <div className="G-flex G-align-center G-justify-between">
        <p className="G-mr-2">Is active</p>
        <Switch
          checked={isActive}
          onChange={() => onChange && onChange({
            type  : actionType.active,
            value : !isActive,
          })}
        />
      </div>
      {showDateSwitch && <div className="G-flex G-align-center G-justify-between">
        <p className="G-mr-2">Show date</p>
        <Switch
          checked={dateIsShown}
          onChange={() => onChange && onChange({
            type  : actionType.date,
            value : !dateIsShown,
          })}
        />
      </div>}
    </div>
  </div>
);

export default Action;