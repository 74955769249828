import React, { Component } from 'react';

import ForgotPass  from './components/ForgotPassword';
import { to }      from '../../../routes';
import { HISTORY } from '../../../routes/Unauthorized';
import AuthService from '../../../platform/api/auth';

import LOGO from '../../../assets/images/logo.png';

export default class ForgotPassword extends Component {

  state = {
    data: {}
  }

  componentDidMount() {
    const params = new URLSearchParams(HISTORY.location.search); 
    this.checkUser(params);
  }

  checkUser = async (params) => {
    const code = params.get('code');
    const email = params.get('email');
    const res = await AuthService.ForgotCheck({ code, email });
    if (res.success && res.data) {
      const data = {};
      data.code = code;
      data.email = email;
      this.setState({ data });
    } else HISTORY.push(to.signIn);
  }

  login = res => {
    localStorage.setItem('token', res.data.token);
    localStorage.setItem('role', res.data.role);
    this.props.checkLoginState();
  }

  render() {
    const { data = {} } = this.state;
    return (
      <div className="App-sign-in-page G-flex">
        <div className="left-box">
          <img src={LOGO} alt="LOGO"/>
        </div>
        <div className="right-box G-flex G-center">
          <ForgotPass email={data.email} code={data.code} success={res => this.login(res)} />
        </div>
      </div>
    )
  }
}
