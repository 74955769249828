import React, { Component }                from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { createBrowserHistory }            from 'history';

import { to }       from '.';
import Notification from '../platform/components/Notification';

//#region Pages

import SignIn         from '../views/authentication/signIn/SignIn';
import ForgotPassword from '../views/authentication/signIn/ForgotPassword';
import Registration   from '../views/authentication/registration/Registration';

//#endregion Pages end

export const HISTORY = createBrowserHistory();

export default class UnauthorizedRoutes extends Component {
  render() {
    const { context } = this.props;
    const token = localStorage.getItem('token');
    if (!token && context.loginState) context.checkLoginState();
    return (
      !token ? <Router history={HISTORY}>
        <Notification/>
        <Switch>
          <Route exact path={to.signIn}>
            <SignIn checkLoginState={context.checkLoginState} />
          </Route>
          <Route exact path={to.forgot}>
            <ForgotPassword checkLoginState={context.checkLoginState} />
          </Route>
          <Route exact path={to.registration}>
            <Registration checkLoginState={context.checkLoginState} />
          </Route>
          <Redirect to={to.signIn} />                               
        </Switch>
      </Router> : null
    );
  }
}
