import React, { Component } from 'react';

import AuthService           from '../../../../platform/api/auth';
import { LoginViewModel }    from '../../../../platform/api/auth/view-model';
import NotificationSystem    from '../../../../platform/services/notificationSystem';
import { loginErrorMessage } from '../../../../platform/lang/messages';
import Input                 from '../../../../platform/components/Input';
import InputPassword         from '../../../../platform/components/InputPassword';

export default class Login extends Component {
  
  state = {
    data : new LoginViewModel(),
  }

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  login = async () => {
    const data = this.state.data;
    if (data.validate()) {
      const res = await AuthService.Login(data.getBaseModel());
      if (res.success) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('role', res.data.role);
        this.props.checkLoginState();
      } else {
        NotificationSystem.showNotification('Message', loginErrorMessage.en);
        data.password = '';
        data.validate();
        this.setState({ data });
      }
    } else this.setState({ data });
  }

  render() {
    const { data } = this.state;
    const { showForgotPasswordPage } = this.props;
    return (
      <div className="form">
        <h1>Sign in</h1>
        <h1 className="G-mb-2">to your account</h1>
        <Input
          title="Email"
          name="email"
          value={data.email}
          errors={data.errors.email}
          onChange={this.handleChange}
          enterKeyPress={this.login}
        />
        <InputPassword
          title="Password"
          name="password"
          value={data.password}
          errors={data.errors.password}
          onChange={this.handleChange}
          enterKeyPress={this.login}
        />
        <div className="G-flex G-justify-end G-align-center G-mb-2">
          <button className="G-text" onClick={showForgotPasswordPage}>Forgot password?</button>
        </div>
        <button className="G-btn App-btn" onClick={this.login}>Sign in</button>
      </div>
    )
  }
}