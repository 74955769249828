import { connection, upload } from '../../services/connection';

const controllerName = 'Section';

export default class SectionService {

  //#region GET

  static List = () => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'GET',
    });
  }

  static Get = position => {
    return connection({
      url    : `${controllerName}?position=${position}`,
      method : 'GET',
    });
  }

  //#endregion

  //#region POST

  static Create = (data) => {
    return upload({
      url    : `${controllerName}`,
      method : 'POST',
      body   : data
    });
  }

  //#endregion

  //#region PUT

  //#endregion
}