import React, { Component } from 'react';
import BigCalendar          from 'react-big-calendar';
import moment               from 'moment';

import { to }            from '../../../routes';
import { HISTORY }       from '../../../routes/ClientRoutes';
import ProgramsService   from '../../../platform/api/programs';
import AppPageToolbar    from '../../../platform/components/PageToolbar';
import TableToolbar      from '../../../platform/components/table/TableToolbar';
import GlobalStepService from '../../../platform/api/globalStep';
import ActivityService   from '../../../platform/api/activity';
import GoalService       from '../../../platform/api/goal';
import TaskService        from '../../../platform/api/task';
import ReportService     from '../../../platform/api/report';
import Modal             from '../../../platform/components/Modal';
import GoalAdd           from './components/GoalAdd';
import GlobalStepAdd     from './components/GlobalStepAdd';
import ObjectiveAdd      from './components/ObjectiveAdd';
import AppTable          from '../../../platform/components/table/Table';
import AppProgramTable   from '../../../platform/components/table/Table';
import Approvement       from './components/Approvement';
import ContextMenu       from '../../../platform/components/contextMenu';
// import YearCalendar      from '../../../platform/components/year-calendar';

import {
  goalTableRows,
  globalStepTableRows,
  objectiveTableRows,
  activityTableRows,
  taskTableRows
} from './services/constant';

import ObjectiveService from '../../../platform/api/objective';
import ActivityAdd from './components/ActivityAdd';
import { ProgramStepsEnum } from '../../../platform/enums';
import { getShortDate } from '../../../platform/services/helpers';
import { ProgramListStatusEnum } from './services/enums';
import settings from '../../../platform/services/settings';

const ViewModeEnum = {
  Calendar: 1,
  List: 2,
};

moment.locale('ko', {
  week: {
    dow: 1,
    doy: 1,
  },
});

export default class Programs extends Component {

  localizer = BigCalendar.momentLocalizer(moment);

  state = {
    list                : [],
    listAddActive       : false,
    selectedItemsId     : [],
    itemCount           : 0,
    activeViewMode      : ViewModeEnum.List,
    tree                : null,
    goals               : [],
    globalSteps         : [],
    objectives          : [],
    objectiveAddActive  : false,
    objectiveActive     : false,
    activities          : [],
    activityAddActive   : false,
    activityActive      : false,
    chosenProgramId     : null,
    tasks               : [],
    taskActive          : false,
    reports             : [],
    reportActive        : false,
    globalStepActive    : true,
    globalStepAddActive : false,
    goalModifyId        : null,
    goalModify          : false,
    goalAddActive       : false,
    globalStepModify    : false,
    globalStepModifyId  : null,
    modifyPickerOpen    : false,
    programDelete       : false,
    programDeleteId     : null,
    globalStepDelete    : false,
    globalStepDeleteId  : null,
    objectiveDelete     : false,
    objectiveDeleteId   : null,
    activityDelete      : false,
    activityDeleteId    : null,
    taskDelete          : false,
    taskDeleteId        : null,
    goalDelete          : false,
    goalDeleteId        : null,
    finishActive        : false,
    finishItemId        : null,
    finishItemType      : null,
    approveActive        : false,
    approveItemId        : null,
    approveItemType      : null
  }

  componentDidMount() {
    settings.emtyAllPageNos();
    this.updateList();
    this.checkQuery();
  }

  updateList = async () => {
    const res = await ProgramsService.List();
    res.success && res.data && this.setState({ list: res.data.itemList, listAddActive: res.data.canAddNew }, () => this.checkQuery());
  }

  getSelectedItems = items => this.setState({ selectedItemsId: items });

  deleteSelectedItems = async () => {
    const { globalStepActive, selectedItemsId } = this.state;
    let res;
    if (!this.globalStepId) res = globalStepActive ? await GlobalStepService.Delete(selectedItemsId) : await GoalService.Delete(selectedItemsId);
    else if (this.objectiveId) res = await ActivityService.Delete(selectedItemsId);
    else res = await ObjectiveService.Delete(selectedItemsId);
    
    if (res.success) {
      if (this.globalStepId) this.fetchObjectives();
      else if (this.objectiveId) this.fetchActivities();
      else globalStepActive ? this.fetchGlobalSteps() : this.fetchGoals();
    }
  }

  changeChosen = (id, initial) => {
    if (id !== this.state.chosenProgramId) {
      this.setState({ chosenProgramId: id, goals: [], globalSteps: [], globalStepActive: true, activityActive: false, objectiveActive: false, taskActive: false, reportActive: false }, () => {
        this.fetchGlobalSteps();
        this.fetchGoals();
  
        if (initial) {
          const query = new URLSearchParams(window.location.search);
          query.set('program', id);  
          HISTORY.replace(`${to.programs}?${query.toString()}`);
        } else {
          HISTORY.replace(`${to.programs}?program=${id}`);
        }
      });
    }
  }

  changeViewMode = activeViewMode => {
    if (activeViewMode !== this.state.activeViewMode) {
      if (activeViewMode === ViewModeEnum.Calendar) {
        const query = new URLSearchParams(window.location.search);
        query.append('calendar', 'true');
        HISTORY.replace(`${to.programs}?${query.toString()}`);
      } else {
        const query = new URLSearchParams(window.location.search);
        query.delete('calendar');
        HISTORY.replace(`${to.programs}?${query.toString()}`);
      }

      this.setState({ activeViewMode });
    }
  }

  fetchGlobalSteps = () => this.setState({ globalStepModify: false, globalStepModifyId: null, globalSteps: [], tree: null }, async () => {
    const { chosenProgramId, tree } = this.state;
    const result = await GlobalStepService.List(chosenProgramId);
    this.setState({ globalSteps: result.data.itemList, globalStepAddActive: result.data.canAddNew, tree: result.data.tree || tree });
  });

  fetchGoals = () => this.setState({ goalModify: false, goalModifyId: null, goals: [], tree: null }, async () => {
    const { chosenProgramId, tree } = this.state;
    const result = await GoalService.List(chosenProgramId);
    this.setState({ goals: result.data.itemList, tree: result.data.tree || tree, goalAddActive: result.data.canAddNew });
  });

  fetchObjectives = () => this.setState({ objectiveModify: false, objectiveModifyId: null, tree: null }, async () => {
    const { tree } = this.state;
    const result = await ObjectiveService.List(this.globalStepId);
    this.setState({ objectives: result.data.itemList, objectiveAddActive: result.data.canAddNew, tree: result.data.tree || tree });
  });

  fetchActivities = () => this.setState({ activityModify: false, activityModifyId: null, tree: null }, async () => {
    const { tree } = this.state;
    const result = await ActivityService.List(this.objectiveId);
    this.setState({ activities: result.data.itemList, activityAddActive: result.data.canAddNew, tree: result.data.tree || tree });
  });

  fetchTasks = () => this.setState({ taskModify: false, taskModifyId: null, tree: null }, async () => {
    const { tree } = this.state;
    const result = await TaskService.List(this.activityId);
    this.setState({ tasks: result.data.itemList, taskAddActive: true, tree: result.data.tree || tree });
  });

  pickerStateChange = state => this.setState({ modifyPickerOpen: state });

  openDeleteProgram = (e, id) => {
    e.stopPropagation();
    this.setState({ programDelete: true, programDeleteId: id });
  }

  deleteProgram = async (id) => {
    const { chosenProgramId } = this.state;
    const result = await ProgramsService.Delete(id);

    const close = { programDelete: false, programDeleteId: null };
    if (result.success && id === chosenProgramId) {
      this.setState({ chosenProgramId: null, ...close }, this.updateList);
      HISTORY.push(to.programs);
    } else if (result.success) {
      this.setState(close, this.updateList);
    }
  }

  opendDeleteGlobalStep = async(e, id) => {
    e.stopPropagation();
    this.setState({ globalStepDelete: true, globalStepDeleteId: id });
  }

  closeDeleteGlobalStep = () => {
    this.setState({ globalStepDelete: false, globalStepDeleteId: null });
  }

  deleteGlobalStep = async (id) => {
    const result = await GlobalStepService.Delete(id);
    if (result && result.success) {
      this.setState({ globalStepDelete: false, globalStepDeleteId: null });
      this.fetchGlobalSteps();
      this.updateList();
    }
  }

  openDeleteObjective = async(e, id) => {
    e.stopPropagation();
    this.setState({ objectiveDelete: true, objectiveDeleteId: id });
  }

  closeDeleteObjective = () => {
    this.setState({ objectiveDelete: false, objectiveDeleteId: null });
  }

  deleteObjective = async (id) => {
    const result = await ObjectiveService.Delete(id);
    if (result && result.success) {
      this.setState({ objectiveDelete: false, objectiveDeleteId: null });
      this.updateList();
      this.fetchObjectives();
    }
  }

  openDeleteActivity = async(e, id) => {
    e.stopPropagation();
    this.setState({ activityDelete: true, activityDeleteId: id });
  }

  closeDeleteActivity = () => {
    this.setState({ activityDelete: false, activityDeleteId: null });
  }

  deleteActivity = async (id) => {
    const result = await ActivityService.Delete(id);
    if (result && result.success) {
      this.setState({ activityDelete: false, activityDeleteId: null });
      this.updateList();
      this.fetchActivities();
    }
  }

  openDeleteTask = async(e, id) => {
    e.stopPropagation();
    this.setState({ taskDelete: true, taskDeleteId: id });
  }

  closeDeleteTask = () => {
    this.setState({ taskDelete: false, taskDeleteId: null });
  }

  deleteTask = async (id) => {
    const result = await TaskService.Delete(id);
    if (result && result.success) {
      this.setState({ taskDelete: false, taskDeleteId: null });
      this.updateList();
      this.fetchTasks();
    }
  }

  editProgram = (e, id) => {
    e.stopPropagation();
    HISTORY.push(`${to.programsManage}?id=${id}`);
  }

  goAddPage = async () => {
    HISTORY.push(to.programsManage);
  }

  globalStepClick = item => {
    const { chosenProgramId, activeViewMode } = this.state;
    HISTORY.push(`${to.programs}?program=${chosenProgramId}&globalStep=${item._id}${activeViewMode === ViewModeEnum.Calendar ? '&calendar=true' : ''}`);
    this.checkQuery();
  }

  objectiveClick = item => {
    const { chosenProgramId, activeViewMode } = this.state;
    HISTORY.push(`${to.programs}?program=${chosenProgramId}&objective=${item._id}${activeViewMode === ViewModeEnum.Calendar ? '&calendar=true' : ''}`);
    this.checkQuery();
  }

  activityClick = item => {
    const { chosenProgramId, activeViewMode } = this.state;
    HISTORY.push(`${to.programs}?program=${chosenProgramId}&activity=${item._id}${activeViewMode === ViewModeEnum.Calendar ? '&calendar=true' : ''}`);
    this.checkQuery();
  }

  checkQuery = async () => {
    const { list, chosenProgramId } = this.state;
    const query = new URLSearchParams(window.location.search);
    this.programId = query.get('program');
    this.globalStepId = query.get('globalStep');
    this.objectiveId = query.get('objective');
    this.activityId = query.get('activity');
    this.isCalendar = query.get('calendar');

    if (this.programId && !chosenProgramId) this.changeChosen(this.programId, true);
    else if (!chosenProgramId && list && list[0]) this.changeChosen(list[0]._id, true);

    this.isCalendar && this.setState({ activeViewMode: ViewModeEnum.Calendar });

    if (this.globalStepId) {
      this.setState({ objectiveActive: true });
      this.fetchObjectives(); 
    } else if (this.objectiveId) {
      this.setState({ activityActive: true, objectiveActive: false });
      this.fetchActivities(); 
    } else if (this.activityId) {
      this.setState({ taskActive: true, activityActive: false, objectiveActive: false });
      this.fetchTasks(); 
    }
  }

  navigateToTask = id => {
    this.activityId && HISTORY.push(`${to.taskManage}?activityId=${this.activityId}${id ? `&id=${id}` : ''}`);
  }

  navigateToReport = async (id, isAdd) => {
    if (isAdd) {
      const reportDraft = await ReportService.Create(id);
      HISTORY.push(`${to.reportManage}?draftId=${reportDraft.data}`);
    } else HISTORY.push(`${to.reportManage}?id=${id}`);
  }

  breadcrumbsClick = (item, index) => {
    const { chosenProgramId, activeViewMode } = this.state;

    const queryParams = new URLSearchParams(window.location.search);


    switch (index) {
      case 0:
        HISTORY.replace(`${to.programs}?program=${item.id}${activeViewMode === ViewModeEnum.Calendar ? '&calendar=true' : ''}`);
        window.location.reload();
        break;
      case 1:
        const globalStep = queryParams.get('globalStep');
        if (!globalStep || globalStep !== item.id) {
          HISTORY.replace(`${to.programs}?program=${chosenProgramId}&globalStep=${item.id}${activeViewMode === ViewModeEnum.Calendar ? '&calendar=true' : ''}`);
          window.location.reload();
        }
        break;
      case 2:
        const objective = queryParams.get('objective');
        if (!objective || objective !== item.id) {
          HISTORY.replace(`${to.programs}?program=${chosenProgramId}&objective=${item.id}${activeViewMode === ViewModeEnum.Calendar ? '&calendar=true' : ''}`);
          window.location.reload();
        }
        break;
      default:
        const activity = queryParams.get('activity');
        if (!activity || activity !== item.id) {
          HISTORY.replace(`${to.programs}?program=${chosenProgramId}&activity=${item.id}${activeViewMode === ViewModeEnum.Calendar ? '&calendar=true' : ''}`);
          window.location.reload();
        }
        break;
    }
  }

  globalStepContext = row => row.actions.map(item => {
    const items = {
      1: {
        label: 'Details',
        onClick: () => this.setState({ globalStepModify: true, globalStepModifyId: row._id })
      },
      2: {
        label: 'Edit',
        onClick: () => this.setState({ globalStepModify: true, globalStepModifyId: row._id })
      },
      3: {
        label: 'Delete',
        onClick: (e) => this.opendDeleteGlobalStep(e, row._id)
      },
      4: {
        label: 'Finish',
        onClick: () => this.openFinish(row._id, ProgramStepsEnum.globalStep),
      },
      5: {
        label: 'Approve',
        onClick: () => this.openApprove(row._id, ProgramStepsEnum.globalStep)
      }
    };

    return items[item];
  });

  objectiveContext = row => row.actions.map(item => {
    const items = {
      1: {
        label: 'Details',
        onClick: () => this.setState({ objectiveModify: true, objectiveModifyId: row._id })
      },
      2: {
        label: 'Edit',
        onClick: () => this.setState({ objectiveModify: true, objectiveModifyId: row._id })
      },
      3: {
        label: 'Delete',
        onClick: (e) => this.openDeleteObjective(e, row._id)
      },
      4: {
        label: 'Finish',
        onClick: () => this.openFinish(row._id, ProgramStepsEnum.objective),
      }
    };
    return items[item];
  });

  activityContext = row => row.actions.map(item => {
    const items = {
      1: {
        label: 'Details',
        onClick: () => this.setState({ activityModify: true, activityModifyId: row._id })
      },
      2: {
        label: 'Edit',
        onClick: () => this.setState({ activityModify: true, activityModifyId: row._id })
      },
      3: {
        label: 'Delete',
        onClick: (e) => this.openDeleteActivity(e, row._id)
      },
      4: {
        label: 'Finish',
        onClick: () => this.openFinish(row._id, ProgramStepsEnum.activity),
      }
    };

    return items[item];
  });

  taskContext = row => row.actions.map(item => {
    const items = {
      1: {
        label: 'Edit task',
        onClick: () => this.navigateToTask(row._id),
      },
      2: {
        label: 'Delete task',
        onClick: (e) => this.openDeleteTask(e, row._id)
      },
      3: {
        label: 'Approve task',
        onClick: () => this.openApprove(row._id, ProgramStepsEnum.task)
      },
      4: {
        label: 'Task details',
        onClick: () => {}
      },
      5: {
        label: 'Write report',
        onClick: () => this.navigateToReport(row._id, true)
      },
      6: {
        label: 'Edit report',
        onClick: () => this.navigateToReport(row.report, false)
      },
      7: {
        label: 'Approve report',
        onClick:  () => this.openApprove(row._id, ProgramStepsEnum.report)
      },
      8: {
        label: 'Report details',
        onClick: () => this.navigateToReport(row.report, false)
      }
    };

    return items[item];
  });

  programContext = row => row.actions.map(item => {
    const items = {
      1: { label: 'Edit', onClick: (e) => this.editProgram(e, row._id) },
      2: { label: 'Finish', onClick: () => this.openFinish(row._id, ProgramStepsEnum.program)},
      3: { label: 'Delete', onClick: (e) => this.openDeleteProgram(e, row._id) },
      4: { label: 'Details', onClick: (e) => this.editProgram(e, row._id) },
    };

    return items[item];
  });

  goalContext = row => row.actions.map(item => {
    const items = {
      1: { label: 'Edit', onClick: () => { console.log(row); this.setState({ goalModify: true, goalModifyId: row._id }) } },
      2: { label: 'Delete', onClick: () => this.opendDeleteGoal(row._id) },
      3: { label: 'Details', onClick: () => { console.log(row); this.setState({ goalModify: true, goalModifyId: row._id }) } },
    };

    return items[item];
  });

  GlobalStep = () => {
    const { globalSteps, activeViewMode } = this.state;

    return activeViewMode === ViewModeEnum.List ? <AppTable
      rowTitle={item => item.title}
      rows={globalStepTableRows}
      itemCount={globalSteps.length}
      hideCheckbox
      list={globalSteps}
      fullCellClick={this.globalStepClick}
      editClick={row => this.setState({ globalStepModify: true, globalStepModifyId: row._id })}
      getSelectedItems={this.getSelectedItems}
      withoutPaging
      contextMenu={row => this.globalStepContext(row)}
    /> : <>
      {/* <YearCalendar
        startDate={new Date(new Date().getFullYear(), 1, 0)}
        endDate={new Date(new Date().getFullYear(), 12, 0)}
      /> */}
      <BigCalendar
        popup
        selectable
        views={['month']}
        localizer={this.localizer}
        events={globalSteps.map(item => ({
          title: item.title,
          start: item.startDate,
          end: item.endDate,
          allDay: true,
          resource: item,
        }))}
        onSelectEvent={(event) => this.globalStepClick(event.resource)}
        defaultDate={globalSteps[0] && new Date(globalSteps[0].startDate)}
        startAccessor="start"
        endAccessor="end"
        components={{
          eventWrapper: ({ event, children }) => {
            const date = Date.now();

            return <>
              <div id={'calendarItem' + event.resource._id + date}>
                {children}
              </div>
              <ContextMenu
                customId={`contextMenuCalendar${event.resource._id}`}
                contextId={'calendarItem' + event.resource._id + date}
                items={this.globalStepContext(event.resource)}
              />
            </>;
          }
        }}
      />
    </>;
  }

  Goal = () => {
    const { goals } = this.state;

    return <AppTable
      rowTitle={item => item.title}
      rows={goalTableRows}
      itemCount={goals.length}
      list={goals}
      editClick={row => this.setState({ goalModify: true, goalModifyId: row._id })}
      getSelectedItems={this.getSelectedItems}
      withoutPaging
      hideCheckbox
      contextMenu={row => this.goalContext(row)}
    />;
  }

  Objective = () => {
    const { objectives, activeViewMode } = this.state;

    return activeViewMode === ViewModeEnum.List ? <AppProgramTable
      rowTitle={item => item.name}
      rows={objectiveTableRows}
      hideCheckbox
      itemCount={objectives.length}
      list={objectives}
      fullCellClick={this.objectiveClick}
      editClick={row => this.setState({ objectiveModify: true, objectiveModifyId: row._id })}
      getSelectedItems={this.getSelectedItems}
      withoutPaging
      contextMenu={row => this.objectiveContext(row)}
    /> : <BigCalendar
      popup
      selectable
      views={['month']}
      localizer={this.localizer}
      events={objectives.map(item => ({
        title: item.name,
        start: item.startDate,
        end: item.endDate,
        allDay: true,
        resource: item,
      }))}
      onSelectEvent={event => this.objectiveClick(event.resource)}
      defaultDate={objectives[0] && new Date(objectives[0].startDate)}
      startAccessor="start"
      endAccessor="end"
      components={{
        eventWrapper: ({ event, children }) => {
          const date = Date.now();

          return <>
            <div id={'calendarItem' + event.resource._id + date}>
              {children}
            </div>
            <ContextMenu
              customId={`contextMenuCalendar${event.resource._id}`}
              contextId={'calendarItem' + event.resource._id + date}
              items={this.objectiveContext(event.resource)}
            />
          </>;
        }
      }}
    />;
  }

  Activity = () => {
    const { activities, activeViewMode } = this.state;

    return activeViewMode === ViewModeEnum.List ? <AppTable
      rowTitle={item => item.name}
      rows={activityTableRows}
      hideCheckbox
      itemCount={activities.length}
      list={activities}
      fullCellClick={this.activityClick}
      editClick={row => this.setState({ activityModify: true, activityModifyId: row._id })}
      getSelectedItems={this.getSelectedItems}
      withoutPaging
      contextMenu={row => this.activityContext(row)}
    /> : <BigCalendar
      popup
      selectable
      views={['month']}
      localizer={this.localizer}
      events={activities.map(item => ({
        title: item.name,
        start: item.startDate,
        end: item.endDate,
        allDay: true,
        resource: item,
      }))}
      onSelectEvent={event => this.activityClick(event.resource)}
      defaultDate={activities[0] && new Date(activities[0].startDate)}
      startAccessor="start"
      endAccessor="end"
      components={{
        eventWrapper: ({ event, children }) => {
          const date = Date.now();

          return <>
            <div id={'calendarItem' + event.resource._id + date}>
              {children}
            </div>
            <ContextMenu
              customId={`contextMenuCalendar${event.resource._id}`}
              contextId={'calendarItem' + event.resource._id + date}
              items={this.activityContext(event.resource)}
            />
          </>;
        }
      }}
    />;
  }

  Task = () => {
    const { tasks, activeViewMode } = this.state;

    return activeViewMode === ViewModeEnum.List ?  <AppTable
      rowTitle={item => item.name}
      rows={taskTableRows}
      hideCheckbox
      itemCount={tasks.length}
      list={tasks}
      editClick={row => this.setState({ taskModify: true, taskModifyId: row._id })}
      getSelectedItems={this.getSelectedItems}
      withoutPaging
      contextMenu={this.taskContext}
    /> : <BigCalendar
      popup
      selectable
      views={['month']}
      localizer={this.localizer}
      events={tasks.map(item => ({
        title: item.name,
        start: item.startDate,
        end: item.endDate,
        allDay: true,
        resource: item,
      }))}
      defaultDate={tasks[0] && new Date(tasks[0].startDate)}
      startAccessor="start"
      endAccessor="end"
      components={{
        eventWrapper: ({ event, children }) => {
          const date = Date.now();

          return <>
            <div id={'calendarItem' + event.resource._id + date}>
              {children}
            </div>
            <ContextMenu
              customId={`contextMenuCalendar${event.resource._id}`}
              contextId={'calendarItem' + event.resource._id + date}
              items={this.taskContext(event.resource)}
            />
          </>;
        }
      }}
    />;
  }

  ProgramDelete = () => {
    const { programDelete, programDeleteId } = this.state;
    if (programDelete) {
      return (
        <Modal onClickOutside={() => this.setState({ programDelete: false, programDeleteId: null })}>
          <Approvement
            data={{
              title: "Delete program",
              body: "Are you sure you want to delete program? Note, that all data connected to the program(exept news and events), will be deleted, too.",
              approve: "Delete"
            }}
            cancel={() => this.setState({ programDelete: false, programDeleteId: null })}
            approve={() => this.deleteProgram(programDeleteId)}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  GlobalStepDelete = () => {
    const { globalStepDelete, globalStepDeleteId } = this.state;
    if (globalStepDelete) {
      return (
        <Modal onClickOutside={this.closeDeleteGlobalStep}>
          <Approvement
            data={{
              title: "Delete global step",
              body: "Are you sure you want to delete global step? Note, that all data connected to the global step will be deleted, too.",
              approve: "Delete"
            }}
            cancel={this.closeDeleteGlobalStep}
            approve={() => this.deleteGlobalStep(globalStepDeleteId)}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  ObjectiveDelete = () => {
    const { objectiveDelete, objectiveDeleteId } = this.state;
    if (objectiveDelete) {
      return (
        <Modal onClickOutside={this.closeDeleteObjective}>
          <Approvement
            data={{
              title: "Delete objective",
              body: "Are you sure you want to delete objective? Note, that all data connected to the objective will be deleted, too.",
              approve: "Delete"
            }}
            cancel={this.closeDeleteObjective}
            approve={() => this.deleteObjective(objectiveDeleteId)}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  ActivityDelete = () => {
    const { activityDelete, activityDeleteId } = this.state;
    if (activityDelete) {
      return (
        <Modal onClickOutside={this.closeDeleteActivity}>
          <Approvement
            data={{
              title: "Delete activity",
              body: "Are you sure you want to delete activity? Note, that all data connected to the activity will be deleted, too.",
              approve: "Delete"
            }}
            cancel={this.closeDeleteActivity}
            approve={() => this.deleteActivity(activityDeleteId)}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  TaskDelete = () => {
    const { taskDelete, taskDeleteId } = this.state;
    if (taskDelete) {
      return (
        <Modal onClickOutside={this.closeDeleteTask}>
          <Approvement
            data={{
              title: "Delete task",
              body: "Are you sure you want to delete task? Note, that all data connected to the task will be deleted, too.",
              approve: "Delete"
            }}
            cancel={this.closeDeleteTask}
            approve={() => this.deleteTask(taskDeleteId)}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  GoalDelete = () => {
    const { goalDelete, goalDeleteId } = this.state;
    if (goalDelete) {
      return (
        <Modal onClickOutside={this.closeDeleteGoal}>
          <Approvement
            data={{
              title: "Delete goal",
              body: "Are you sure you want to delete goal? Note, that all data connected to the goal will be deleted, too.",
              approve: "Delete"
            }}
            cancel={this.closeDeleteGoal}
            approve={() => this.deleteGoal(goalDeleteId)}
          />
        </Modal>
      );
    } else {
      return null;
    }
  }

  openFinish = (id, type) => {
    this.setState({
      finishItemId: id,
      finishItemType: type,
      finishActive: true
    });
  }

  finishItem = async () => {
    const { finishItemId, finishItemType } = this.state;
    let result;
    switch (finishItemType) {
      case ProgramStepsEnum.program: {
        result = await ProgramsService.Finish(finishItemId);
        break;
      }
      case ProgramStepsEnum.globalStep: {
        result = await GlobalStepService.Finish(finishItemId);
        break;
      }
      case ProgramStepsEnum.objective: {
        result = await ObjectiveService.Finish(finishItemId);
        break;
      }
      case ProgramStepsEnum.activity: {
        result = await ActivityService.Finish(finishItemId);
        break;
      }
      default: {
        break;
      }
    }
    if (result && result.success) {
      this.setState({ finishActive: false, finishItemId: null, finishItemType: null }, () => {
        switch (finishItemType) {
          case ProgramStepsEnum.program: {
            break;
          }
          case ProgramStepsEnum.globalStep: {
            this.fetchGlobalSteps();
            break;
          }
          case ProgramStepsEnum.objective: {
            this.fetchObjectives();
            break;
          }
          case ProgramStepsEnum.activity: {
            this.fetchActivities();
            break;
          }
          default: {
            this.fetchTasks();
            break;
          }
        }
        this.updateList();
      });
    }
  }

  getFinishBody = (type) => {
    let body = 'Are you sure you want to finish ';
    let title = 'Finish ';
    switch (type) {
      case ProgramStepsEnum.program: {
        title+= 'program';
        body+= 'program?';
        break;
      }
      case ProgramStepsEnum.globalStep: {
        title+= 'global step';
        body+= 'global step?';
        break;
      }
      case ProgramStepsEnum.objective: {
        title+= 'objective';
        body+= 'objective?';
        break;
      }
      case ProgramStepsEnum.activity: {
        title+= 'activity';
        body+= 'activity?';
        break;
      }
      default: {
        title+= 'task';
        body+= 'task?';
        break;
      }
    }
    return { body, title };
  }

  openApprove = (id, type) => {
    this.setState({
      approveItemId: id,
      approveItemType: type,
      approveActive: true
    });
  }

  approveItem = async () => {
    const { approveItemId, approveItemType } = this.state;
    let result;
    switch (approveItemType) {
      case ProgramStepsEnum.globalStep: {
        result = await GlobalStepService.Approve(approveItemId);
        break;
      }
      case ProgramStepsEnum.task: {
        result = await TaskService.Approve(approveItemId);
        break;
      }
      case ProgramStepsEnum.report: {
        result = await ReportService.Approve(approveItemId);
        break;
      }
      default: {
        break;
      }
    }
    if (result && result.success) {
      this.setState({ approveActive: false, approveItemId: null, approveItemType: null }, () => {
        switch (approveItemType) {
          case ProgramStepsEnum.globalStep: {
            this.fetchGlobalSteps();
            break;
          }
          case ProgramStepsEnum.task: {
            this.fetchTasks();
            break;
          }
          default: {
            this.fetchTasks();
            break;
          }
        }
        this.updateList();
      });
    }
  }

  getApproveBody = (type) => {
    let body = 'Are you sure you want to approve ';
    let title = 'Approve ';
    switch (type) {
      case ProgramStepsEnum.globalStep: {
        title+= 'global step';
        body+= 'global step?';
        break;
      }
      case ProgramStepsEnum.objective: {
        title+= 'objective';
        body+= 'objective?';
        break;
      }
      case ProgramStepsEnum.task: {
        title+= 'task';
        body+= 'task?';
        break;
      }
      default: {
        title+= 'report';
        body+= 'report?';
        break;
      }
    }
    return { body, title };
  }

  opendDeleteGoal = async(id) => {
    this.setState({ goalDelete: true, goalDeleteId: id });
  }

  closeDeleteGoal = () => {
    this.setState({ goalDelete: false, goalDeleteId: null });
  }

  deleteGoal = async (id) => {
    const result = await GoalService.Delete(id);
    if (result && result.success) {
      this.setState({ goalDelete: false, goalDeleteId: null });
      this.fetchGoals();
    }
  }

  render() {
    const {
      list,
      activeViewMode,
      chosenProgramId,
      globalStepActive,
      globalStepAddActive,
      goalAddActive,
      objectiveAddActive,
      objectiveActive,
      activityActive,
      activityAddActive,
      goalModify,
      goalModifyId,
      taskActive,
      reportActive,
      selectedItemsId,
      globalStepModify,
      globalStepModifyId,
      objectiveModify,
      objectiveModifyId,
      activityModify,
      activityModifyId,
      modifyPickerOpen,
      tree,
      listAddActive,
      finishActive,
      finishItemType,
      approveActive,
      approveItemType
    } = this.state;

    console.log('goalAddActive', goalAddActive);

    return list ? <div className="App-page">
      <AppPageToolbar>
        <TableToolbar title={'Programs'} count={list.length} usingViewMode viewModeIcons={['icon-item_2', 'icon-calendar']} viewModeChange={this.changeViewMode} viewMode={activeViewMode}>
          <div className="G-flex">
            {listAddActive && <button className="G-btn App-add-btn" onClick={this.goAddPage}>Add program</button>}
          </div>
        </TableToolbar>
      </AppPageToolbar>
      <div className="App-programs">
        <div className="App-programs-list">
          <h3>Programs list</h3>
          {list.map(item => <div key={item._id} id={'programRow' + item._id} className={chosenProgramId === item._id ? 'App-chosen-program' : ''} onClick={() => this.changeChosen(item._id)}>
            <p>{item.name}</p>
            <p>
              <i className='fas fa-user-tie'/>
              {`Managers count: ${item.managerCount}`}
            </p>
            <p>
              <i className='fas fa-user'/>
              {`Users count: ${item.userCount }`}
            </p>
            <p>
              <i className="fas fa-calendar-alt" />
              {`Start date: ${getShortDate(item.startDate)}`}
            </p>
            <p>
              <i className="fas fa-calendar-alt" />
              {`End date: ${item.endDate ? getShortDate(item.endDate) : 'N/A'}`}
            </p>
            <p>            
              <i className="fas fa-clock"></i>
              {`Status: ${ProgramListStatusEnum[item.status]}`}
            </p>
            <ContextMenu
              customId={`contextMenuProgram${item._id}`}
              contextId={'programRow' + item._id}
              items={this.programContext(item)}
            />
          </div>)}
        </div>
        <div className="App-programs-info">
          {tree && tree.length > 1 && <div className="App-programs-breadcrumbs">
            {tree.map((item, index) => <div key={item.id}>
              <span onClick={() => this.breadcrumbsClick(item, index)}>
              {
                item.name.length <= 26
                ?
                item.name
                :
                item.name.slice(0, 26) + '...'
              }
              </span>
              {index !== tree.length - 1 ? '/' : ''}
            </div>)}
          </div>}

          {objectiveActive && <>
            <div className="App-program-tabs">
              <h4 className="App-active-tab">Objectives</h4>
              {objectiveAddActive && <button className="G-btn App-add-btn" onClick={() => this.setState({ objectiveModify: true, objectiveModifyId: null })}>Add objective</button>}
              {!!selectedItemsId.length && <i className="icon-waste-can" onClick={this.deleteSelectedItems} />}
            </div>

            <div className="App-programs-content">
              <this.Objective />
            </div>
          </>}

          {activityActive && <>
            <div className="App-program-tabs">
              <h4 className="App-active-tab">Activities</h4>
              {activityAddActive && <button className="G-btn App-add-btn" onClick={() => this.setState({ activityModify: true, activityModifyId: null })}>Add activity</button>}
              {!!selectedItemsId.length && <i className="icon-waste-can" onClick={this.deleteSelectedItems} />}
            </div>

            <div className="App-programs-content">
              <this.Activity />
            </div>
          </>}

          {(taskActive || reportActive) && <>
            <div className="App-program-tabs">
              <h4 className={taskActive ? 'App-active-tab' : ''} onClick={() => this.setState({ taskActive: true })}>Tasks</h4>
              {taskActive && <>
                <button className="G-btn App-add-btn" onClick={() => this.navigateToTask()}>Add task</button>
              </>}
              {!!selectedItemsId.length && <i className="icon-waste-can" onClick={this.deleteSelectedItems} />}
            </div>

            <div className="App-programs-content">
              <this.Task />
            </div>
          </>}

          {!objectiveActive && !activityActive && !taskActive && !reportActive && <>
            <div className="App-program-tabs">
              <h4 className={globalStepActive ? 'App-active-tab' : ''} onClick={() => this.setState({ globalStepActive: true })}>Global step</h4>
              <h4 className={!globalStepActive ? 'App-active-tab' : ''} onClick={() => this.setState({ globalStepActive: false })}>Goal</h4>
              {globalStepActive ?
                globalStepAddActive && <button className="G-btn App-add-btn" onClick={() => this.setState({ globalStepModify: true, globalStepModifyId: null })}>Add global step</button> :
                goalAddActive ? <button className="G-btn App-add-btn" onClick={() => this.setState({ goalModify: true, goalModifyId: null })}>Add goal</button> :
                null }
              {!!selectedItemsId.length && <i className="icon-waste-can" onClick={this.deleteSelectedItems} />}
            </div>

            <div className="App-programs-content">
              {globalStepActive ? <this.GlobalStep /> : <this.Goal />}
            </div>
          </>}
        </div>

      </div>
      
      {goalModify && <Modal onClickOutside={() => this.setState({ goalModify: false, goalModifyId: null })}>
        <GoalAdd id={goalModifyId} programId={chosenProgramId} close={() => this.setState({ goalModify: false, goalModifyId: null })} onUpdate={this.fetchGoals}  />
      </Modal>}
      {globalStepModify && <Modal onClickOutside={() => !modifyPickerOpen && this.setState({ globalStepModify: false, globalStepModifyId: null })}>
        <GlobalStepAdd
          id={globalStepModifyId}
          close={() => !modifyPickerOpen && this.setState({ globalStepModify: false, globalStepModifyId: null }) }
          programId={chosenProgramId}
          onUpdate={this.fetchGlobalSteps}
          pickerStateChange={this.pickerStateChange}
        />
      </Modal>}
      {objectiveModify && <Modal onClickOutside={() => !modifyPickerOpen && this.setState({ objectiveModify: false, objectiveModifyId: null })}>
        <ObjectiveAdd 
          id={objectiveModifyId}
          close={() => !modifyPickerOpen && this.setState({ objectiveModify: false, objectiveModifyId: null })}
          globalStepId={this.globalStepId}
          onUpdate={this.fetchObjectives}
          pickerStateChange={this.pickerStateChange}
        />
      </Modal>}
      {activityModify && <Modal onClickOutside={() => !modifyPickerOpen && this.setState({ activityModify: false, activityModifyId: null })}>
        <ActivityAdd
        id={activityModifyId}
        close={() => !modifyPickerOpen && this.setState({ activityModify: false, activityModifyId: null })}
        objectiveId={this.objectiveId}
        onUpdate={this.fetchActivities}
        pickerStateChange={this.pickerStateChange}
      />
      </Modal>}
      
      <this.ProgramDelete />
      <this.GlobalStepDelete/>
      <this.ObjectiveDelete/>
      <this.ActivityDelete/>
      <this.TaskDelete />
      <this.GoalDelete />

      {finishActive && <Modal onClickOutside={() => this.setState({ finishActive: false, finishItemId: null, finishItemType: null })}>
        <Approvement
          data={{
            ...this.getFinishBody(finishItemType),
            approve: "Finish"
          }}
          cancel={() => this.setState({ finishActive: false, finishItemId: null, finishItemType: null })}
          approve={() => this.finishItem()}
        />
      </Modal>}

      {approveActive && <Modal onClickOutside={() => this.setState({ approveActive: false, approveId: null, approveItemType: null })}>
        <Approvement
          data={{
            ...this.getApproveBody(approveItemType),
            approve: "Approve"
          }}
          cancel={() => this.setState({ approveActive: false, approveId: null, approveItemType: null })}
          approve={() => this.approveItem()}
        />
      </Modal>}

    </div> : null;
  } 
}
