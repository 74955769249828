import React, { useState, useEffect } from 'react';
import classnames          from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import IconButton      from '@material-ui/core/IconButton';
import Tooltip         from '@material-ui/core/Tooltip';
import Toolbar         from '@material-ui/core/Toolbar';
import Typography      from '@material-ui/core/Typography';

export const ViewModeEnum = {
  Items: 1,
  Rows: 2,
};

const toolbarStyles = theme => ({
  spacer: {
    flex: '1 1 100%',
  },
  title: {
    flex: '0 0 auto',
  },
});


let TableToolbar = props => {
  const {
    numSelected,
    classes,
    deleteBtn,
    blockBtn,
    unblockBtn,
    blockItem,
    unblockItem,
    deleteItem,
    viewMode,
    viewModeChange,
    viewModeIcons,
  } = props;

  const [getViewMode, setViewMode] = useState(ViewModeEnum.Items);

  useEffect(() => {
    setViewMode(viewMode);
  }, [viewMode]);

  return (
    <Toolbar
      className={classnames(classes.root, 'App-table-toolbar', {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classnames('App-table-toolbar-title', classes.title)}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography id="tableTitle">
            <span className="G-flex G-align-center">
              <b style={{ minWidth: 100 }}>
                {props.title}
                <span className="G-ml-1">{props.count || ''}</span>
              </b>
              {props.usingViewMode && <>
                <i
                  className={classnames(`App-table-toolbar-viewmode ${viewModeIcons ? viewModeIcons[0] : 'icon-item_2'}`, { 'App-active-viewmode': getViewMode === ViewModeEnum.Rows })}
                  onClick={() => { setViewMode(ViewModeEnum.Rows); viewModeChange(ViewModeEnum.Rows); }}
                />
                <i
                  className={classnames(`App-table-toolbar-viewmode ${viewModeIcons ? viewModeIcons[1] : 'icon-item_1'}`, { 'App-active-viewmode': getViewMode === ViewModeEnum.Items })}
                  onClick={() => { setViewMode(ViewModeEnum.Items); viewModeChange(ViewModeEnum.Items); }}
                />
              </>}
            </span>
          </Typography>
        )}
      </div>
      <div className={classes.spacer} />
      <div className={classes.actions}>

        {numSelected > 0 ? (
          <div className="G-flex App-toolbar-btn-block">
            {deleteBtn && <Tooltip title="Delete">
              <IconButton aria-label="Delete" onClick={deleteItem}>
                <i className="icon-waste-can"/>
              </IconButton>
            </Tooltip>}
            {blockBtn && <Tooltip title="Block">
              <IconButton aria-label="Block" onClick={blockItem}>
                <i class="fas fa-lock"></i>
              </IconButton>
            </Tooltip>}
            {unblockBtn && <Tooltip title="Unblock">
              <IconButton aria-label="Unblock" onClick={unblockItem}>
                <i class="fas fa-lock-open"></i>
              </IconButton>
            </Tooltip>}
          </div>
        ) : props.children}

      </div>
    </Toolbar>
  );
};

export default withStyles(toolbarStyles)(TableToolbar);
