import React, { Component } from 'react';

import { tableRows } from './services/constant';

import { to }                  from '../../../routes';
import { HISTORY }             from '../../../routes/ClientRoutes';
import EventService            from '../../../platform/api/event';
import { PaginationViewModel } from '../../../platform/view-models';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import AppTable                from '../../../platform/components/table/Table';
import TableToolbar            from '../../../platform/components/table/TableToolbar';
import settings from '../../../platform/services/settings';

export default class Events extends Component {

  state = {
    list            : [],
    selectedItemsId : [],
    itemCount       : 0,
  }

  //#region Table

  paginationData;

  getList = async () => {
    const res = await EventService.List(this.paginationData.getBaseModel());
    if (res.success && res.data) {
      this.setState({
        selectedItemsId : [],
        list            : res.data.itemList || [],
        itemCount       : res.data.itemCount || 0,
      });
    }
  }
  
  changePage = page => {
    this.paginationData.pageNo = page;
    this.getList();
  }

  changeLimit = limit => {
    this.paginationData = new PaginationViewModel();
    this.paginationData.limit = +(limit);
    this.getList();
  }

  //#region Selected Items

  getSelectedItems = items => this.setState({ selectedItemsId: items });

  delete = async () => {
    const { selectedItemsId, list } = this.state;
    const res = await EventService.Delete({ idList: this.state.selectedItemsId });
    if (res.success) {
      if (list.length - selectedItemsId.length === 0 && this.paginationData.pageNo !== 1) {
        this.paginationData.pageNo--;
      }
      this.getList();
    }
  }

  //#endregion Selected items

  //#endregion Table

  goAddPage = async () => {
    const res = await EventService.Create();
    if (res.success && res.data) HISTORY.push(`${to.eventsManage}?draftId=${res.data}`);
  }

  clickItem = item => item && item._id && HISTORY.push(`${to.eventsManage}?id=${item._id}`);

  componentDidMount() {
    settings.emptyAllExept('event');
    this.paginationData = new PaginationViewModel();
    this.getList();
  }

  render() {
    const { selectedItemsId, list, itemCount } = this.state;
    return <div className="App-page">
      <AppPageToolbar>
        <TableToolbar title={'Event list'} count={itemCount} numSelected={selectedItemsId.length} deleteBtn deleteItem={this.delete}>
          <div className="G-flex">
            <button className="G-btn App-add-btn" onClick={this.goAddPage}>+Add new</button>
          </div>
        </TableToolbar>
      </AppPageToolbar>
      <div className="App-page-content-box">
        <div className="App-page-content">
          <AppTable
            rows={tableRows}
            list={list}
            itemCount={itemCount}
            clickItem={this.clickItem}
            clearSelectedItem={!selectedItemsId.length}
            changePage={page => this.changePage(page)}
            changeLimit={limit => this.changeLimit(limit)}
            getSelectedItems={this.getSelectedItems}/>
        </div>
      </div>
    </div>
  }
}