import { connection, upload } from '../../services/connection';

const controllerName = 'UserItem';

export default class UserItemService {
  static List = () => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'GET',
    });
  }

  static Position = data => {
    return connection({
      url    : `${controllerName}/position`,
      method : 'PUT',
      body   : data,
    });
  }

  static Create = data => {
    return upload({
      url    : `${controllerName}`,
      method : 'POST',
      body   : data,
    });
  }

  static Update = data => {
    return upload({
      url    : `${controllerName}`,
      method : 'PUT',
      body   : data,
    });
  }

  static Delete = data => {
    return connection({
      url    : `${controllerName}`,
      method : 'DELETE',
      body   : data,
    });
  }

  static Get = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'GET',
    });
  }
}