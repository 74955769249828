import React, { Component } from 'react';

import Input from '../../../../platform/components/Input';
import DatePicker from '../../../../platform/components/DatePicker';
import GlobalStepService from '../../../../platform/api/globalStep';
import ProgramsService from '../../../../platform/api/programs';
import NotificationSystem from '../../../../platform/services/notificationSystem';
import Closer from '../../../../platform/components/Closer';

class GlobalStepAdd extends Component {

  state = {
    submited: false,
    minDate: undefined,
    maxDate: undefined,
    rangeFetched: false,
    dataFetched: false,
    form: {
      titleHy: '',
      titleEn: '',
      descriptionHy: '',
      descriptionEn: '',
      startDate: null,
      endDate: null,
    },
  };

  async componentDidMount() {
    const { id } = this.props;

    if (id) {
      const result = await GlobalStepService.Details(id);
      if (result.data) {
        delete result.data._id;
        this.setState({
          form: result.data.details,
          startMinDate: result.data.range.startDate.min || undefined,
          startMaxDate: result.data.range.startDate.max || undefined,
          endMinDate: result.data.range.endDate.min || undefined,
          endMaxDate: result.data.range.endDate.max || undefined,
          rangeFetched: true,
          dataFetched: true,
        });
      }
    } else {
      this.fetchDateRanges();
      this.setState({ dataFetched: true });
    }
  }

  handleChange = e => {
    const { form } = this.state;
    form[e.target ? e.target.name : e.name] = e.target ? e.target.value : e.value;
    this.setState({ form });
  }

  fetchDateRanges = async () => {
    const { programId } = this.props;
    const result = await ProgramsService.Range(programId);

    if (result.data) this.setState({
      rangeFetched: true,
      startMinDate: result.data.startDate.min || undefined,
      startMaxDate: result.data.startDate.max || undefined,
      endMinDate: result.data.endDate.min || undefined,
      endMaxDate: result.data.endDate.max || undefined,
    });
  }

  submit = () => {
    this.setState({ submited: true }, async () => {
      const { id, programId } = this.props;
      const { form } = this.state;
      
      if (id) form.id = id;
      else form.programId = programId;

      if (form.titleEn && form.titleHy && form.descriptionEn && form.descriptionHy && form.startDate && form.endDate && form.startDate < form.endDate) {
        const result = id ? await GlobalStepService.Edit(form) : await GlobalStepService.Add(form);
        if (result.success) this.props.onUpdate();
        else NotificationSystem.showNotification('Error', result.message);
      }
    })
  }

  changeDate = (key, date) => {
    const { form } = this.state;
    form[key] = date ? new Date(date) : null;
    this.setState({ form });
  }

  render() {
    const { id, pickerStateChange } = this.props;
    const { form, submited, rangeFetched, dataFetched, startMinDate, startMaxDate, endMinDate, endMaxDate, } = this.state;
    
    return rangeFetched && dataFetched ? (
      <div className="App-program-modal-add">
        <div className="G-flex G-align-center G-mb-2">
          <h4>{id ? 'Edit Global Step' : 'Add Global Step'}</h4>
          <Closer close={this.props.close} />
        </div>
        <div>
          <Input
            title="Title in armenian"
            name="titleHy"
            value={form.titleHy}
            onChange={this.handleChange}
            errors={submited && !form.titleHy}
          />
          <Input
            title="Title in english"
            name="titleEn"
            value={form.titleEn}
            errors={submited && !form.titleEn}
            onChange={this.handleChange}
          />
          <div className="G-input-box">
            <p>Description in armenian</p>
            <textarea cols="5" rows="5"
              name="descriptionHy"
              className={submited && !form.descriptionHy ? 'G-error' : ''}
              value={form.descriptionHy}
              onChange={this.handleChange}
            />
          </div>
          <div className="G-input-box">
            <p>Description in english</p>
            <textarea cols="5" rows="5"
              name="descriptionEn"
              className={submited && !form.descriptionEn ? 'G-error' : ''}
              value={form.descriptionEn}
              onChange={this.handleChange}
            />
          </div>
          <div className="App-manage-event-location-block G-flex G-justify-between G-half-input-box">
            <DatePicker
              title="Select start date"
              minDate={startMinDate}
              maxDate={startMaxDate}
              onOpen={pickerStateChange}
              defaultValue={form.startDate}
              error={submited && (!form.startDate || form.startDate >= form.endDate)}
              onChange={date => this.changeDate('startDate', date)}
            />
            <DatePicker
              title="Select end date"
              minDate={endMinDate}
              maxDate={endMaxDate}
              onOpen={pickerStateChange}
              defaultValue={form.endDate}
              error={submited && (!form.endDate || form.startDate >= form.endDate)}
              onChange={date => this.changeDate('endDate', date)}
            />
          </div>
        </div>
        <div className="App-program-modal-add-footer">
          <button className="active" onClick={this.submit}>Save</button>
        </div>
      </div>
    ) : null;
  }
}

export default GlobalStepAdd;