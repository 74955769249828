import { connection } from '../../services/connection';

const controllerName = 'goal';

export default class GoalService {

  static List = id => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'POST',
      body   : { programId: id },
    });
  }

  static Details = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'GET',
    });
  }

  static Add = form => {
    return connection({
      url    : controllerName,
      method : 'POST',
      body   : form,
    });
  }

  static Edit = form => {
    return connection({
      url    : controllerName,
      method : 'PUT',
      body   : form,
    });
  }

  static Delete = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'DELETE',
    });
  }
}
