import React, { Component }         from 'react';
import classnames                   from 'classnames';

import { HISTORY }             from '../../../routes/ClientRoutes';
import { to }                  from '../../../routes';
import { LanguageEnum }        from '../../../platform/enums';
import { languageCodeList }    from '../../../platform/services/constant';
import NotificationSystem      from '../../../platform/services/notificationSystem';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import ImageUpload             from '../../../platform/components/ImageUpload';
import LanguageSelector        from '../../../platform/components/LanguageSelector';
import Input                   from '../../../platform/components/Input';
import { UserItemViewModel }   from '../../../platform/api/userItem/view-model';
import UserItemService         from '../../../platform/api/userItem';

export default class UserManage extends Component {

  checkImage = true;

  state = {
    selectedLanguage : LanguageEnum.hy,
    data             : new UserItemViewModel(),
    deleteImage      : false,
  }

  getDetails = async () => {
    const { id } = this.state;
    const res = await UserItemService.Get(id);
    if (res.success && res.data) {
      this.checkImage = false;
      const data = new UserItemViewModel(res.data);
      this.setState({ data });
    } else HISTORY.goBack();
  }

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  getMainImages = images => {
    const data = this.state.data;
    data.image = images;
    this.checkImage = true;
    this.setState({ data, deleteImage: !images });
  }

  save = async () => {
    const data = this.state.data;
    const isValid = data.validate();
    if (isValid) {
      const formData = new FormData();
      if (this.state.id) formData.append('id', this.state.id);
      if (this.state.deleteImage) formData.append('deleteImage', this.state.deleteImage); 
      const baseModel = data.getBaseModel();
      for (const key in baseModel) {
        if (baseModel.hasOwnProperty(key)) {
          const item = baseModel[key];
          if (item && (key !== 'image' || (key === 'image' && this.checkImage))) {
            formData.append(key, item);
          }
        }
      }
      let res; 
      if (this.state.id) {
        res = await UserItemService.Update(formData);
      } else {
        res = await UserItemService.Create(formData);
      }

      if (res && res.success) {
        NotificationSystem.showNotification('Message', 'Success');
        HISTORY.push(to.usersWebList);
      } else {
        NotificationSystem.showNotification('Message', res.message);
        this.setState({ data });
      }
    } else {
      this.setState({ data });
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(HISTORY.location.search);
    const id = params.get('id');
    this.setState({ id }, () => id && this.getDetails());
  }

  render() {
    const {
      id,
      data,
      selectedLanguage,
    } = this.state;

    return (
      <div className="App-page App-add-page-news-event">
        <AppPageToolbar className="G-pl-2 G-pr-2 G-flex G-justify-between">
          <h6>Users/ <span className="G-page-active-title">{id ? 'Edit user' : 'Add user'}</span></h6>
          <div className="G-flex G-align-center">
            <LanguageSelector
              languageList={languageCodeList}
              selectedLanguage={selectedLanguage}
              selectLanguage={value => this.setState({ selectedLanguage: value })}
            />
            <button className="G-btn App-save-btn G-ml-4" onClick={this.save}>Save</button>
          </div>
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-page-content G-flex G-justify-between">

            <div className="G-w-25 App-manage-book-image G-flex G-justify-center">
              <ImageUpload error={data.imageError}
                imageUrl={data.image}
                output={this.getMainImages}
              />
            </div>

            <div className="G-w-75">

              <div className="block">

                <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
                  <div className="G-flex G-justify-between G-half-input-box">
                    <Input
                      title="First name"
                      name="firstNameEn"
                      errors={data.errors.firstNameEn}
                      value={data.firstNameEn}
                      onChange={this.handleChange}
                    />
                    <Input
                      title="Last name"
                      name="lastNameEn"
                      errors={data.errors.lastNameEn}
                      value={data.lastNameEn}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="G-flex G-justify-between">
                    <Input
                      title="Profession"
                      name="professionEn"
                      errors={data.errors.professionEn}
                      value={data.professionEn}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="G-input-box">
                    <p>Description</p>
                    <textarea cols="5" rows="15"
                      className={classnames({ 'G-error': data.errors.descriptionEn })}
                      value={data.descriptionEn || ''}
                      onChange={event => this.handleChange({ name: 'descriptionEn', value: event.target.value })}
                    />
                  </div>
                </div>

                <div className={classnames('G-hide',  { 'G-show': selectedLanguage === LanguageEnum.hy })}>
                  <div className="G-flex G-justify-between G-half-input-box">
                    <Input
                      title="First name"
                      name="firstNameHy"
                      errors={data.errors.firstNameHy}
                      value={data.firstNameHy}
                      onChange={this.handleChange}
                    />
                    <Input
                      title="Last name"
                      name="lastNameHy"
                      errors={data.errors.lastNameHy}
                      value={data.lastNameHy}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="G-flex G-justify-between">
                    <Input
                      title="Profession"
                      name="professionHy"
                      errors={data.errors.professionHy}
                      value={data.professionHy}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="G-input-box">
                    <p>Description</p>
                    <textarea cols="5" rows="15"
                      className={classnames({ 'G-error': data.errors.descriptionHy })}
                      value={data.descriptionHy || ''}
                      onChange={event => this.handleChange({ name: 'descriptionHy', value: event.target.value })}
                    />
                  </div>                 
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }
} 