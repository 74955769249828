import React, { Component } from 'react';
import classnames           from 'classnames';

export default class LanguageSelector extends Component {
  render() {
    const { languageList = [], selectLanguage, selectedLanguage } = this.props;
    return (
      <div className="App-language-selector G-flex G-justify-between">
        {languageList.map((item, index) => (
          <p key={index} className={classnames({ "G-mr-1": index !== languageList.length - 1, "active": selectedLanguage === item.value })} onClick={() => selectLanguage(item.value)}>
            {`${item.name} ${index !== languageList.length - 1 ? '/' : ''}`}
          </p>
        ))}
      </div>
    )
  }
}