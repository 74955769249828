import React, { Component } from 'react';

import FileUpload  from '../../../../platform/components/FileUpload';
import BookService from '../../../../platform/api/book';

class AddFile extends Component {

  state = {
    file        : null,
    showError   : false,
  }

  get defaultName() {
    const { defaultPath } = this.props;
    
    if (defaultPath) {
      const splitted = defaultPath.split('/');
      const last = splitted.length - 1;

      return splitted[last];
    }

    return '';
  }

  changeFile = file => {
    const { id, output } = this.props;
    this.setState({ file }, async () => {
      if (file) {
        const formData = new FormData();
        formData.append('id', id);
        formData.append('file', file);
        const result = await BookService.File(formData);
        output(result.data);
      } else output(null);
    });
  }

  render() {
    const { file, showError } = this.state;

    return (
      <div className="App-add-files">
        <div className="G-flex G-justify-between G-mb-2">
          <h6 className="title">Upload Book</h6>
        </div>
        <div className="body">
          <div>
            <div className="G-flex G-flex-wrap G-justify-between G-half-input-box">
              <div className="G-input-box">
                <FileUpload
                  uniqueKey={'file'}
                  fileName={file ? file.name : this.defaultName}
                  error={showError}
                  output={this.changeFile}
                  showDeleteIcon={file || this.defaultName}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddFile;
