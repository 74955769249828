export const publishLanguageEnum = {
  armenian : 1,
  russian  : 2,
  english  : 3,
  1        : 'Armenian',
  2        : 'Russian',
  3        : 'English',
};

//#region Tables

export const tableRows = [
  {
    key   : 'cover',
    label : 'Cover',
    photo : true,
    click : true,
  },
  {
    key   : 'titleEn',
    label : 'English name',
    click : true,
  },
  {
    key   : 'titleHy',
    label : 'Armenian name',
    click : true,
  },
  {
    key   : 'categories',
    label : 'Categories',
  },
  {
    key    : 'favorite',
    label  : 'Favorite',
    switch : true,
  },
  {
    key   : 'createdDt',
    date  : true,
    label : 'Creation date',
  },
  {
    key      : '',
    editIcon : true,
    label    : 'Action',
  },
];

//#endregion
