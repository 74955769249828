import React, { Component } from 'react';

import ImageUpload from '../../../../platform/components/ImageUpload';

export default class Images extends Component {

  state = {
    data: {
      mainImage : null,
      cover     : null,
    },
    edit: false
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.edit && ((nextProps.mainImage && nextProps.mainImage !== this.state.data.mainImage) ||
      (nextProps.cover && nextProps.cover !== this.state.data.cover))
    ) {
      const data = {
        mainImage : nextProps.mainImage,
        cover     : nextProps.cover,
      };
      this.setState({ data, edit: true });
    }
  }

  setFile = (key, file) => {
    const data = this.state.data;
    data[key] = file;
    this.setState({ data }, () => {
      this.props.output && this.props.output(data);
    });
  }

  render() {
    const { mainImage, cover } = this.state.data;
    const { mainImageError, coverImageError } = this.props;
    return (
      <div className="G-flex G-justify-between">
        <div className="G-w-30 G-mr-2">
          <ImageUpload
            imageUrl={mainImage}
            uniqueKey="mainImage"
            error={mainImageError}
            output={file => this.setFile('mainImage', file)}
          />
        </div>
        <div className="G-w-70">
          <ImageUpload
            imageUrl={cover}
            uniqueKey="cover"
            error={coverImageError}
            output={file => this.setFile('cover', file)}
          />
        </div>
      </div>
    )
  }
}