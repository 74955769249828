import React from 'react';
import {
  withGoogleMap,
  GoogleMap,
  Marker
} from 'react-google-maps';

const Maps = withGoogleMap(props => {
  const { lat = 40.1692799, lng = 44.515708399999994, zoom = 16 } = props;
  return (
    <GoogleMap defaultZoom={zoom} center={{ lat, lng }}>
      <Marker position={{ lat, lng }} />
    </GoogleMap>
  )
});

const AppGoogleMap = props => <Maps
  {...props}
  loadingElement={<div style={{ height: `100%` }} />}
  containerElement={<div style={{ height: `400px` }} />}
  mapElement={<div style={{ height: `100%` }} />}
/>;

export default AppGoogleMap;
