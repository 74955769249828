import { connection, upload } from '../../services/connection';

const controllerName = 'report';

export default class ReportService {

  //#region POST

  static Create = id => {
    return connection({
      url    : `${controllerName}/draft?id=${id}`,
      method : 'GET',
    });
  }

  static Main = (data) => {
    return upload({
      url    : `${controllerName}/main`,
      method : 'POST',
      body   : data
    });
  }

  static File = (data) => {
    return upload({
      url    : `${controllerName}/file`,
      method : 'POST',
      body   : data
    });
  }

  //#endregion

  static Delete = (data) => {
    return connection({
      url    : `${controllerName}`,
      method : 'DELETE',
      body   : data
    });
  }

  static Save = (data) => {
    return connection({
      url    : `${controllerName}/save`,
      method : 'PUT',
      body   : data
    });
  }

  static Get = id => {
    return connection({
      url    : `${controllerName}/details?id=${id}`,
      method : 'GET',
    });
  }

  static Approve = id => {
    return connection({
      url    : `${controllerName}/approve?id=${id}`,
      method : 'PUT',
    });
  }
}