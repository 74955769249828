import React, { Component } from 'react';

import SectionManage  from './components/SectionManage';
import SectionService from '../../../platform/api/section';
import AppPageToolbar from '../../../platform/components/PageToolbar';
import TableToolbar   from '../../../platform/components/table/TableToolbar';
import Modal          from '../../../platform/components/Modal';
import settings from '../../../platform/services/settings';

const Section = ({ title, body, featured_image, position, link, onEdit }) => {
  return (
    <div className="App-section-item G-p-2">
      <div className="header G-mb-2 G-flex G-align-center G-justify-between">
        <h5 className="G-bold">Position {position}</h5>
        <button title="Edit" onClick={() => onEdit(position)} ><i className="icon-edit"/></button>
      </div>
      <div className="content G-flex G-justify-between">
        <div className="G-bg-image" style={{ backgroundImage: `url(${featured_image})` }} />
        <div className="text">
          <h4 className="G-mb-2 G-bold">{title}</h4>
          <p>{body}</p>
        </div>
      </div>
    </div>
  )
}

export default class HomeSection extends Component {

  state = {
    list              : [],
    sectionModalState : false,
    selectedSection   : null
  }

  getSectionList = async () => {
    const res = await SectionService.List();
    if (res.success && res.data) {
      this.setState({ list: res.data });
    }
  }

  toggleModalState = () => {
    const state = !this.state.sectionModalState;
    if (!state) {
      this.getSectionList();
      this.setState({
        sectionModalState : state,
        selectedSection   : null
      });
    } else this.setState({ sectionModalState: state });
  }

  edit = async position => {
    const res = await SectionService.Get(position);
    if (res.success && res.data) {
      this.setState({
        selectedSection   : res.data,
        sectionModalState : true
      });
    } else {
      alert('Oops!');
    }
  }

  componentDidMount() {
    settings.emtyAllPageNos();
    this.getSectionList();
  }

  render() {
    const { list, sectionModalState, selectedSection } = this.state;
    return (
      <div className="App-page">
        <AppPageToolbar>
          <TableToolbar title={'Home section'}>
            {list.length < 2 && <div className="G-flex">
              <button className="G-btn App-add-btn" onClick={this.toggleModalState}>+Add new</button>
            </div>}
          </TableToolbar>
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-section-list">
            {list.map((item, index) => 
              <Section {...item} onEdit={this.edit} key={index} />
            )}
          </div>
        </div>
        {sectionModalState && <Modal onClickOutside={this.toggleModalState}>
          <SectionManage data={selectedSection} position={list.length} closeModal={this.toggleModalState} />
        </Modal>}
      </div>
    )
  }
}