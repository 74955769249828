import { connection } from '../../services/connection';

const controllerName = 'objective';

export default class ObjectiveService {

  static List = id => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'POST',
      body   : { globalId: id },
    });
  }

  static Details = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'GET',
    });
  }

  static Add = form => {
    return connection({
      url    : controllerName,
      method : 'POST',
      body   : form,
    });
  }

  static Edit = form => {
    return connection({
      url    : controllerName,
      method : 'PUT',
      body   : form,
    });
  }

  static Delete = id => {
    return connection({
      url    : `${controllerName}/delete?id=${id}`,
      method : 'DELETE'
    });
  }

  static Range = id => {
    return connection({
      url    : `${controllerName}/range?id=${id}`,
      method : 'GET',
    });
  }

  static Finish = objectiveId => {
    return connection({
      url    : `${controllerName}/finish`,
      method : 'PUT',
      body   : { id: objectiveId }
    });
  }
}
