import NotificationSystem             from './notificationSystem';
import io from 'socket.io-client';

import Settings                       from './settings';
import { customEvent, eventTypeEnum } from './event';
import { globalErrorMassage }         from '../lang/messages';

export class Socket {
  static connection = null;

  static init = () => {
    const socket = io(process.env.REACT_APP_API_HOST_URL, {
      query: {
        authorization: `Bearer ${Settings.token}`
      }
    });

    Socket.connection = socket;
  }
 };

const errorHandler = res => {
  let data = {}, response = {};
  switch (res.status) {
    case 401:
      NotificationSystem.showNotification(res.status, globalErrorMassage.en);
      data.name = eventTypeEnum.logout;
      customEvent(data)
      break;
    case 403:
      NotificationSystem.showNotification(res.status, globalErrorMassage.en);
      break;
    case 404:
      NotificationSystem.showNotification(res.status, globalErrorMassage.en);
      break;
    case 500:
      NotificationSystem.showNotification(res.status, globalErrorMassage.en);
      break;
    default:
      response = res.json();
      break; 
    };

  window.loaderState = false;
  customEvent({ name: eventTypeEnum.loaderHide });
  return response;
}

export const connection = async (options) => {
  if (!options.withoutLoading) {
    window.loaderState = true;
    customEvent({ name: eventTypeEnum.loaderShow });
  }

  const token = localStorage.getItem('token');
  const HEADERS = new Headers();
  const CONTENT_TYPE = !options.hasOwnProperty('contentType') ? 'application/json' : options.contentType;
  CONTENT_TYPE && HEADERS.append('Content-Type', CONTENT_TYPE);

  if (token) HEADERS.append('Authorization', `Bearer ${token}`);

  const BODY = !options.hasOwnProperty('contentType') ? JSON.stringify(options.body) : options.body;
  const RESULTS = await fetch(`${options.fullUrl ? options.fullUrl : `${process.env.REACT_APP_API_HOST_URL}api/${options.url}`}`, {
    method  : options.method,
    headers : HEADERS,
    body    : BODY
  });
  return errorHandler(RESULTS);
};

export const upload = async (options) => {
  window.loaderState = true;
  customEvent({ name: eventTypeEnum.loaderShow });
  const token = localStorage.getItem('token');
  const HEADERS = new Headers();
  if (token) HEADERS.append('Authorization', `Bearer ${token}`);
  const RESULTS = await fetch(`${options.fullUrl ? options.fullUrl : `${process.env.REACT_APP_API_HOST_URL}api/${options.url}`}`, {
    method : options.method || 'POST',
    headers: HEADERS,
    body   : options.body      
  });
  return errorHandler(RESULTS);
}

