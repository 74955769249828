import React, { useState } from 'react';
import classnames          from 'classnames';

const InputPassword = ({
  title,
  name,
  value,
  errors,
  onChange,
  enterKeyPress,
}) => {

  const [showPassState, setShowPassState] = useState(false);

	const handleKeyDown = e => {
    if (e.key === 'Enter') enterKeyPress(e);
	}
	
	const handleChange = (event) => {
		const value = event.target.value;
		onChange({ name, value });
  };

  return (
    <div className="G-input-box">
      <p>Password</p>
      <div className="G-input-password-box">
        <input
          type={showPassState ? "text" : "password"}
          className={classnames({ 'G-error': errors })}
          value={value}
          onKeyDown={handleKeyDown}
          onChange={event => handleChange(event)} />
        <button onClick={() => setShowPassState(!showPassState)}>{showPassState ? "Hide" : "Show"}</button>
      </div>
    </div>
  )
} 

export default InputPassword;