import React, { useState, useEffect }          from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils                            from '@date-io/date-fns';
import { InputAdornment }                      from '@material-ui/core';
import classnames                              from 'classnames';

const DatePickerYearMonth = ({
  title,
  required,
  format,
  defaultValue,
  disableFuture,
  error,
  onChange,
  onOpen,
}) => {
  
  const [selectedDate, setSelectedDate] = useState();
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange && onChange(date);
  }

  useEffect(() => {
    const date = defaultValue ? new Date(defaultValue) : null;
    setSelectedDate(date);
  }, [defaultValue]);

  return (
    <div className={classnames("App-date-picker G-input-box", { "G-error": error })}>
			<p>{title || ''} {required && <span>*</span>} </p>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DatePicker
          variant="inline"
          openTo="year"
          views={["year", "month"]}
          value={selectedDate}
          onOpen={() => onOpen && onOpen(true)}
          onClose={() => onOpen && onOpen(false)}
          onChange={handleDateChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i className="icon-calendar" />
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
};

export default DatePickerYearMonth;
