import React, { Component } from 'react';

import ImageUpload from '../../../../platform/components/ImageUpload';
import Closer      from '../../../../platform/components/Closer';
import FileService from '../../../../platform/api/file';

export default class CoverManage extends Component {

  state = {
    imageUrl     : null,
    sendingImage : null,
  }

  uploadFile = file => {
    if (!file) {
      this.setState({
        imageUrl     : null,
        sendingImage : null,
      });
    } else {
      this.setState({
        imageUrl     : URL.createObjectURL(file),
        sendingImage : file,
      });
    }
  }

  save = async () => {
    const { sendingImage } = this.state;
    const { updateList, close, data } = this.props;
    if (sendingImage) {
      const formData = new FormData();
      formData.append('id', data._id);
      formData.append('cover', sendingImage);
      const result = await FileService.UpdateCover(formData);
      if (result.success) updateList && updateList();
    } else close && close();
  }

  componentDidMount() {
    const { data } = this.props;
    this.setState({ imageUrl: data.path });
  }
  
  render() {
    const { imageUrl } = this.state;
    return (
      <div className="App-add-section App-add-section-auto-width">
        <div className="G-flex G-justify-between G-align-center G-mb-2">
          <h4>Update cover</h4>
          <Closer close={this.props.close}/>
        </div>
        <div className="G-flex G-mb-2">
          <div className="image-upload-box">
            <div className="image-upload">
              <ImageUpload
                errors={!imageUrl}
                imageUrl={imageUrl}
                output={this.uploadFile}
              />
            </div>
          </div>
        </div>
        <div className="G-flex G-align-center G-justify-end">
          <button className="G-btn App-save-btn" onClick={this.save}>SAVE</button>
        </div>
      </div>
    )
  }
}
