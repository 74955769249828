import React, { Component } from 'react';
import classnames           from 'classnames';

import DefaultImageModel         from './components/DefaultImageModel';
import Info                      from './components/Info';
import { to }                    from '../../../routes';
import { HISTORY }               from '../../../routes/Unauthorized';
import { languageCodeList }      from '../../../platform/services/constant';
import NotificationSystem        from '../../../platform/services/notificationSystem';
import { LanguageEnum }          from '../../../platform/enums';
import LanguageSelector          from '../../../platform/components/LanguageSelector';
import ImageUpload               from '../../../platform/components/ImageUpload';
import InputPassword             from '../../../platform/components/InputPassword';
import AuthService               from '../../../platform/api/auth';
import { RegistrationViewModel } from '../../../platform/api/auth/view-model';

import LOGO from '../../../assets/images/logo.png';

export default class Registration extends Component {

  state = {
    data              : new RegistrationViewModel(),
    defaultModelState : false,
    defaultImage      : null,
    selectedLanguage  : LanguageEnum.en,
  }

  enInfo = null;
  hyInfo = null;

  componentDidMount() {
    const params = new URLSearchParams(HISTORY.location.search);
    this.checkUser(params);
  }

  selectLanguage = value => {
    this.setState({ selectedLanguage: value })
  }

  toggleDefaultModelState = event => {
    event.preventDefault()
    event.stopPropagation();
    this.setState({ defaultModelState: !this.state.defaultModelState });
  }
  
  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  checkUser = async (params) => {
    const code = params.get('code');
    const email = params.get('email');
    const res = await AuthService.Check({ code, email });
    if (res.success && res.data) {
      const data = this.state.data;
      data.code = code;
      data.email = email;
      this.setState({ data });
    } else HISTORY.push(to.signIn);
  }

  //#region Info

  getFullInfoEn = info => {
    const isValid = info.validate();
    if (!isValid) this.setState({ selectedLanguage  : LanguageEnum.en });

    const data = this.state.data;
    data.firstNameEn = info.firstName;
    data.lastNameEn = info.lastName;
    data.specialtyEn = info.specialty;
    data.descriptionEn = info.description;
    data.validate();
    this.setState({ data });
  }

  getFullInfoHy = info => {
    const data = this.state.data;
    data.firstNameHy = info.firstName;
    data.lastNameHy = info.lastName;
    data.specialtyHy = info.specialty;
    data.descriptionHy = info.description;
    data.validate();
    this.setState({ data });
  }

 //#endregion

  //#region User Image

  getDefaultImage = item => {
    const data = this.state.data;
    data.avatar = item.type;
    this.setState({
      defaultModelState : !this.state.defaultModelState,
      defaultImage      : item.path,
      data,
    });
  }

  getImageUpload = file => {
    const data = this.state.data;
    if (!file) {
      data.avatar = null;
      data.avatarImage = null;
    } else {
      data.avatarImage = file;
    }
    this.setState({
      defaultImage : null,
      data,
    });
  }

  //#endregion

  signIn = async () => {
    this.enInfo.fullInfo();
    this.hyInfo.fullInfo();

    const data = this.state.data;
    if(data.validate()) {
      const formData = new FormData();
      const baseModel = data.getBaseModel();
      if (!this.state.defaultImage) delete baseModel.avatar;
      for (const key in baseModel) {
        if (baseModel.hasOwnProperty(key)) {
          const item = baseModel[key];
          formData.append(key, item)
        }
      }
      const res = await AuthService.Register(formData);
      if (res.success) {
        localStorage.setItem('token', res.data.token);
        localStorage.setItem('role', res.data.role);
        window.location.reload();
      } else {
        NotificationSystem.showNotification('Message', res.message);
      }
    }
  }

  render() {
    const { data, selectedLanguage, defaultModelState, defaultImage } = this.state;
    return (
      <div className="App-registration-page G-flex">
        <div className="left-box">
          <img src={LOGO} alt="LOGO" />
        </div>
        <div className="right-box">
          <div className="form">
              <div className="title G-flex G-justify-between G-mb-2">
                <h2>Registration</h2>
                <LanguageSelector
                  languageList={languageCodeList}
                  selectedLanguage={selectedLanguage}
                  selectLanguage={value => this.selectLanguage(value)}
                />
              </div>

              <div className="G-flex G-justify-between">
                <div className="G-w-25">
                  <ImageUpload imageUrl={defaultImage} output={this.getImageUpload}>
                    <p>Select image or <b onClick={event => this.toggleDefaultModelState(event)}>choose default</b></p>
                  </ImageUpload>
                </div>
                <div className="G-w-75">
                  <InputPassword
                    title="Password"
                    name="password"
                    value={data.password}
                    errors={data.errors.password}
                    onChange={this.handleChange}
                  />
                  <InputPassword
                    title="Repeat password"
                    name="repeatPassword"
                    value={data.repeatPassword}
                    errors={data.errors.repeatPassword}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="G-line G-mb-2" />

              <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
                <Info ref={instance => { this.enInfo = instance }} requiredFields getFullInfo={this.getFullInfoEn} />
              </div>

              <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.hy })}>
                <Info ref={instance => { this.hyInfo = instance }} getFullInfo={this.getFullInfoHy} />
              </div>

              <button className="G-btn App-btn" onClick={this.signIn}>Sign in</button>

            </div>
            {defaultModelState && <DefaultImageModel
              closeModal={this.toggleDefaultModelState}
              getDefaultImage={this.getDefaultImage} />
            }
          </div>
      </div>
    )
  }
}
