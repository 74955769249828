import React, { Component }  from 'react';
import classnames            from 'classnames';

import { to }                                 from '../../../routes';

import Action                    from './components/Action';
import Images                    from './components/Images';
import Info			                 from './components/Info';
import ManagersInfo              from './components/Managersinfo';
import { actionType }            from './services/constant';
import { HISTORY }               from '../../../routes/ClientRoutes';
import { LanguageEnum }          from '../../../platform/enums';
import { languageCodeList }      from '../../../platform/services/constant';
import AppPageToolbar            from '../../../platform/components/PageToolbar';
import LanguageSelector          from '../../../platform/components/LanguageSelector';
import DatePicker                from '../../../platform/components/DatePicker';
import ProgramsService           from '../../../platform/api/programs';
import { ProgramsMainViewModel } from '../../../platform/api/programs/view-model';
import Notification              from '../../../platform/services/notificationSystem';

export default class ProgramsManage extends Component {

  id; enInfo; hyInfo;
  mainImage; coverImage;
  checkImages = false;

  state = {
    selectedLanguage : LanguageEnum.hy,
    data             : new ProgramsMainViewModel(),
    imagesUrl        : [],
    mainImageError   : false,
    coverImageError  : false,
    programId        : null,
    startDate: {
      min: null,
      max: null
    },
    endDate: {
      min: null,
      max: null
    }
  }

  getDetails = async () => {
    const res = await ProgramsService.Get(this.state.programId);
    if (res.success && res.data) {
      const data = new ProgramsMainViewModel(res.data.details);
      this.mainImage = res.data.details.image;
      this.coverImage = res.data.details.cover;
      data.cover = null;
      data.image = null;
      this.setState({ data, details: res.data.details, ...res.data.range });
    } else HISTORY.goBack();
  }

  //#region Info

  getFullInfoEn = info => {
    const data = this.state.data;
    data.titleEn = info.title;
    data.descriptionEn = info.description;
    data.shortDescriptionEn = info.shortDescription;
    this.setState({ data });
  }

  getFullInfoHy = info => {
    const data = this.state.data;
    data.titleHy = info.title;
    data.descriptionHy = info.description;
    data.shortDescriptionHy = info.shortDescription;
    this.setState({ data });
  }

  //#endregion

  getMainImages = images => {
    this.checkImages = true;
		const data = this.state.data;
		data.cover = images.cover;
    data.image = images.mainImage;
    if (typeof images.cover === 'string') delete data.cover; 
    if (typeof images.mainImage === 'string') delete data.image; 
    this.setState({
      data,
      coverImageError : !!!images.cover,
      mainImageError  : !!!images.mainImage,
    });
  }

  getDate = (key, date) => {
    const data = this.state.data;
    data[key] = date ? new Date(date) : null;
    this.setState({ data });
  }

  getActionType = obj => {
    const data = this.state.data;
    const value = obj.value;
    switch (obj.type) {
      case actionType.web:
        data.shownInWeb = value;
        break;
      case actionType.homePage:
        data.shownInHome = value;
        break;
      default:
        break;
    }
    this.setState({ data });
  }

  componentDidMount() {
    const params = new URLSearchParams(HISTORY.location.search);
    const id = params.get('id');
    if (id) {
      this.setState({ programId: id }, () => this.getDetails());
    }
  }

  save = async () => {
    this.enInfo.fullInfo();
    this.hyInfo.fullInfo();
    this.membersInfo.fullInfo();

    const data = this.state.data;
    const mainImageError = !!!data.image && this.checkImages;
    const coverImageError = !!!data.cover && this.checkImages;

    const isValid = data.validate();

    if (isValid) {
      if (!mainImageError && !coverImageError) {
        const formData = new FormData();
        const baseModel = data.getBaseModel();
        if (this.state.programId) baseModel.id = this.state.programId;
        for (const key in baseModel) {
          if (baseModel.hasOwnProperty(key)) {
            const item = baseModel[key];
            if (item || item === 0) {
              if (Array.isArray(item)) item.map(sub => formData.append(`${key}[]`, sub));
              else formData.append(key, item);
            } else if (key === 'shownInWeb' || key === 'shownInHome') {
              formData.append(key, item);
            }
          }
        }
        let res;
        if (!this.state.programId) {
          res = await ProgramsService.Create(formData);
        } else {
          res = await ProgramsService.Update(formData);
        }
        if (res && res.success) {
          HISTORY.push(to.programs);
        } else {
          this.setState({ data });
          Notification.showNotification('Error', res.message);
        }
      } else {
        this.setState({
          data,
          mainImageError,
          coverImageError,
        }, () => {
          if (mainImageError || coverImageError) {
            Notification.showNotification('Error', 'Images are required');
          }
        });
      }
    } else this.setState({ data });
  }

  membersInfoChange = info => {
    const { data } = this.state;

    data.managerIdList = info.selectedList.map(item => item._id);
    if (info.responsible || info.responsible === 0) data.responsible = info.responsible;
    else delete data.responsible;

    this.setState({ data });
  }

  render() {
    const { data, details, selectedLanguage, mainImageError, coverImageError, programId } = this.state;
    return (
      <div className="App-page App-add-page-news-event">
        <AppPageToolbar className="G-pl-2 G-pr-2 G-flex G-justify-between">
          <h6>Programs / <span className="G-page-active-title">{`${programId ? 'Edit' : 'Add'} program`}</span></h6>
          <div className="G-flex G-align-center">
            <LanguageSelector
              languageList={languageCodeList}
              selectedLanguage={selectedLanguage}
              selectLanguage={value => this.setState({ selectedLanguage: value })}
            />
            <button className="G-btn App-save-btn G-ml-4" onClick={this.save}>Save</button>
          </div>
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-page-content">

            <div className="block App-manage-event-news-image">
              <Images
                mainImage={this.mainImage}
                cover={this.coverImage}
                output={this.getMainImages}
                mainImageError={mainImageError}
                coverImageError={coverImageError}
              />
            </div>

            <div className="line"/>

            <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
              <Info
                info={{
                  title            : data.titleEn,
                  description      : data.descriptionEn,
                  shortDescription : data.shortDescriptionEn,
                }}
                dat
                ref={instance => { this.enInfo = instance }}
                getFullInfo={this.getFullInfoEn}
              />
            </div>

            <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.hy })}>
              <Info
                requiredFields
                info={{
                  title            : data.titleHy,
                  description      : data.descriptionHy,
                  shortDescription : data.shortDescriptionHy,
                }}
                errors={{
                  title            : data.errors.titleHy,
                  description      : data.errors.descriptionHy,
                  shortDescription : data.errors.shortDescriptionHy,
                }}
                ref={instance => { this.hyInfo = instance }}
                getFullInfo={this.getFullInfoHy} 
              />
            </div>

            <div className="App-manage-event-location-block G-flex G-justify-between G-half-input-box">
              <DatePicker
                title="Select start date"
                defaultValue={data.startDate}
                error={data.errors.startDate}
                onChange={date => this.getDate('startDate', date)}
              />
              <DatePicker
                title="Select end date"
                defaultValue={data.endDate}
                onChange={date => this.getDate('endDate', date)}
              />
            </div>

            {((this.state.programId && details) || !this.state.programId) && <ManagersInfo
              programId={programId}
              info={details}
              name='Manager'
              error={data.errors.managerIdList}
              ref={instance => { this.membersInfo = instance }}
              changeData={this.membersInfoChange}
            />}

            <div className="line"/>

            <div className="block">
              <Action
                shownInHome={data.shownInHome}
                shownInWeb={data.shownInWeb}
                onChange={this.getActionType}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
