import React, { Component }    from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import classnames              from 'classnames';
import { Button }              from '@material-ui/core';

import { navigation, navigationForUser } from '../routes';
import { HISTORY }                       from '../routes/ClientRoutes';
import DropDown                          from '../platform/components/DropDown';
import Settings                          from '../platform/services/settings';
import { UserTypeEnum }                  from '../platform/enums';
// import { to }                            from '../routes';

import LOGO from '../assets/images/logo.png';
import NotificationService from '../platform/api/notification';
// import { Socket } from '../platform/services/connection';

const CustomDropDown = props => (
  <DropDown
    left={20}
    bodyClickDisabled={false}
    Header={() => (
      <NavLink to="#" className={classnames('G-ml-2', { 'active': props.pathName.includes(props.data.path) })}>
        {props.data.name}
      </NavLink>      
    )}
    Body={() => (
      <div className="App-drop-down-list">
        {props.data.child.map((item, index) => (
          <NavLink to={item.path} key={index}>
            <p>{item.name}</p>
          </NavLink>
        ))}
      </div>
    )}
  />
);

// const NotificationItem = props => (
//   <div className="G-flex">
//     <div className="">
//       <h6></h6>
//       <p></p>
//     </div>
//     <div></div>
//   </div>
// );

// const NotificationDropDown = props => (
//   <DropDown
//     left={-460}
//     bodyClickDisabled={false}
//     Header={() => (
//       <Button color="inherit">
//         <i className="icon-notification"/>
//       </Button>
//     )}
//     Body={() => (
//       <div className="App-notification-list">
//         <div className="App-notification-header G-flex G-center">
//           <h6>Notifications</h6>
//         </div>
//         <div className="App-notification-list">

//         </div>
//         <div className="App-notification-footer G-flex G-center">
//           <button>See all</button>
//         </div>
//       </div>
//     )}
//   />
// );

class Header extends Component {

  state = {
    unseenNotification: false,
  };

  async componentDidMount() {
    // Socket.connection.on('notification', data => {
    //   this.setState({ unseenNotification: true });
    // });

    const { data } = await NotificationService.Badge();
    this.setState({ showNotificationsBadge: data });
    window.changeNotificationBadge = this.changeNotificationBadge;
  }

  changeNotificationBadge = state => this.setState({ showNotificationsBadge: state });

  signOut = () => this.props.signOut();

  render() {
    const { showNotificationsBadge } = this.state;
    const navigationList = UserTypeEnum.admin === Settings.role ? navigation : navigationForUser;

    return (
      <header>
        <div className="G-flex G-align-center G-justify-between App-header">
          <div className="logo">
            <img src={LOGO} alt="Logo" />
          </div>
          <div className="G-flex left-block">
            {navigationList.map((item, index) =>
              !item.child || !item.child.length ?
              <div key={index}>
                <NavLink to={item.path} className="G-ml-2">{item.name}</NavLink>
                {item.notificationsBadge && showNotificationsBadge && <div className="App-notifications-badge" />}
              </div>
              : <CustomDropDown data={item} key={index} pathName={HISTORY.location.pathname} />
            )}
          </div>
          <div className="G-flex G-align-center right-block">
            {/* <NotificationDropDown/> */}
            {/* <Link to={to.notifications} className="App-header-notification">
              {unseenNotification && <span />}
              <i className="icon-notification" />
            </Link> */}
            <Button className="G-ml-2" color="inherit" onClick={this.signOut}>Sign Out</Button>
          </div>
        </div>
      </header>
    );
  }
}

export default withRouter(Header);