import React, { Component } from 'react';

import AuthService         from '../../../../platform/api/auth';
import { ForgotViewModel } from '../../../../platform/api/auth/view-model';
import InputPassword       from '../../../../platform/components/InputPassword';

export default class ForgotPass extends Component {
  
  state = {
    data     : new ForgotViewModel(),
    nextStep : false,
  }

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  done = async () => {
    const data = this.state.data;
    data.email = this.props.email;
    data.code = this.props.code;
    if (data.validate()) {
      const res = await AuthService.Forgot(data.getBaseModel());
      if (res.success && res.data) {
        this.props.success(res);
      } else this.setState({ data });
    } else this.setState({ data });
  }

  render() {
    const { data } = this.state;
    return (
      <div className="form">
        <h4 className="G-mb-2">FORGOT PASSWORD</h4>
        <InputPassword
          title="Password"
          name="password"
          value={data.password}
          errors={data.errors.password}
          onChange={this.handleChange}
          enterKeyPress={this.done}
        />
        <InputPassword
          title="Repeat password"
          name="repeatPassword"
          value={data.repeatPassword}
          errors={data.errors.repeatPassword}
          onChange={this.handleChange}
          enterKeyPress={this.done}
        />
        <button className="G-btn App-btn" onClick={this.done}>Done</button>
      </div>
    )
  }
}