import React, { useState, useEffect }                         from 'react';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils                                from '@date-io/date-fns';
import { InputAdornment }                          from '@material-ui/core';
import classnames                                  from 'classnames';


const DatePicker = ({
  title,
  required,
  format,
  defaultValue,
  disableFuture,
  error,
  onChange,
  onOpen,
  minDate,
  maxDate,
}) => {
  
  const [selectedDate, setSelectedDate] = useState();
  
  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange && onChange(date);
  }

  useEffect(() => {
    const date = defaultValue ? new Date(defaultValue) : null;
    setSelectedDate(date);
  }, [defaultValue]);

  return (
    <div className={classnames("App-date-picker G-input-box", { "G-error": error })}>
			<p>{title || ''} {required && <span>*</span>} </p>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <DateTimePicker
          clearable
          ampm={false}
          hideTabs
          disableFuture={disableFuture}
          format={format || "yyyy/MM/dd hh:mm"}
          value={selectedDate}
          onChange={handleDateChange}
          minDate={minDate || undefined}
          maxDate={maxDate || undefined}
          onOpen={() => onOpen && onOpen(true)}
          onClose={() => onOpen && onOpen(false)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <i className="icon-calendar" />
              </InputAdornment>
            ),
          }}
        />
      </MuiPickersUtilsProvider>
    </div>
  )
};

export default DatePicker;
