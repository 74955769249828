import React, { Component } from 'react';
import classnames           from 'classnames';

import Modal  from '../../../../platform/components/Modal';
import Closer from '../../../../platform/components/Closer';

export default class DefaultImageModel extends Component {

  state = {
    selectedDefaultImage : null,
    defaultCovers        : null
  }
  
  selectDefaultImage = item => {
    this.setState({ selectedDefaultImage: item });
  }

  componentDidMount() {
    const { defaultCovers } = this.props;
    if (defaultCovers) this.setState({ defaultCovers })
  }

  componentWillReceiveProps(nextProps) {
    const { defaultCovers } = this.props;
    if (nextProps.defaultCovers && defaultCovers && (defaultCovers.length !== nextProps.defaultCovers.length))
      this.setState({ defaultCovers: nextProps.defaultCovers })
  }

  render() {
    const { selectedDefaultImage, defaultCovers } = this.state;
    const { closeModal, getDefaultImage } = this.props;
    return (
      <Modal onClickOutside={closeModal}>
        <div className="App-choose-default-image">
          <h6 className="G-mb-2">Photo</h6>
          <div className="G-flex G-justify-between images G-mb-2">
            {defaultCovers ? defaultCovers.map((item, index) => (
              <div
                key={index}
                onClick={() => this.selectDefaultImage(index)}
                className={classnames('G-bg-image', { 'G-mr-4': index !== defaultCovers.length - 1 }, { 'active': selectedDefaultImage === index })}
                style={{ backgroundImage: `url(${item})` }} />
            )): <div className="G-bg-image" />}
          </div>
          <button className="G-btn App-btn" onClick={() => getDefaultImage(selectedDefaultImage)}>Save</button>
          <Closer close={closeModal}/>
        </div>
      </Modal>
    )
  }
}