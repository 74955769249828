export const tableRows = [
  {
    key   : 'cover',
    label : 'Photo',
    photo : true,
  },
  {
    key   : 'title',
    label : 'Title',
  },
  {
    key   : 'number',
    label : 'Number',
  },
  {
    key   : 'createdDt',
    date  : true,
    label : 'Creation date',
  },
  {
    key              : 'isActive',
    label            : 'Status',
    status           : true,
    statusTitleTrue  : 'Published',
    statusTitleFalse : 'Unpublished',
    colorTrue        : 'green',
    colorFalse       : 'red',
  },
  {
    key      : '',
    editIcon : true,
    label    : 'Actions',
  }
];
