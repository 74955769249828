import React, { Component } from 'react';

import FileUpload from '../../../platform/components/FileUpload';

import AppPageToolbar from '../../../platform/components/PageToolbar';
import TableToolbar   from '../../../platform/components/table/TableToolbar';
import FileService    from '../../../platform/api/file';

import Notification from '../../../platform/services/notificationSystem';
import settings from '../../../platform/services/settings';

export default class Covers extends Component {

  state = {

    file: '',

    oldPath: '',
    data: [],
    uploading: false

  }

  componentDidMount() {
    settings.emtyAllPageNos();
    this.getData()
  }

  getData = async () => {
    const result = await FileService.getCalc();
    if (result && result.success) {
      this.setState({
        oldPath: result.data.path,
        data: result.data.data
      });
    }
  }

  handleUpload = (file) => {
    this.setState({ file });
  }

  submit = async () => {
    const { file, uploading } = this.state;
    if (file && !uploading) {
      this.setState({ uploading: true }, async () => {
        const formData = new FormData();
        formData.append('file', file);
        const result = await FileService.setCalc(formData);
        if (result && result.success) {
          // Do smth
          this.setState({ uploading: false, file: '' });
          this.getData();
        } else {
          Notification.showNotification('Error', result.message);
          this.setState({ uploading: false });
        }
      });
    }
  }

  downloadOld = () => {
    const a = document.createElement('a');
    a.href = this.state.oldPath;
    document.body.appendChild(a);
    a.click();
  }

  getRow = (item, index, first) => {
    return (
      <div className="App-calculator-body-row" key={index} >
        {
          item.map((rItem, rIndex) => (
            <div
              className={`App-calculator-body-item ${!index || !rIndex ? 'main' : ''} ${!rIndex ? 'short': ''}`}
              title={index && rIndex ? `${item[0]} / ${first[rIndex]}` : ''}
              key={rIndex}
            >
              {rItem}
            </div>
          ))
        }
      </div>
    );
  }

  render() {
    
    const { file, oldPath, uploading, data } = this.state;

    return (
      <div className="App-page">
        <AppPageToolbar>
          <TableToolbar title={'Calculator'} />
        </AppPageToolbar>
        <div className="App-page-calculator-box">
          <div className="App-page-calculator-header">
            <div className="App-page-calculator-header-box">
              <div>
                <p>Upload new excel</p>
                <FileUpload
                  accept='.xlsx'
                  output={file => this.handleUpload(file)}
                />
              </div>
              <button className={`G-btn ${!file || uploading ? 'disabled': 'save'}`} onClick={this.submit}>Save</button>
            </div>
            <div>
              {
                oldPath
                ?
                <button className={`G-btn download`} onClick={this.downloadOld}>Download old excel</button>
                :
                null
              }
            </div>
          </div>
          <div className="App-page-calculator-body">
            {
              data.map((item, index) =>
                this.getRow(item, index, data[0])
              )
            }
          </div>
        </div> 
      </div>
    )
  }
}