import React, { Component } from 'react';

import { eventTypeEnum } from '../services/event';

const defaultValue = {
  loginState      : false,
  checkLoginState : () => {},
  signOut         : () => {}
};

export const Login = React.createContext(defaultValue);

export class LoginProvider extends Component {

  state = {
    loginState  : false,
    loaderState : false,
  }

  checkLoginState = () => {
    const token = localStorage.getItem('token');
    this.setState({ loginState: !!token })
  }

  signOut = () => {
    localStorage.clear();
    this.setState({ loginState: false })
  }

  handlerEvent = event => {
    if (event.type === eventTypeEnum.logout) this.signOut();
    if (event.type === eventTypeEnum.loaderShow) this.setState({ loaderState: true });
    if (event.type === eventTypeEnum.loaderHide) {
      setTimeout(() => {
        if (this.state.loaderState) this.setState({ loaderState: false });
      }, 1500);
    }
  }

  componentDidMount() {
    document.addEventListener(eventTypeEnum.logout, this.handlerEvent, true);
    document.addEventListener(eventTypeEnum.loaderShow, this.handlerEvent, true);
    document.addEventListener(eventTypeEnum.loaderHide, this.handlerEvent, true);
    this.checkLoginState();
  }

  componentWillUnmount = () => {
    document.removeEventListener(eventTypeEnum.logout, this.handlerEvent);
  }

  render() {
    const { loginState, loaderState } = this.state;
    const { checkLoginState, signOut } = this;
    const value = { loginState, loaderState, checkLoginState, signOut };
    return (
      <Login.Provider value={value}>
        {this.props.children}
      </Login.Provider>
    )
  }
}
