import React, { Component, Fragment } from 'react';

import Input from '../../../../platform/components/Input';

class InfoViewModel {
  description = '';
  firstName = '';
  lastName = '';
  specialty = '';

  errors = {
    firstName : false,
    lastName  : false,
  };
  
  validate = () => {
    let isValid = true;

    const ERRORS = {
      firstName : false,
      lastName  : false,
    };

    if (!this.firstName) {
      ERRORS.firstName = true;
      isValid = false;
    }

    if (!this.lastName) {
      ERRORS.lastName = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}


export default class Info extends Component {

  state= {
    data : new InfoViewModel(),
  }
  
  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  // ! The parent component calls this function.
  fullInfo = () => {
    if (this.props.requiredFields) {
      const data = this.state.data;
      data.validate();
      this.setState({ data });
      this.props.getFullInfo(data);
    } else this.props.getFullInfo(this.state.data);
  }

  render() {
    const { data } = this.state;
    const { requiredFields } = this.props;
    return (
      <Fragment>
        <div className="G-flex G-justify-between G-half-input-box">
          <Input
            title="Name"
            name="firstName"
            value={data.firstName}
            required={requiredFields}
            errors={requiredFields && data.errors.firstName}
            onChange={this.handleChange}
          />
          <Input
            title="Surname"
            name="lastName"
            value={data.lastName}
            required={requiredFields}
            errors={requiredFields && data.errors.lastName}
            onChange={this.handleChange}
          />
        </div>

        <Input
          title="Profession"
          name="specialty"
          value={data.specialty}
          onChange={this.handleChange}
        />

        <div className="G-input-box">
          <p>Description</p>
          <textarea cols="10" rows="10"
            value={data.description}
            onChange={event => this.handleChange({ name: 'description', value: event.target.value})}
          />
        </div>
      </Fragment>
    )
  }
}