export class AddImageViewModel {
  nameEn = '';
  nameHy = '';
  image = null;

  errors = {
    nameEn : false,
    nameHy : false,
    image  : false,
  };

  constructor(data) {
    if (data) {
      this.nameEn = data.nameEn;
      this.nameHy = data.nameHy;
      this.image = data.image;
    }
  }

  getBaseModel = () => {
    const formData = new FormData();
    formData.append('nameEn', this.nameEn);
    formData.append('nameHy', this.nameHy);
    formData.append('image', this.image);

    return formData;
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      nameEn : false,
      nameHy : false,
      image  : false,
    };

    if (!this.nameEn || this.nameEn.length < 5) {
      ERRORS.nameEn = true;
      isValid = false;
    }

    if (!this.nameHy || this.nameHy.length < 5) {
      ERRORS.nameHy = true;
      isValid = false;
    }

    if (!this.image) {
      ERRORS.image = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class UpdateImageViewModel {
  nameEn = '';
  nameHy = '';

  errors = {
    nameEn : false,
    nameHy : false,
  };

  constructor(data) {
    if (data) {
      this.nameEn = data.nameEn;
      this.nameHy = data.nameHy;
    }
  }

  getBaseModel = () => {
    return {
      nameEn: this.nameEn,
      nameHy: this.nameHy,
    };
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      nameEn : false,
      nameHy : false,
    };

    if (!this.nameEn || this.nameEn.length < 5) {
      ERRORS.nameEn = true;
      isValid = false;
    }

    if (!this.nameHy || this.nameHy.length < 5) {
      ERRORS.nameHy = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}

export class ModifyVideoViewModel {
  nameEn = '';
  nameHy = '';
  date = '';
  path = '';

  errors = {
    // nameEn : false,
    // nameHy : false,
    // date   : false,
    path   : false,
  };

  constructor(data) {
    if (data) {
      this.nameEn = data.nameEn;
      this.nameHy = data.nameHy;
      this.date = data.date;      
      this.path = data.path;
    }
  }

  getBaseModel = () => {
    return {
      nameEn: this.nameEn,
      nameHy: this.nameHy,
      date: this.date,      
      path: this.path,
    };
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      // nameEn : false,
      // nameHy : false,
      // date   : false,      
      path   : false,
    };

    // if (!this.nameEn || this.nameEn.length < 5) {
    //   ERRORS.nameEn = true;
    //   isValid = false;
    // }

    // if (!this.nameHy || this.nameHy.length < 5) {
    //   ERRORS.nameHy = true;
    //   isValid = false;
    // }

    //   if (!this.date) {
    //   ERRORS.date = true;
    //   isValid = false;
    // }

    if (!this.path) {
      ERRORS.path = true;
      isValid = false;
    }

    this.errors = ERRORS;
    return isValid;
  }
}