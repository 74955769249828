import React              from 'react';
import ReactDOM           from 'react-dom';
import NotificationSystem from 'react-notification-system';

import { NotificationEnum } from '../enums';

class Notification extends React.Component {

  _notificationSystem;

  componentDidMount = () => {
    document.addEventListener('Notification', this.addNotification, true);
    this._notificationSystem = this.notificationSystem;
  };

  componentWillUnmount = () => {
    document.removeEventListener('Notification', this.addNotification);
  }

  addNotification = notification => {
    this._notificationSystem.addNotification({
      level      : NotificationEnum.Success,
      position   : 'br',
      autoDismiss: 5,
      children: <div className="App-notification-item">
        <h6 className="G-mb-1">{notification.detail.title}</h6>
        <p>{notification.detail.message}</p>
      </div>
    });
  };

  render() {
    return ReactDOM.createPortal(
      <NotificationSystem ref={ref => this.notificationSystem = ref} />,
      document.getElementById('notification')
  );
}}

export default Notification;
