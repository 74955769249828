import { connection, upload } from '../../services/connection';

//#region JSDoc types

/**
 * @typedef {Object} LoginParams
 * @property {string} email
 * @property {string} password
 */

 /**
 * @typedef {Object} CheckParams
 * @property {string} email
 * @property {string} code
 */

 //#endregion

const controllerName = 'Auth';

export default class AuthService {

  //#region POST requests

  /**
   * @param {LoginParams} data */
  static Login = data => {
    return connection({
      url    : `${controllerName}/Login`,
      method : 'POST',
      body   : data
    });
  }

 /**
  * @param {CheckParams} data */ 
  static Check = data => {
    return connection({
      url    : `${controllerName}/check`,
      method : 'POST',
      body   : data
    });
  }

  static ForgotEmail = data => {
    return connection({
      url    : `${controllerName}/forgot/email`,
      method : 'POST',
      body   : data
    });
  }

  static ForgotCheck = data => {
    return connection({
      url    : `${controllerName}/forgot/check`,
      method : 'POST',
      body   : data
    });
  }

  static Forgot = data => {
    return connection({
      url    : `${controllerName}/forgot`,
      method : 'POST',
      body   : data
    });
  }

  static Register = data => {
    return upload({
      url         : `${controllerName}/register`,
      method      : 'POST',
      body        : data,
    });
  }

  //#endregion

  //#region GET requests

  static Avatar = data => {
    return connection({
      url    : `${controllerName}/avatar`,
      method : 'GET',
    });
  }

  //#endregion
}