import React, { Component } from 'react';

import Login           from './components/Login';
import ForgotPassEmail from './components/ForgotPassEmail';

import LOGO from '../../../assets/images/logo.png';

export default class SignIn extends Component {

  state = {
    loginPage: true
  }

  changePage = () => this.setState({ loginPage: !this.state.loginPage });

  render() {
    const { loginPage } = this.state;
    return (
      <div className="App-sign-in-page G-flex">
        <div className="left-box">
          <img src={LOGO} alt="LOGO"/>
        </div>
        <div className="right-box G-flex G-center">
          {loginPage ?
            <Login
              checkLoginState={() => this.props.checkLoginState()}
              showForgotPasswordPage={this.changePage} /> :
            <ForgotPassEmail showLoginPage={this.changePage} />
          }
        </div>
      </div>
    )
  }
}
