import React from 'react';
import classnames from 'classnames';

let AppPageToolbar = props => (
  <div className="App-page-toolbar-box">
    <div className="App-toolbar-line" />
    <div className={classnames("App-page-toolbar G-flex G-align-center", props.className)}>
      {props.children}
    </div>
  </div>
)

export default AppPageToolbar;