import React, { Component } from 'react';
import classnames           from 'classnames';

import AuthService from '../../../../platform/api/auth';
import Modal       from '../../../../platform/components/Modal';
import Closer      from '../../../../platform/components/Closer';

export default class DefaultImageModel extends Component {

  state = {
    selectedDefaultImage : null,
    defaultImages        : []
  }

  componentDidMount() {
    this.getDefaultImages();
  }

  getDefaultImages = async () => {
    const res = await AuthService.Avatar();
    this.setState({ defaultImages: res.data })
  }
  
  selectDefaultImage = item => {
    this.setState({ selectedDefaultImage: item });
  }

  render() {
    const { defaultImages, selectedDefaultImage } = this.state;
    const { closeModal, getDefaultImage } = this.props;
    return (
      <Modal onClickOutside={closeModal}>
        <div className="App-choose-default-image">
          <h6 className="G-mb-2">Avatar photo</h6>
          <div className="G-flex G-justify-between images G-mb-2">
            {defaultImages ? defaultImages.map((item, index) => (
              <div
                key={index}
                onClick={() => this.selectDefaultImage(item)}
                className={classnames('G-bg-image', { 'G-mr-4': index !== defaultImages.length - 1 }, { 'active': selectedDefaultImage && selectedDefaultImage.type === item.type })}
                style={{ backgroundImage: `url(${item.path})` }} />
            )): <div className="G-bg-image" />}
          </div>
          <button className="G-btn App-btn" onClick={() => getDefaultImage(selectedDefaultImage)}>Save</button>
          <Closer close={closeModal} />
        </div>
      </Modal>
    )
  }
}