import React from 'react';
import classnames from 'classnames';

const Input = ({
	title,
	name,
	value,
	errors,
	required,
	onChange,
	enterKeyPress,
	className,
}) => {

	const handleKeyDown = e => {
    if (e.key === 'Enter') enterKeyPress && enterKeyPress(e);
	}
	
	const handleChange = (event) => {
		const value = event.target.value;
		onChange && onChange({ name, value });
  };

	return (
		<div className={classnames("G-input-box", className)}>
			<p>{title} {required && <span>*</span>}</p>
			<input
			  type="text"
				value={value || ''}
				className={classnames({ 'G-error': errors })}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
			/>
		</div>
	)
}

export default Input;