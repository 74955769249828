import UserService from './';
import NotificationSystem from '../../services/notificationSystem';
import {
  emailErrorMessage,
  fieldErrorMessage,
} from '../../lang/messages';

export class InviteViewModel {
  email = '';
  name = '';

  errors = {
    email    : false,
    name : false,
  };

  constructor(data) {
    if (data) {
      this.email = data.email;
      this.name = data.name;
    }
  }

  getBaseModel = () => {
    return {
      email : this.email,
      name  : this.name,
    }
  }

  check = async () => {
    const res = await UserService.Check({ email: this.email });
    return res.success;
  }

  validate = () => {
    let isValid = true;
    const messages = [];

    const ERRORS = {
      email    : false,
      name : false,
    };

    if (!this.name) {
      ERRORS.name = true;
      isValid = false;
      messages.push(fieldErrorMessage.en);
    }

    const emailValid = this.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
    if (!emailValid) {
      ERRORS.email = true;
      isValid = false;
      messages.push(emailErrorMessage.en);
    }

    if (!isValid && messages.length) {
      NotificationSystem.showNotification('Warning', messages[0]);
    }

    this.errors = ERRORS;
    return isValid;
  }
}