import { statusColors } from "../../../../platform/services/constant";

export const goalTableRows = [
  {
    func  : item => item.title.length > 65 ? item.title.slice(0, 65) : item.title,
    label : 'Title',
  },
  {
    func  : item => item.description.length > 75 ? item.description.slice(0, 75) : item.description,
    label : 'Description',
  }
];

export const globalStepTableRows = [
  {
    label : 'Title',
    func  : item => item.title.length > 45 ? item.title.slice(0, 42) : item.title,
    title : 'title'
  },
  { 
    key   : 'startDate',
    label : 'Start Date',
    date  : true,
  },
  { 
    key   : 'endDate',
    label : 'End date',
    date  : true,
  },
  {
    key              : 'status',
    label            : 'Status',
    status           : true,
    statusEnum       : {
      1: 'Unapproved',
      2: 'Draft',
      3: 'Active',
      4: 'Outdated',
      5: 'Finished'
    },
    colorEnum        : {
      1: statusColors.unapproved,
      2: statusColors.draft,
      3: statusColors.active,
      4: statusColors.outdate,
      5: statusColors.finished
    },
  }
];

export const objectiveTableRows = [
  {
    func  : item => item.name.length > 45 ? item.name.slice(0, 42) : item.name,
    label : 'Title',
  },
  { 
    key   : 'startDate',
    label : 'Start Date',
    date  : true,
  },
  { 
    key   : 'endDate',
    label : 'End date',
    date  : true,
  },
  {
    key              : 'status',
    label            : 'Status',
    status           : true,
    statusEnum       : {
      1: 'Draft',
      2: 'Active',
      3: 'Outdate',
      4: 'Finished',
    },
    colorEnum        : {
      1: statusColors.draft,
      2: statusColors.active,
      3: statusColors.outdate,
      4: statusColors.finished
    },
  }
];

export const activityTableRows = [
  {
    func  : item => item.name.length > 45 ? item.name.slice(0, 42) : item.name,
    label : 'Title',
  },
  { 
    key   : 'startDate',
    label : 'Start Date',
    date  : true,
  },
  { 
    key   : 'endDate',
    label : 'End date',
    date  : true,
  },
  {
    key              : 'status',
    label            : 'Status',
    status           : true,
    statusEnum       : {
      1: 'Draft',
      2: 'Active',
      3: 'Outdate',
      4: 'Finished',
    },
    colorEnum        : {
      1: statusColors.draft,
      2: statusColors.active,
      3: statusColors.outdate,
      4: statusColors.finished
    },
  }
];

export const taskTableRows = [
  {
    func  : item => item.name.length > 45 ? item.name.slice(0, 42) : item.name,
    label : 'Title',
  },
  { 
    key   : 'startDate',
    label : 'Start Date',
    date  : true,
  },
  { 
    key   : 'endDate',
    label : 'End date',
    date  : true,
  },
  {
    key              : 'status',
    label            : 'Status',
    status           : true,
    statusEnum       : {
      1: 'Unapproved',
      2: 'Draft',
      3: 'Active',
      4: 'OutDated',
      5: 'Finished',
      6: 'Report Unapproved'
    },
    colorEnum        : {
      1: statusColors.unapproved,
      2: statusColors.draft,
      3: statusColors.active,
      4: statusColors.outdate,
      5: statusColors.finished,
      6: statusColors.unapproved
    }
  }
];

export const actionType = {
  homePage : 1,
  web      : 2,
}
