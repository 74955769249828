class Settings {
  get token() {
    const TOKEN = localStorage.getItem('token');
    return TOKEN ? TOKEN : undefined;
  }

  set token(token) {
    localStorage.setItem('token', token);
  }

  get role() {
    const ROLE = localStorage.getItem('role');
    return ROLE ? +ROLE : undefined;
  }

  set roleState(role) {
    localStorage.setItem('role', role);
  }

  setBookPageNo(pageNo) {
    localStorage.setItem('bookPageNo', pageNo);
  }

  getBookPageNo() {
    const pageNo = localStorage.getItem('bookPageNo');
    return pageNo ? +pageNo : undefined;
  }

  set setNewsPageNo(pageNo) {
    localStorage.setItem('newsPageNo', pageNo);
  }

  get getNewsPageNo() {
    const pageNo = localStorage.getItem('newsPageNo');
    return pageNo ? +pageNo : undefined;
  }

  set setEventPageNo(pageNo) {
    localStorage.setItem('eventPageNo', pageNo);
  }

  get getEventPageNo() {
    const pageNo = localStorage.getItem('eventPageNo');
    return pageNo ? +pageNo : undefined;
  }

  emtyAllPageNos() {
    console.log('Empting..');
    localStorage.removeItem('bookPageNo');
    localStorage.removeItem('newsPageNo');
    localStorage.removeItem('eventPageNo');
  }

  emptyAllExept(exept) {
    console.log('Empting exept ' + exept);
    if (exept !== 'book') localStorage.removeItem('bookPageNo');
    if (exept !== 'news') localStorage.removeItem('newsPageNo');
    if (exept !== 'event') localStorage.removeItem('eventPageNo');
  }

}

export default new Settings();