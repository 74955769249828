import React, { Component } from 'react';
import classNames           from 'classnames';

import { AddImageViewModel, UpdateImageViewModel } from '../../../../../platform/api/media/view-model';
import MediaService from '../../../../../platform/api/media';
import FileUpload from '../../../../../platform/components/FileUpload'

import Closer from '../../../../../platform/components/Closer';

export default class Manage extends Component {

  state = {
    form: new AddImageViewModel(),
  }

  componentDidMount() {
    const { data } = this.props;
    data && this.setState({ form: new UpdateImageViewModel(data) });
  }

  handleChange = event => {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  save = async () => {
    const { data } = this.props;
    const { form } = this.state;
    const valid = form.validate();
    if (valid) {
      if (data) {
        const editForm = { id: data._id, ...form.getBaseModel() };        
        const res = await MediaService.EditImage(editForm);      
        res.success && this.props.closeModal(editForm);
      } else {
        const res = await MediaService.UploadImage(form.getBaseModel());
        res.success && this.props.closeModal();
      }
    } else this.setState({ form });
  }

  imageChange = file => {
    const { form } = this.state;
    form.image = file || null;
    this.setState({ form });
  }

  render() {
    const { data } = this.props;
    const { form } = this.state;

    return (
      <div className="App-modify-media">
        <div className="G-flex G-align-center G-mb-2">
          <h4>{data ? 'Update photo' : 'Add photo'}</h4>
          <Closer close={this.props.close} />
        </div>
        <div className="App-modify-media-content G-flex G-justify-between">
          <div className="G-input-box">
            <p>English name</p>
            <input
              type="text"
              name="nameEn"
              value={form.nameEn}
              className={classNames({ 'G-error': form.errors.nameEn })}
              onChange={this.handleChange} />
          </div>
          <div className="G-input-box"> 
            <p>Armenian name</p>
            <input
              type="text"
              name="nameHy"
              value={form.nameHy}
              className={classNames({ 'G-error': form.errors.nameHy })}
              onChange={this.handleChange} />
          </div>
          {!data && <div className="G-input-box"> 
            <p>Photo</p>
            <FileUpload
              output={this.imageChange}
              className={classNames({ 'G-error': form.errors.image })}
            />
          </div>}
        </div>
        <div className="G-flex G-justify-end">
          <button className="G-btn App-btn" onClick={this.save}>Save</button>
        </div>
      </div>
    )
  }
}
