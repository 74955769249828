import React, { Component }                from 'react';
import { Route, Switch, Redirect, Router } from 'react-router-dom';
import { createBrowserHistory }            from 'history';
import { CssBaseline }                     from '@material-ui/core';

import { to } from '.';

import Notification     from '../platform/components/Notification';
import Settings         from '../platform/services/settings';
import { UserTypeEnum } from '../platform/enums';

//#region Pages and Components

import Header    from '../components/Header';

import Newspapers     from '../views/shared/newspapers/Newspapers';
import UsersWeb       from '../views/shared/users/Users-v2';
import UsersList      from '../views/shared/users/Users';
import UserManage     from '../views/shared/users/UserManage';
import UserDetails    from '../views/shared/users/UserDetails';
import ProfileManage  from '../views/shared/users/ProfileManage';
import Events         from '../views/shared/events/Events';
import EventsManage   from '../views/shared/events/EventsManage';
import NewsManage     from '../views/shared/events/NewsManage';
import News           from '../views/shared/events/News';
import Covers         from '../views/shared/builder/Covers';
import Photos         from '../views/shared/media/photos/Photos';
import Videos         from '../views/shared/media/videos/Videos';
import HomeSection    from '../views/shared/builder/HomeSection';
import Calculator     from '../views/shared/builder/Calculator';
// import ProgramsManage from '../views/shared/programs-v2/ProgramsManage';
import Programs       from '../views/shared/programs/Programs';
import ProgramsManage from '../views/shared/programs/ProgramsManage';
import BooksCategory  from '../views/shared/books/books-category/BooksCategory';
import Books          from '../views/shared/books/Books';
import BooksFavorites from '../views/shared/books/BookFavorite';
import BooksManage    from '../views/shared/books/BooksManage';
import Notifications  from '../views/shared/notifications/Notifications';
import Task           from '../views/shared/programs/components/task/TasksManage';
import Report         from '../views/shared/programs/components/task/ReportsManage';

//#endregion

export const HISTORY = createBrowserHistory();

class ClientRoutes extends Component {

  state = {
    sidebarOpen: true,
  }

  changeSidebar = () => {
    this.setState({ sidebarOpen: !this.state.sidebarOpen })
  }

  render() {
    const { context } = this.props;

    return (
      <Router history={HISTORY}>
        <CssBaseline />
        <Notification/>
        <Header signOut={context.signOut} />
        <div className="App-router-content">
          <Switch>

            {/* This Routes available to only admin */}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.bookList} component={Books} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.booksCategory} component={BooksCategory} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.booksManage} component={BooksManage} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.booksFavorite} component={BooksFavorites} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.builderCovers} component={Covers} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.builderCalculator} component={Calculator} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.builderSections} component={HomeSection} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.events} component={Events} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.eventsManage} component={EventsManage} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.news} component={News} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.newsManage} component={NewsManage} />}
            <Route exact path={to.notifications} component={Notifications} />
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.newspapers} component={Newspapers} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.usersWebList} component={UsersWeb} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.usersAdminList} component={UsersList} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.usersManage} component={UserManage} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.usersAdminDetails} component={UserDetails} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.photos} component={Photos} />}
            {UserTypeEnum.admin === Settings.role && <Route exact path={to.videos} component={Videos} />}
            {UserTypeEnum.user === Settings.role && <Route exact path={to.profile} component={ProfileManage} />}
            <Route exact path={to.programs} component={Programs} />
            <Route exact path={to.programsManage} component={ProgramsManage} />
            <Route exact path={to.taskManage} component={Task} />
            <Route exact path={to.reportManage} component={Report} /> 
            <Redirect to={to.programs} />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default ClientRoutes;
