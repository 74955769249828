import React, { Component } from 'react';
import classnames           from 'classnames';
import { Editor }           from 'react-draft-wysiwyg';
import draftToHtml          from 'draftjs-to-html';
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from 'draft-js';

import Input from '../../../../platform/components/Input';

class InfoViewModel {
  title = '';
  shortDescription = '';
  description = '';

  constructor(data) {
    if (data) {
      this.title = data.title || '';
      this.description = data.description || '';
      this.shortDescription = data.shortDescription || '';
    }
  }
}

export default class Info extends Component {

  state = {
    data        : new InfoViewModel(),
    info        : null,
    editorState : EditorState.createEmpty(),
  }

  handleChange = ({ name, value }) => {
    const data = this.state.data;
    data[name] = value;
    this.setState({ data });
  };

  handleEventChange = (event, name) => {
    const data = this.state.data;
    data[name] = event.target.value;
    this.setState({ data });
  };

  // ! The parent component calls this function.
  fullInfo = () => {
    const data = this.state.data;
    this.setState({ data });
    this.props.getFullInfo(data);
  }

  setEditorState = text => {
    if (text) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(text)
        )
      );
      this.setState({ editorState });
    }
  }

  onEditorStateChange = (editorState) => {
    const html = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const data = this.state.data;
    data.description = html;
    this.setState({ data, editorState });
  };

  componentDidMount() {
    const { info } = this.props;
    if (info) {
      const data = new InfoViewModel(info);
      this.setEditorState(data.description);
      this.setState({ data, info });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { info } = nextProps;
    if (JSON.stringify(this.state.info) !== JSON.stringify(info)) {
      const data = new InfoViewModel(info);
      this.setEditorState(data.description);
      this.setState({ info, data });
    }
  }

  render() {
    const { data, editorState } = this.state;
    const { requiredFields, errors = {} } = this.props;
    return (
      <div className="App-manage-event-news-info">
        <Input
          title="Title"
          name="title"
          value={data.title}
          required={requiredFields}
          errors={requiredFields && errors.title}
          onChange={this.handleChange}
        />

        <div className="G-input-box">
			    <p>Short description {requiredFields && <span>*</span>}</p>
          <textarea cols="5" rows="5"
            className={classnames({ 'G-error': errors.shortDescription })}
            value={data.shortDescription}
            onChange={event => this.handleEventChange(event, 'shortDescription')}
          />
        </div>

        <div className="G-input-box">
			    <p>Description {requiredFields && <span>*</span>}</p>
          <Editor
            wrapperClassName={classnames({ 'G-error': errors.description })}
            editorState={editorState}
            editorStyle={{ height: '600px' }}
            toolbar={{
              options: ['inline', 'textAlign'],
              inline: {
                options: [
                  'bold',
                  'italic',
                  'underline',
                ],
              },
            }}
            onEditorStateChange={this.onEditorStateChange}
          />
        </div>

      </div>
    )
  }
}
