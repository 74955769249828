import React, { Component } from 'react';

import { tableRowsForCovers } from './services/constant';
import CoverManage            from './components/CoverManage';
import Modal                  from '../../../platform/components/Modal';
import AppPageToolbar         from '../../../platform/components/PageToolbar';
import AppTable               from '../../../platform/components/table/Table';
import TableToolbar           from '../../../platform/components/table/TableToolbar';
import FileService            from '../../../platform/api/file';
import settings from '../../../platform/services/settings';

export default class Covers extends Component {

  state = {
    itemList   : [],
    modifyData : null,
  }

  getItemList = async () => {
    const res = await FileService.CoverList();
    if (res.success && res.data) this.setState({ itemList: res.data });
  }

  editClick = item => this.setState({ modifyData: item });
  closeModifyModal = () => this.setState({ modifyData: null });

  componentDidMount() {
    settings.emtyAllPageNos();
    this.getItemList();
  }

  render() {
    const { itemList, modifyData } = this.state;
    return (
      <div className="App-page">
        <AppPageToolbar>
          <TableToolbar title={'Cover list'} count={itemList.length} />
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-page-content">
            <AppTable
              withoutPaging
              hideCheckbox
              list={itemList}
              rows={tableRowsForCovers}
              editClick={this.editClick}
              getSelectedItems={this.getSelectedItems}
            />
          </div>
        </div>
        {modifyData && <Modal onClickOutside={this.closeModifyModal}>
          <CoverManage
            data={modifyData}
            updateList={this.getItemList}
            close={this.closeModifyModal}
          />
        </Modal>}
      </div>
    )
  }
}