import React, { Component } from 'react';

export default class MultiImageUpload extends Component {

  state = {
    imageUrls    : [],
    images       : [],
    deleteImages : [],
    uniqueId     : null,
  }

  input = React.createRef();
  blobFiles = [];

  componentDidMount() {
    const uniqueId = `App-file-upload-${new Date().getTime()}-${this.props.uniqueKey || ''}`;
    this.setState({ uniqueId });
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.images && nextProps.images.length !== this.state.images.length) {
      this.setState({
        images : nextProps.images
      });
    }
  }

  filesSelectedHandler = file => {
    if (file.target && file.target.files && file.target.files.length) {
      const files = file.target.files;
      Object.keys(files).map(IMG => {
        const READER = new FileReader();
        const file = files[IMG];
        READER.readAsDataURL(file);
        READER.onload = () => {
          const list = [...this.state.imageUrls, READER.result ];
          this.blobFiles.push(file);
          this.props.output(this.blobFiles);
          this.setState({ imageUrls : list });
          this.input.current.value = null;
        };
        return IMG;
      })
    }
  }

  deletePhoto = index => {
    const urls = [...this.state.imageUrls];
    urls.splice(index, 1);
    this.setState({ imageUrls: urls });
    this.blobFiles.splice(index, 1);
    this.props.output(this.blobFiles);
    this.input.current.value = null;
  }

  deleteUploadedPhoto = (item, index) => {
    const ids = [ ...this.state.deleteImages ];
    const images = [ ...this.state.images ];
    images.splice(index, 1);
    ids.push(item._id);
    this.setState({
      deleteImages: ids,
      images,
    });
    this.props.getDeletedImagesId && this.props.getDeletedImagesId(ids, images);
  }

  render() {
    const { imageUrls, images, uniqueId } = this.state;
    return (
      <div className="App-image-upload-component App-multi-image-upload G-flex G-flex-wrap">
        <input type="file" id={uniqueId} multiple={true} ref={this.input} onChange={this.filesSelectedHandler} />
        {images.map((item, index) => (
          <div className="App-image-upload G-mr-2 G-mb-2" key={index}>
            <div className="App-uploaded-image">
              <div className="image" style={{ backgroundImage: `url(${item.path})` }} />
              <div className="opacity">
                <h4 onClick={() => this.deleteUploadedPhoto(item, index)}>DELETE</h4>
              </div>
            </div>
          </div>  
        ))}
        {imageUrls.map((url, index) => (
          <div className="App-image-upload G-mr-2 G-mb-2" key={index}>
            <div className="App-uploaded-image">
              <div className="image" style={{ backgroundImage: `url(${url})` }} />
              <div className="opacity">
                <h4 onClick={() => this.deletePhoto(index)}>DELETE</h4>
              </div>
            </div>
          </div>  
        ))}
        <div className="App-image-upload">
          <label htmlFor={uniqueId}>
            <i className="icon-upload"/>
            {this.props.children || <p>Select image</p>}
          </label>
        </div>
      </div>
    );
  }
}