import { connection } from '../../services/connection';

const controllerName = 'global';

export default class GlobalStepService {

  static List = id => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'POST',
      body   : { programId: id },
    });
  }

  static Details = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'GET',
    });
  }

  static Add = form => {
    return connection({
      url    : controllerName,
      method : 'POST',
      body   : form,
    });
  }

  static Edit = form => {
    return connection({
      url    : controllerName,
      method : 'PUT',
      body   : form,
    });
  }

  static Delete = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'DELETE'
    });
  }

  static Range = id => {
    return connection({
      url    : `${controllerName}/range?id=${id}`,
      method : 'GET',
    });
  }

  static Finish = globalStepId => {
    return connection({
      url    : `${controllerName}/finish`,
      method : 'PUT',
      body   : { id: globalStepId }
    });
  }

  static Approve = id => {
    return connection({
      url    : `${controllerName}/approve?id=${id}`,
      method : 'PUT',
    });
  }
}
