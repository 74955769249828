import React, { Component } from 'react';
import classNames           from 'classnames';

import { ModifyBookCategoryModel } from '../../../../../platform/api/book/view-model';
import BookService from '../../../../../platform/api/book';

import ImageUpload from '../../../../../platform/components/ImageUpload';
import Closer      from '../../../../../platform/components/Closer';

export default class Manage extends Component {

  state = {
    form: new ModifyBookCategoryModel()
  }

  componentDidMount() {
    const { data } = this.props;
    data && this.setState({ form: new ModifyBookCategoryModel(data) });
  }

  handleChange = event => {
    const { form } = this.state;
    form[event.target.name] = event.target.value;
    this.setState({ form });
  };

  handleImage = (image) => {
    const { form } = this.state;
    if (!image) {
      form.showImage = null;
      form.image = null;
    } else {
      form.showImage = URL.createObjectURL(image);
      form.image = image;
    }
    this.setState({ form });
  }

  save = async () => {
    const { data } = this.props;
    const { form } = this.state;
    const valid = form.validate();
    if (valid) {
      const DATA = new FormData();
      DATA.append('nameEn', form.nameEn);
      DATA.append('nameHy', form.nameHy);
      if (form.image) DATA.append('image', form.image);
      if (data) {
        DATA.append('id', form.id);
        // const editForm = form.getBaseModel();
        const res = await BookService.EditCategory(DATA);
        res.success && this.props.closeModal();
      } else {
        const res = await BookService.AddCategory(DATA);
        res.success && this.props.closeModal();
      }
    } else this.setState({ form });
  }

  render() {
    const { data } = this.props;
    const { form } = this.state;

    return (
      <div className="App-modify-media">
        <div className="G-flex G-align-center G-mb-2">
          <h4>{data ? 'Update category' : 'Add category'}</h4>
          <Closer close={this.props.close} />
        </div>
        <div className="App-modify-book-category">
          <div className="App-modify-book-category-image">
            <ImageUpload
              imageUrl={form.showImage}
              uniqueKey="image"
              errors={!form.showImage}
              output={file => this.handleImage(file)}
            />
          </div>
          <div className="App-modify-media-content G-flex G-justify-between">
            <div className="G-input-box">
              <p>English name</p>
              <input
                type="text"
                name="nameEn"
                value={form.nameEn}
                className={classNames({ 'G-error': form.errors.nameEn })}
                onChange={this.handleChange} />
            </div>
            <div className="G-input-box"> 
              <p>Armenian name</p>
              <input
                type="text"
                name="nameHy"
                value={form.nameHy}
                className={classNames({ 'G-error': form.errors.nameHy })}
                onChange={this.handleChange} />
            </div>
        </div>
        </div>
        <div className="G-flex G-justify-end">
          <button className="G-btn App-btn" onClick={this.save}>Save</button>
        </div>
      </div>
    )
  }
}
