import NotificationSystem    from '../../services/notificationSystem';
import { fieldErrorMessage } from '../../lang/messages';

export class UserItemViewModel {
  firstNameHy = '';
  lastNameHy = '';
  firstNameEn = '';
  lastNameEn = '';
  professionHy = '';
  professionEn = '';
  descriptionHy = '';
  descriptionEn = '';

  image = null;
  imageError = false;

  errors = {
    firstNameHy   : false,
    lastNameHy    : false,
    firstNameEn   : false,
    lastNameEn    : false,
    professionHy  : false,
    professionEn  : false,
    descriptionHy : false,
    descriptionEn : false,
  };

  constructor(data) {
    if (data) {
      this.firstNameHy = data.firstNameHy;
      this.lastNameHy = data.lastNameHy;
      this.firstNameEn = data.firstNameEn;
      this.lastNameEn = data.lastNameEn;
      this.professionHy = data.professionHy;
      this.professionEn = data.professionEn;
      this.descriptionHy = data.descriptionHy;
      this.descriptionEn = data.descriptionEn;
      this.image = data.image;
    }
  }

  getBaseModel = () => {
    return {
      firstNameHy        : this.firstNameHy,
      lastNameHy         : this.lastNameHy,
      firstNameEn        : this.firstNameEn,
      lastNameEn         : this.lastNameEn,
      professionHy       : this.professionHy,
      professionEn       : this.professionEn,
      descriptionHy      : this.descriptionHy,
      descriptionEn      : this.descriptionEn,
      image              : this.image,
    }
  }

  validate = () => {
    let isValid = true;

    const ERRORS = {
      firstNameHy        : false,
      lastNameHy         : false,
      firstNameEn        : false,
      lastNameEn         : false,
      professionHy       : false,
      professionEn       : false,
      descriptionHy      : false,
      descriptionEn      : false,
    };

    if (!this.firstNameHy) {
      ERRORS.firstNameHy = true;
      isValid = false;
    }

    if (!this.lastNameHy) {
      ERRORS.lastNameHy = true;
      isValid = false;
    }

    if (!this.firstNameEn) {
      ERRORS.firstNameEn = true;
      isValid = false;
    }

    if (!this.lastNameEn) {
      ERRORS.lastNameEn = true;
      isValid = false;
    }

    if (!this.descriptionHy) {
      ERRORS.descriptionHy = true;
      isValid = false;
    } else if (this.descriptionHy.length < 250) {
      ERRORS.descriptionHy = true;
      isValid = false;
      NotificationSystem.showNotification('Warning', 'Description (AM) length must be at least 250 characters long');
    }

    if (!this.descriptionEn) {
      ERRORS.descriptionEn = true;
      isValid = false;
    } else if (this.descriptionEn.length < 250) {
      ERRORS.descriptionEn = true;
      isValid = false;
      NotificationSystem.showNotification('Warning', 'Description (EN) length must be at least 250 characters long');
    }

    if (!this.professionEn) {
      ERRORS.professionEn = true;
      isValid = false;
    }

    if (!this.professionHy) {
      ERRORS.professionHy = true;
      isValid = false;
    }

    if (!isValid) {
      NotificationSystem.showNotification('Warning', fieldErrorMessage.en);
    }

    this.errors = ERRORS;
    return isValid;
  }
}