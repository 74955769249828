import React, { Component } from 'react';
import Input from './Input'
import Card from './Card'


export default class Autocomplete extends Component {

  render() {
    const { suggestions, full } = this.props;

    return (
      <div className={`App-manager-info ${full ? 'App-manager-info-full' : ''}`}>
        <Input
          title={this.props.title}
          name={this.props.name}
          value={this.props.value}
          required={this.props.required}
          errors={this.props.required}
          onChange={this.props.onChange}
        />
        {
          suggestions.length
          ?
          <div className="App-manager-info-autocomplete">
            {this.props.value ?
              <ul>
                {suggestions.map((item, index) =>
                  <Card
                    key={index}
                    property={item}
                    handleManagerClick={this.props.handleManagerClick}
                  />
                )}
              </ul>
              : []}
          </div>
          :
          null
        }
      </div>
    )
  }
}
