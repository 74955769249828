import { connection, upload } from '../../services/connection';

const controllerName = 'Program';

export default class ProgramsService {

  //#region POST

  static List = () => {
    return connection({
      url    : `${controllerName}/list`,
      method : 'GET',
    });
  }

  static ShortList = () => {
    return connection({
      url    : `${controllerName}/drop`,
      method : 'GET',
    });
  }

  static Create = (data) => {
    return upload({
      url    : `${controllerName}`,
      method : 'POST',
      body   : data
    });
  }


  //#endregion

  static Delete = (data) => {
    return connection({
      url    : `${controllerName}/delete?id=${data}`,
      method : 'DELETE',
    });
  }

  static Update = (data) => {
    return upload({
      url    : `${controllerName}`,
      method : 'PUT',
      body   : data
    });
  }

  static Get = id => {
    return connection({
      url    : `${controllerName}/details?id=${id}`,
      method : 'GET',
    });
  }

  static GetList = () => {
    return connection({
      url    : `${controllerName}/drop`,
      method : 'GET',
    });
  }

  static Range = programId => {
    return connection({
      url    : `${controllerName}/range?id=${programId}`,
      method : 'GET',
    });
  }

  static Finish = programId => {
    return connection({
      url    : `${controllerName}/finish`,
      method : 'PUT',
      body   : { id: programId }
    });
  }
}
