import React, { Component } from 'react';
import classnames           from 'classnames';

import UserService         from '../../../../platform/api/user';
import { InviteViewModel } from '../../../../platform/api/user/view-model';

import Closer from '../../../../platform/components/Closer';

export default class InviteUser extends Component {

  state = {
    inviteUserList : [ new InviteViewModel() ],
  }

  flagForFirstItem = false;

  handleChange = (event, name, index) => {
    const list = this.state.inviteUserList;
    list[index][name] = event.target.value;
    this.setState({ inviteUserList: list });
  };

  send = async () => {
    const list = this.state.inviteUserList;
    const listItem = list[list.length - 1];
    const valid = listItem.validate();
    if (valid) {
      const check = await listItem.check();
      if (check) {
        const req = {};
        req.invitations = list.map(item => item.getBaseModel());
        const res = await UserService.Invite(req);
        if (res.success) this.props.closeModal();
      }
    } else this.setState({ inviteUserList: list });
  }

  addPerson = async () => {
    const list = this.state.inviteUserList;
    const valid = list[list.length - 1].validate();
    if (valid) {
      const check = await list[list.length - 1].check();
      if (check) {
        const data = new InviteViewModel()
        list.push(data);
      }
    }
    if (list.length > 1) this.flagForFirstItem = true;
    this.setState({ inviteUserList: list });
  }

  deletePerson = index => {
    const list = this.state.inviteUserList;
    list.splice(index, 1);
    if (list.length === 1 && this.flagForFirstItem) this.flagForFirstItem = false;
    this.setState({ inviteUserList: list });
  }

  render() {
    const { inviteUserList } = this.state;
    return (
      <div className="App-invite-user">
        <div className="G-flex G-align-center G-mb-2">
          <h4>Invite user</h4>
          <Closer close={this.props.closeModal} />
        </div>
        {inviteUserList.map((item, index) => (
          <div className="App-invite-user-item G-flex G-justify-between G-half-input-box" key={index}>
            <div className="G-input-box">
              <p>Full Name</p>
              <input
                type="text"
                value={item.name}
                className={classnames({ 'G-error': item.errors.name })}
                onChange={event => this.handleChange(event, 'name', index)} />
            </div>
            <div className="G-input-box"> 
              <p>Email</p>
              <input
                type="text"
                value={item.email}
                disabled={index !== inviteUserList.length - 1}
                className={classnames({ 'G-error': item.errors.email })}
                onChange={event => this.handleChange(event, 'email', index)} />
            </div>
            {this.flagForFirstItem && <i className="icon-close G-cursor" onClick={() => this.deletePerson(index)} />}
          </div>
        ))}
        <div className="G-flex G-align-center G-mb-2">
          <p className="App-add-btn G-cursor" onClick={this.addPerson}>+ Add another</p>
        </div>
        <div className="G-flex G-justify-end">
          <button className="G-btn App-btn" onClick={this.send}>Send</button>
        </div>
      </div>
    )
  }
}
