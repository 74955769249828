import React, { Component } from 'react';

export default class Closer extends Component {
  
  render () {
    return (
      <div className="App-closer">
        <i className="fas fa-times" onClick={this.props.close} />
      </div>
    );
  }
}