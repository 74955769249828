import React, { Component } from 'react';

import { tableRows } from './services/constant';

import Invite                  from './components/Invite';
import { HISTORY }             from '../../../routes/ClientRoutes';
import { to }                  from '../../../routes';
import UserService             from '../../../platform/api/user';
import { PaginationViewModel } from '../../../platform/view-models';
import Modal                   from '../../../platform/components/Modal';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import AppTable                from '../../../platform/components/table/Table';
import TableToolbar            from '../../../platform/components/table/TableToolbar';
import settings from '../../../platform/services/settings';

export default class Users extends Component {

  // state - list, selectedItemsId, itemCount - for table
  state = {
    list            : [],
    selectedItemsId : [],
    itemCount       : 0,
    inviteModal     : false,
  }

  //#region Table

  paginationData;

  getList = async () => {
    const res = await UserService.List(this.paginationData.getBaseModel());
    if (res.success && res.data) {
      this.setState({
        selectedItemsId : [],
        list            : res.data.itemList || [],
        itemCount       : res.data.itemCount || 0,
      });
    }
  }
  
  changePage = page => {
    this.paginationData.pageNo = page;
    this.getList();
  }

  changeLimit = limit => {
    this.paginationData = new PaginationViewModel();
    this.paginationData.limit = +(limit);
    this.getList();
  }

  
  //#region Selected Items

  getSelectedItems = items => this.setState({ selectedItemsId: items });

  block = async () => {
    const res = await UserService.Block(this.state.selectedItemsId);
    if (res.success) this.getList();
  }

  unblock = async () => {
    const res = await UserService.Unblock(this.state.selectedItemsId);
    if (res.success) this.getList();
  }

  //#endregion Selected items

  //#endregion Table

  toggleInviteModalState = () => this.setState({ inviteModal: !this.state.inviteModal });

  componentDidMount() {
    settings.emtyAllPageNos();
    this.paginationData = new PaginationViewModel();
    this.getList();
  }

  changeSwitchStatus = async(id) => {
    const item = this.state.list.find(item => item._id === id);
    if (!item.blocked) {
      const res = await UserService.WebAction(id);
      if (res.success) this.getList();
    }
  } 

  render() {
    const { list, selectedItemsId, itemCount, inviteModal } = this.state;
    return <div className="App-page">
      <AppPageToolbar>
        <TableToolbar title={'User list'} count={list.length}
          numSelected={selectedItemsId.length}
          blockBtn
          unblockBtn
          blockItem={this.block}
          unblockItem={this.unblock}
        >
          <div className="G-flex">
            <button className="G-btn App-add-btn" onClick={this.toggleInviteModalState}>
              +Add new
            </button>
          </div>
        </TableToolbar>
      </AppPageToolbar>
      <div className="App-page-content-box">
        <div className="App-page-content">
          <AppTable
            rows={tableRows}
            list={list}
            itemCount={itemCount}
            clearSelectedItem={!selectedItemsId.length}
            changePage={page => this.changePage(page)}
            changeLimit={limit => this.changeLimit(limit)}
            fullCellClick={item => HISTORY.push(to.usersAdminDetails + `?id=${item._id}`)}
            getSelectedItems={this.getSelectedItems}
            changeSwitchStatus={this.changeSwitchStatus}/>
        </div>
      </div>
      {inviteModal && <Modal onClickOutside={this.toggleInviteModalState}>
        <Invite closeModal={this.toggleInviteModalState} />
      </Modal>}
    </div>
  }
};
