import { LanguageEnum } from '../enums';

export const languageCodeList = [
  {
    name  : 'EN',
    value : LanguageEnum.en
  },
  {
    name  : 'AM',
    value : LanguageEnum.hy
  },
];


export const statusColors = {
  draft     : 'gray',
  unapproved: 'red',
  active    : 'orange',
  finished  : 'green',
  outdate   : 'gray'
};