export const TaskActionTypeEnum = {
  editTask     : 1,
  deleteTask   : 2,
  approveTask  : 3,
  taskDetails  : 4,
  addReport    : 5,
  editReport   : 6,
  approveReport: 7,
  reportDetails: 8
};

export const WhoIsForTaskEnum = {
  admin                 : 1,
  programResposnisble   : 2,
  activityCreatorManager: 3,
  activityReponsibleUser: 4,
  taskUser              : 5
};

export const WhoIsForActivityEnum = {
  isAdmin            : 1,
  isResponsiveManager: 2,
  isCreatorManager   : 3,
  isManager          : 4,
  isResponsiveUser   : 5,
  isActivityUser     : 6
}

export const ActivityActionEnum = {
  getDetails : 1,
  edit       : 2,
  delete     : 3,
  finish     : 4,
  addTask    : 5,
  getTaskList: 6
}

export const ObjectiveRoleEnum = {
  admin            : 1,
  responsiveManager: 2,
  creatorManager   : 3,
  activityUser     : 4
}

export const ObjectiveActionEnum = {
  getDetails     : 1,
  edit           : 2,
  delete         : 3,
  finish         : 4,
  addActivity    : 5,
  getActivityList: 6
}

export const GlobalStepRoleEnum = {
  admin            : 1,
  responsiveManager: 2,
  creatorManager   : 3,
  activityUser     : 4
}

export const GlobalStepActionEnum = {
  getDetails      : 1,
  edit            : 2,
  delete          : 3,
  finish          : 4,
  approve         : 5,
  addObjective    : 6,
  getObjectiveList: 7
}

export const ProgramRoleEnum = {
  admin            : 1,
  responsiveManager: 2,
  manager          : 3,
  activityUser     : 4
}

export const ProgramActionEnum = {
  edit             : 1,
  finish           : 2,
  delete           : 3,
  details          : 4,
  addGlobalStep    : 5,
  getGlobalStepList: 6
}

export const ProgramListStatusEnum = {
  draft: 1,
  active: 2,
  outdated: 3,
  finished: 4,
  1: 'Draft',
  2: 'Active',
  3: 'Outdate',
  4: 'Finished'
};

export const GoalActionsEnum = {
  edit   : 1,
  delete : 2,
  details: 3
}