import { connection } from '../../services/connection';

const controllerName = 'Book';

export default class BookService {

  //#region GET

  static CategoryList = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}/category?pageNo=${data.pageNo}&limit=${data.limit}`,
      method : 'GET',
    });
  }

  static Create = () => {
    return connection({
      url    : `${controllerName}/draft`,
      method : 'GET',
    });
  }

  static Details = id => {
    return connection({
      url    : `${controllerName}?id=${id}`,
      method : 'GET',
    });
  }

  static FavoriteList = () => {
    return connection({
      url    : `${controllerName}/favorite`,
      method : 'GET',
    });
  }

  //#endregion

  //#region POST

  static List = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}/adminList`,
      body   : data,
      method : 'POST',
    });
  }

  static Main = data => {
    return connection({
      url         : controllerName,
      body        : data,
      method      : 'POST',
      contentType : undefined,
    });
  }

  
  static File = data => {
    return connection({
      url         : `${controllerName}/file`,
      body        : data,
      method      : 'POST',
      contentType : undefined,
    });
  }

  
  static CategoryDrop = () => {
    return connection({
      url    : `${controllerName}/category/drop`,
      method : 'GET',
    });
  }

  static AddCategory = data => {
    return connection({
      url         : `${controllerName}/category`,
      method      : 'POST',
      body        : data,
      contentType : undefined,
    });
  }

  static EditCategory = data => {
    return connection({
      url         : `${controllerName}/category`,
      method      : 'PUT',
      body        : data,
      contentType : undefined,
    });
  }

  //#endregion

  //#region PUT 

  static favoriteOrUnFavorite = id => {
    return connection({
      url   : `${controllerName}/favorite?id=${id}`,
      method: 'PUT'
    });
  }

  static Position = data => {
    return connection({
      url   : `${controllerName}/position`,
      method: 'PUT',
      body  : data
    });
  }

  //#endregion

  //#region DELETE

  static DeleteCategories = ids => {
    return connection({
      url    : `${controllerName}/category`,
      method : 'DELETE',
      body   : { idList: ids },
    });
  }

  static Delete = ids => {
    return connection({
      url    : `${controllerName}`,
      method : 'DELETE',
      body   : { idList: ids },
    });
  }

  //#endregion
}