import React, { Component } from 'react';

import UserService 				from '../../../../platform/api/user'
import NotificationSystem from '../../../../platform/services/notificationSystem';
import Autocomplete       from '../../../../platform/components/Autocomplete'
import Card 				      from '../../../../platform/components/Card'

class ManagersInfoViewModel {
	isValid = true;
	managerIdList = '';
	managerName = '';

	errors = {
		managerName: false,
	}

	validate = () => {
		const errorsMessages = [];

		const ERRORS = {
			managerName: false,
		};

		if (!this.managerName) {
			errorsMessages.push('Manager Name field is required');
			ERRORS.managerName = true;
			this.isValid = false;
		}

		if (!this.isValid) {
			NotificationSystem.showNotification('Validation', errorsMessages[0]);
		}

		this.errors = ERRORS;
		return this.isValid;
	}
}

export default class ManagersInfo extends Component {

	state = {
		name				 : '',
		data         : new ManagersInfoViewModel(),
		managersList : [],
		selectedList : [],
	}

	componentDidMount() {
		const { info, name } = this.props;
		const { data } = this.state;
		if (info) {
			data.responsible = info.responsible;
			this.setState({ selectedList: info.managerList || [], data, name });
		} else {
			this.setState({ name });
		}
	}

	handleChange = ({ name, value }) => {
		const data = this.state.data;
		data[name] = value;
		this.setState({ data }, this.searchManagers);
	};

	searchManagers = () => {
		const { objectiveId, activityId, programId } = this.props;
		const { data, selectedList } = this.state;
		const excludeIdList = selectedList.map(user => user._id)
		UserService.ListAuto({
			excludeIdList,
			key: data.managerIdList,
			objectiveId: objectiveId || undefined,
			activityId: activityId || undefined,
			programId: programId || undefined,
		}).then(resp => {
			this.setState({
				managersList: resp.data || []
			})
		})
	}

	// ! The parent component calls this function.
	fullInfo = () => {
		const { data, selectedList } = this.state;
		this.props.changeData({ selectedList, responsible: data.responsible });
	}

	handleManagerClick = manager => {
		const { selectedList, data } = this.state;

		if (selectedList.length === 1) data.responsible = 0;

		this.setState({
			data,
			selectedList: [
				...selectedList,
				manager
			]
		}, () => {
			const data = this.state.data;
			data.managerIdList = ''
			this.setState({ data });
		});
	}

	cardClick = manager => {
		const { data, selectedList } = this.state;

		if (selectedList.length > 1) {
			data.responsible = selectedList.indexOf(manager);
			this.setState({ selectedList, data });
		}
	}

	deleteCard = manager => {
		const { data, selectedList } = this.state;
		const index = selectedList.indexOf(manager);
		
		selectedList.splice(index, 1);
		if (!selectedList.length) delete data.responsible;
		else data.responsible = 1;

		this.setState({ selectedList, data });
	}
	

	render() {
		const { error, objectiveId, withoutResponsible } = this.props;
		const { data, managersList, selectedList, name } = this.state;

		return (
			<div className={`App-manager-info-main ${objectiveId ? 'App-manager-info-main-full' : ''}`}>
				<Autocomplete
					title={`Add ${name}(s)`}
					name="managerIdList"
					value={data.managerIdList}
					required={error}
					errors={error}
					full={!!objectiveId}
					onChange={this.handleChange}
					suggestions={managersList}
					handleManagerClick={this.handleManagerClick}
				/>
				<div className={`App-manager-info ${objectiveId ? 'App-manager-info-full' : ''}`} id="margin_top_40">
					<div className="App-manager-info-managers">
						{selectedList.length ?
							<div
								className="App-manager-info-manager-item"
							>
								{selectedList.map((manager, index) =>
									<Card
										key={index}
										responsive={!withoutResponsible && (data.responsible === index || selectedList.length === 1)}
										property={manager}
										handleManagerClick={() => !withoutResponsible && this.cardClick(manager)}
										deleteClick={() => this.deleteCard(manager)}
										chosen
									/>
								)}
							</div>
							: null}
					</div>
				</div>
			</div>
		)
	}
}
