import NotificationSystem from '../../services/notificationSystem';

export class ProgramsMainViewModel {
  shownInWeb = true;
  shownInHome = true;
  startDate;
  endDate;
  managerIdList;
  cover;
  image;
  titleHy;
  titleEn;
  shortDescriptionHy;
  shortDescriptionEn;
  descriptionHy;
  descriptionEn;

  errors = {
    descriptionHy      : false,
    shortDescriptionHy : false,
    titleHy            : false,
    startDate          : false,
    managerIdList       : false,
  };

  constructor(data) {
    if (data) {
      this.cover = data.cover;
      this.image = data.image;
      this.titleHy = data.titleHy;
      this.titleEn = data.titleEn;
      this.shortDescriptionEn = data.shortDescriptionEn;
      this.shortDescriptionHy = data.shortDescriptionHy;
      this.descriptionEn = data.descriptionEn;
      this.descriptionHy = data.descriptionHy;
      this.managerIdList = data.managerIdList;
      if (this.responsible || this.responsible === 0) this.responsible = data.responsible;
      else delete this.responsible;
      this.startDate = data.startDate;
      this.endDate = data.endDate;
      this.shownInWeb = data.shownInWeb;
      this.shownInHome = data.shownInHome;
    }
  }

  getBaseModel = () => {
    return {
      cover              : this.cover,
      image              : this.image,
      titleEn            : this.titleEn,
      titleHy            : this.titleHy,
      shortDescriptionEn : this.shortDescriptionEn,
      shortDescriptionHy : this.shortDescriptionHy,
      descriptionEn      : this.descriptionEn,
      descriptionHy      : this.descriptionHy,
      managerIdList      : this.managerIdList,
      responsible        : this.responsible,
      startDate          : this.startDate,
      endDate            : this.endDate,
      shownInWeb         : this.shownInWeb,
      shownInHome        : this.shownInHome,
    }
  }

  validate = () => {
    let isValid = true;

    const errorsMessages = [];

    const ERRORS = {
      descriptionHy      : false,
      shortDescriptionHy : false,
      titleHy            : false,
      startDate          : false,
      managerIdList       : false,
    };

    if (!this.titleHy) {
      errorsMessages.push('Title field is required');
      ERRORS.titleHy = true;
      isValid = false;
    }

    if (!this.descriptionHy) {
      errorsMessages.push('Description field is required');
      ERRORS.descriptionHy = true;
      isValid = false;
    }

    if (!this.shortDescriptionHy) {
      errorsMessages.push('Short description field is required');
      ERRORS.shortDescriptionHy = true;
      isValid = false;
    }

    if (!this.managerIdList || !this.managerIdList.length) {
      errorsMessages.push('Add members is required');
      ERRORS.managerIdList = true;
      isValid = false;
    }

    if (!this.startDate) {
      errorsMessages.push('Start date is required');
      ERRORS.startDate = true;
      isValid = false;
    }

    if (!isValid) {
      NotificationSystem.showNotification('Validation', errorsMessages[0]);
    }

    this.errors = ERRORS;
    return isValid;
  }
}
