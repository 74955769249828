import { connection } from '../../services/connection';

const controllerName = 'Media';

export default class MediaService {

  //#region GET

  static GetImage = id => {
    return connection({
      url    : `${controllerName}/image?id=${id}`,
      method : 'GET',
    });
  }

  static GetVideo = id => {
    return connection({
      url    : `${controllerName}/video?id=${id}`,
      method : 'GET',
    });
  }

  static List = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}/image/adminList`,
      method : 'POST',
      body   : data
    });
  }

  static VideoList = (data = { pageNo: 1, limit: 20 }) => {
    return connection({
      url    : `${controllerName}/video/adminList`,
      method : 'POST',
      body   : data
    });
  }

  //#endregion

  //#region POST

  static UploadImage = data => {
    return connection({
      url         : `${controllerName}/image`,
      method      : 'POST',
      body        : data,
      contentType : undefined,
    });
  }

  static CreateVideo = data => {
    return connection({
      url         : `${controllerName}/video`,
      method      : 'POST',
      body        : data,
    });
  }

  //#endregion

  //#region PUT

  static EditImage = data => {
    return connection({
      url    : `${controllerName}/image`,
      method : 'PUT',
      body   : data
    });
  }

  static EditVideo = data => {
    return connection({
      url    : `${controllerName}/video`,
      method : 'PUT',
      body   : data
    });
  }

  //#endregion

  //#region DELETE

  static DeleteImage = ids => {
    return connection({
      url    : `${controllerName}/image`,
      method : 'DELETE',
      body   : { idList: ids },
    });
  }

  static DeleteVideo = ids => {
    return connection({
      url    : `${controllerName}/video`,
      method : 'DELETE',
      body   : { idList: ids },
    });
  }

  //#endregion
}