import React, { Component } from 'react';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';

import AppPageToolbar   from '../../../platform/components/PageToolbar';
import TableToolbar     from '../../../platform/components/table/TableToolbar';
import { getShortDate } from '../../../platform/services/helpers';
import { HISTORY }      from '../../../routes/ClientRoutes';
import { to }           from '../../../routes';
import UserItemService  from '../../../platform/api/userItem';
import settings from '../../../platform/services/settings';

const DragHandle = sortableHandle(() => <div style={{ cursor: 'pointer', fontSize: '30px' }}>:::</div>);
const SortableItem = sortableElement(({ onClick, value }) => <div className="App-user-item-table G-flex" onClick={onClick}>{value}</div>);
const SortableContainer = sortableContainer(({ children }) => <div>{children}</div>);


const Photo = ({path}) => (
  <img className="App-table-image" src={path} alt="Img"/>
);

export default class Users extends Component {

  state = {
    itemList: []
  }

  goToManagePage = (id = '') => {
    HISTORY.push(`${to.usersManage}?id=${id}`);
  }

  componentDidMount() {
    settings.emtyAllPageNos();
    this.getList();
  }

  getList = async() => {
    const result = await UserItemService.List();
    if (result && result.success) {
      this.setState({ itemList: result.data.itemList });
    }
  }
 
  onSortEnd = async({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      let { itemList } = this.state;
      const id = itemList[oldIndex]._id;
      const position = newIndex + 1;
      const result = await UserItemService.Position({ id, position });
      if (result && result.success) {
        this.setState({ itemList: result.data.itemList });
      }
    }
  };

  delete = async id => {
    const { itemList } = this.state;
    const res = await UserItemService.Delete({ idList: [id] });
    if (res.success) {
      if (itemList.length & this.pag)
      this.getList();
    }
  }

  getItem = (item) => {
    return (
      <div className="G-pl-2 G-pr-2 G-pt-1 G-pb-1 G-w-f G-flex G-align-center G-justify-between">
        <div className="App-user-item-start-end" title={item.position}>
          <DragHandle />
        </div>
        <div className="App-user-item">{item.image ? <Photo path={item.image}/> : '-'}</div>
        <div className="App-user-item">{item.nameEn ? item.nameEn : '-'}</div>
        <div className="App-user-item">{getShortDate(item.createdDt)}</div>
        <div className="App-user-item-start-end G-flex G-justify-end">
          <div className="G-flex">
            <i className="icon-edit G-mr-1" onClick={() => this.goToManagePage(item._id)} />
            <i className="icon-waste-can" onClick={() => this.delete(item._id)} />
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { itemList } = this.state;
    return (
      <div className="App-page">
        <AppPageToolbar>
          <TableToolbar title={'Web users list'}>
            <div className="G-flex">
              <button className="G-btn App-add-btn" onClick={() => this.goToManagePage()}>
                +Add new
              </button>
            </div>
          </TableToolbar>
        </AppPageToolbar>
        <div className="App-page-favorite-box">
          <div className="App-page-favorite-content App-user-item-table">
            <div className="G-p-2 G-flex G-justify-between">
              <div className="App-user-item-start-end short">
                Position
              </div>
              <div className="App-user-item">
                Photo
              </div>
              <div className="App-user-item">
                Name
              </div>
              <div className="App-user-item">
                Creation date
              </div>
              <div className="App-user-item-start-end G-flex G-justify-end">
                Action
              </div>
            </div>
            <div style={{ maxHeight: 'calc(100vh - 250px)' }}>
              <SortableContainer onSortEnd={this.onSortEnd} useDragHandle>
                {itemList.map((item, index) => <SortableItem 
                index={index}
                key={item._id}
                value={this.getItem(item)}/>)}
              </SortableContainer>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
