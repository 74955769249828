import React, { Component } from 'react';
import { Link }             from 'react-router-dom';

import { PaginationViewModel } from '../../../platform/view-models';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import TableToolbar            from '../../../platform/components/table/TableToolbar';
import NotificationService     from '../../../platform/api/notification';
import { to }                  from '../../../routes';
import settings from '../../../platform/services/settings';

export default class Notifications extends Component {

  state = {
    itemList        : [],
    pageCount       : 0,
    itemCount       : 0,
    loading         : false,
  }

  paginationData;

  componentDidMount() {
    settings.emtyAllPageNos();
    this.paginationData = new PaginationViewModel({ pageNo: 1, limit: 30 });
    this.getItemList();
  }

  getItemList = () => this.setState({ loading: true }, async () => {
    const res = await NotificationService.List(this.paginationData.getBaseModel());
    res.success && res.data && res.data.itemList && this.setState({
      itemList: [ ...this.state.itemList, ...res.data.itemList],
      itemCount: res.data.itemCount,
      pageCount: res.data.pageCount,
      loading: false,
    });
    setTimeout(() => window.changeNotificaionBadge && window.changeNotificaionBadge(false), 0);
    // window.changeNotificationBadge(false);
  });

  onInfiniteScroll = e => {
    const { loading, pageCount } = this.state;

    if (e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 200 &&
        pageCount !== this.paginationData.pageNo && !loading) {
      this.paginationData.pageNo++;
      this.getItemList();
    }
  }

  getURL = item => {
    const basicProgram = `${to.programs}?program=${item.programId}`;

    if (item.reportId || item.taskId) return `${basicProgram}&activity=${item.activityId}`;
    else if (item.activityId) return `${basicProgram}&objective=${item.objectiveId}`;
    else if (item.objectiveId) return `${basicProgram}&globalStep=${item.globalStepId}`;
    else if (item.globalStepId || item.programId) return basicProgram;    
  }

  render() {
    const { itemCount, itemList } = this.state;

    return <div className="App-page">
      <AppPageToolbar>
        <TableToolbar title={'Notifications'} count={itemCount} />
      </AppPageToolbar>
      <div className="App-page-content-box">
        <div className="App-page-content App-notifications-content" onScroll={this.onInfiniteScroll}>
          {itemList.map(item => <Link key={item._id} to={this.getURL(item)}>
            {item.message}
          </Link>)}
        </div>
      </div>
    </div>
  }
}