import React, { Component, Fragment } from 'react';
import classnames                     from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Table           from '@material-ui/core/Table';
import TableBody       from '@material-ui/core/TableBody';
import TableCell       from '@material-ui/core/TableCell';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow        from '@material-ui/core/TableRow';
import TableFooter     from '@material-ui/core/TableFooter';
import Checkbox        from '@material-ui/core/Checkbox';
import Switch          from '@material-ui/core/Switch';
import ContextMenu     from '../contextMenu';

import TableHeader      from './TableHeader';
import { getShortDate } from '../../services/helpers';
import { HISTORY } from '../../../routes/ClientRoutes';

const styles = () => ({
  root: {
    width: '100%',
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

const Photo = ({path}) => (
  <img className="App-table-image" src={path} alt="Img"/>
);

class AppTableCall extends Component {
  render() {
    const { row, item, changeSwitchStatus, clickItem, editClick, fullCellClick } = this.props;
    const isText = (!row.photo && !row.switch && !row.status && !row.date && !row.editIcon && !row.func);
    const value = item[row.key];

    return <TableCell
      align={!row.numeric ? 'left' : 'right'}
      onClick={() => fullCellClick && fullCellClick(item)}
      className={classnames({ 'G-cursor': fullCellClick })}
    >
      {isText && <span onClick={() => row.click ? clickItem && clickItem(item) : null} className={classnames({ 'G-cursor': row.click })}>
        {Array.isArray(value) ? value.join(', ') : value || (!row.numeric ? '-' : 0)}
      </span>}

      {row.photo && <Photo path={value} />}

      {row.switch && 
        <Switch
          checked={value}
          onClick={e => {
            e.stopPropagation();
              changeSwitchStatus && changeSwitchStatus(item._id)
          }}
        />}

      {row.status && <div className={classnames("App-table-status-item")}>
        <span style={{ backgroundColor: row.colorEnum ? row.colorEnum[value] : (value ? row.colorTrue : row.colorFalse) }}/>
        {row.statusEnum ? row.statusEnum[value] : (value ? row.statusTitleTrue : row.statusTitleFalse)}
      </div>}

      {row.date && <span>{value ? getShortDate(value) : '-'}</span>}
      {row.editIcon && <i className="icon-edit" onClick={e => { e.stopPropagation(); editClick(item); }} />}
      {row.func && row.func(item)}
    </TableCell>;
  }
}

class AppTable extends Component {

  state = {
    selected   : [],
    page       : 0,
    rowsPerPage: 10,
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.list.length && !this.queryChecked) {
      const query = new URLSearchParams(window.location.search);
      const page = +query.get('page');
      const rows = +query.get('rows');
      if (rows && rows !== this.state.rowsPerPage) this.handleChangeRowsPerPage({ target: { value: rows }});
      if (page && page - 1 !== this.state.page) this.handleChangePage(null, page - 1);
      this.queryChecked = true;
    }

    if (nextProps.clearSelectedItem && this.state.selected && this.state.selected.length) {
      this.setState({ selected: [] });
    }
  }

  handleSelectAllClick = event => {
    if (event.target.checked) {
      this.setState(state => ({ selected: this.props.list.map(n => n._id) }), () => {
        this.props.getSelectedItems(this.state.selected);
      });
      return;
    }
    this.setState({ selected: [] });
    this.props.getSelectedItems([]);
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setState({ selected: newSelected });
    this.props.getSelectedItems(newSelected);
  };

  handleChangePage = (event, page) => {
    const query = new URLSearchParams(window.location.search);
    query.set('page', page + 1);
    HISTORY.replace(`${window.location.pathname}?${query.toString()}`)

    this.props.changePage(page + 1);
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    // console.log(event.target.value);
    // const query = new URLSearchParams(window.location.search);
    // query.set('rows', event.target.value);
    // HISTORY.replace(`${window.location.pathname}?${query.toString()}`)

    this.props.changeLimit(event.target.value);
    this.setState({
      rowsPerPage : +(event.target.value),
      page        : 0
    });
  };

  isSelected = id => this.state.selected.indexOf(id) !== -1;

  render() {
    const {
      classes,
      rows,
      list = [],
      changeSwitchStatus,
      clickItem,
      editClick,
      fullCellClick,
      withoutPaging,
      itemCount,
      hideCheckbox,
      contextMenu,
      rowTitle,
    } = this.props;

    const { selected, rowsPerPage, page } = this.state;
    return (
      <Table className={classes.table} aria-labelledby="tableTitle">
        <TableHeader
          rows={rows}
          rowCount={list.length}
          hideCheckbox={hideCheckbox}
          numSelected={selected.length}
          onSelectAllClick={this.handleSelectAllClick}
        />

        <TableBody>
          {list.map(n => {
            const isSelected = this.isSelected(n._id);

            return <Fragment key={n._id}>
              <TableRow
                hover
                title={rowTitle ? rowTitle(n) : undefined}
                aria-checked={isSelected}
                tabIndex={-1}
                key={n._id}
                selected={isSelected}
                id={'tableRow' + n._id}>

                {!hideCheckbox && <TableCell padding="checkbox">
                  <Checkbox 
                    onClick={event => this.handleClick(event, n._id)}
                    checked={isSelected} />
                </TableCell>}

                {rows.map((row, rowIndex) => <AppTableCall
                  key={rowIndex} row={row} item={n}
                  clickItem={item => clickItem && clickItem(item)}
                  editClick={item => editClick && editClick(item)}      
                  fullCellClick={item => fullCellClick && fullCellClick(item)}                                                
                  changeSwitchStatus={id => changeSwitchStatus && changeSwitchStatus(id)}
                />)}
              </TableRow>
              
              {contextMenu && <ContextMenu
                  customId={`contextMenuTable${n._id}`}
                  contextId={'tableRow' + n._id}
                  items={contextMenu(n)}
              />}
            </Fragment>;
          })}
        </TableBody>

        {!withoutPaging && <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 20, 30]}
              rowsPerPage={rowsPerPage}
              count={itemCount}
              page={page}
              SelectProps={{ native: true }}
              onChangePage={this.handleChangePage}
              onChangeRowsPerPage={this.handleChangeRowsPerPage}
            />
          </TableRow>
        </TableFooter>}
      </Table>
    );
  }
}

export default withStyles(styles)(AppTable);