import React, { Component } from 'react';
import classnames           from 'classnames';

import DropDown        from '../../../../platform/components/DropDown';
import ProgramsService from '../../../../platform/api/programs';

export default class ProgramDropDown extends Component {

  userChoose = false;

  state = {
    list     : [],
    selected : {
      name : null,
      _id  : null
    }
  }

  getList = async () => {
    const res = await ProgramsService.ShortList();
    if (res.success && res.data) this.setState({ list: res.data });
  }

  onSelect = selected => {
    this.userChoose = true;
    this.setState({ selected });
    this.props.output && this.props.output(selected._id);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.userChoose && nextProps.selected && this.state.selected && nextProps.selected._id !== this.state.selected._id) {
      this.setState({ selected: nextProps.selected });
    }
  }

  componentDidMount() {
    this.getList();
  }

  render() {
    const { list, selected } = this.state;
    return (
      <div className="G-input-box">
        <p>Program</p>
        <DropDown
          bodyClickDisabled={false}
          Header={() => <div>{selected.name || ''}</div>}
          Body={() => <div className="App-drop-down-list">
            {list.map((item, index) => (
              <div className={classnames({ 'active': selected._id === item._id })} key={index} onClick={(() => this.onSelect(item))}>
                <p>{item.name || '-'}</p>
              </div>
            ))}
          </div>}
        />
      </div>
    )
  }
}
