export const tableRows = [
  {
    key   : 'image',
    label : 'Photo',
    photo : true,
  },
  {
    key   : 'title',
    label : 'Title',
    click : true,
  },
  {
    key   : 'createdDt',
    date  : true,
    label : 'Created date',
  },
  {
    key              : 'isActive',
    label            : 'Is active',
    status           : true,
    statusTitleTrue  : 'Active',
    statusTitleFalse : 'Inactive',
    colorTrue        : 'green',
    colorFalse       : 'red'
  },
];

export const actionType = {
  homePage : 1,
  slide    : 2,
  active   : 3,
  date     : 4,
  isNews   : 5,
}
