const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const shortDate = date => {
	if (date) {
		const localDate = new Date(date);
		return `${addZero(localDate.getDate())} ${monthNames[localDate.getMonth()]}`;
	}
};

const addZero = i => {
	if (i < 10) {
		i = '0' + i;
	}
	return i;
};

export const hoursDate = date => {
	if (date) {
    const d = new Date(date);
    const h = addZero(d.getHours());
    const m = addZero(d.getMinutes());
    return h + ':' + m;
  }
};

export const getShortDate = (date) => {
  const newDate = new Date(date);
  return `${addZero(newDate.getDate())} ${monthNames[newDate.getMonth()]} ${newDate.getFullYear()} ${hoursDate(newDate)}`;
};
