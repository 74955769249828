export const tableRows = [
  {
    key   : 'cover',
    label : 'Video',
    photo : true,
  },
  {
    key   : 'nameEn',
    label : 'English name',
  },
  {
    key   : 'nameHy',
    label : 'Armenian name',
  },
  {
    key   : 'createdDt',
    date  : true,
    label : 'Created at',
  },
  {
    key      : '',
    editIcon : true,
    label    : 'Actions',
  },
];
