import React, { Component }  from 'react';
import classnames            from 'classnames';

import Action                  from './components/Action';
import AddFiles                from './components/AddFiles';
import Images                  from './components/Images';
import EventInfo               from './components/EventInfo';
import ProgramDropDown         from './components/ProgramDropDown';
import { actionType }          from './services/constant';
import { HISTORY }             from '../../../routes/ClientRoutes';
import { LanguageEnum }        from '../../../platform/enums';
import { languageCodeList }    from '../../../platform/services/constant';
import AppPageToolbar          from '../../../platform/components/PageToolbar';
import LanguageSelector        from '../../../platform/components/LanguageSelector';
import MultiImageUpload        from '../../../platform/components/MultiImageUpload';
import DatePicker              from '../../../platform/components/DatePicker';
import NewsService             from '../../../platform/api/news';
import { NewsMainViewModel }   from '../../../platform/api/news/view-model';

export default class NewsManage extends Component {

  draftId; newsId; enInfo; hyInfo; hyFiles; enFiles; deletedImageList;
  mainImage; coverImage; uploadedFilesHy; uploadedFilesEn; deletedFileList

  state = {
    selectedLanguage : LanguageEnum.hy,
    data             : new NewsMainViewModel(),
    imagesUrl        : []
  }

  getDetails = async () => {
    const res = await NewsService.Get(this.newsId);
    if (res.success && res.data) {
      const data = new NewsMainViewModel(res.data);
      const imagesUrl = res.data.images;
      this.mainImage = res.data.image;
      this.coverImage = res.data.cover;
      this.uploadedFilesHy = res.data.filesHy;
      this.uploadedFilesEn = res.data.filesEn;
      data.mainImage = null;
      data.cover = null;
      data.image = null;
      this.setState({ data, imagesUrl });
    } else HISTORY.goBack();
  }

  //#region Info

  getFullInfoEn = info => {
    const data = this.state.data;
    data.titleEn = info.title;
    data.descriptionEn = info.description;
    data.shortDescriptionEn = info.shortDescription;
    this.setState({ data });
  }

  getFullInfoHy = info => {
    const isValid = info.isValid;
    if (isValid) {
      const data = this.state.data;
      data.titleHy = info.title;
      data.descriptionHy = info.description;
      data.shortDescriptionHy = info.shortDescription;
      this.setState({ data });
    } else {
      this.setState({ selectedLanguage: LanguageEnum.hy });
    }
  }

  //#endregion

  getMainImages = images => {
    const data = this.state.data;
    if (typeof images.mainImage !== 'string') data.mainImage = images.mainImage;
    if (!images.cover) {
      data.deleteCover = true;
    } else if (typeof images.cover !== 'string') {
      data.cover = images.cover;
    }
    this.setState({ data });
  }

  getImages = images => {
    const data = this.state.data;
    data.image = images;
    this.setState({ data });
  }

  getDate = (key, date) => {
    const data = this.state.data;
    data[key] = date ? new Date(date) : null;
    this.setState({ data });
  }

  getActionType = obj => {
    const data = this.state.data;
    const value = obj.value;
    switch (obj.type) {
      case actionType.active:
        data.isActive = value;
        break;
      case actionType.homePage:
        data.shownInHome = value;
        break;
      case actionType.slide:
        data.shownInSlider = value;
        break;
      case actionType.date:
        data.dateIsShown = value;
        break;
      default:
        break;
    }
    this.setState({ data });
  }

  getHyFiles = files => this.hyFiles = files;
  getEnFiles = files => this.enFiles = files;

  uploadFiles = async (fileList) => {
    let isValid = true;
    const files = fileList.map(file => file.getBaseModel());
    files.forEach(async baseModel => {
      const formData = new FormData();
      formData.append('newsId', this.newsId || this.draftId);
      for (const key in baseModel) {
        const item = baseModel[key];
        if (baseModel.hasOwnProperty(key)) {
          item && formData.append(key, item);
        }
      }
      const res = await NewsService.File(formData);
      if (!res.success) {
        isValid = false;
        // TODO - show error message - baseModel.name file upload error
      }
    });
    return isValid;
  }

  getDeletedImagesId = (ids, images) => {
    this.deletedImageList = ids;
    this.imagesUrl = images;
  }

  getDeletedFiles = (ids) => {
    this.deletedFileList = ids;
  }

  getProgramId = id => {
    const data = this.state.data;
    data.programId = id;
    this.setState({ data });
  }

  save = async () => {
    this.enInfo.fullInfo();
    this.hyInfo.fullInfo();

    const data = this.state.data;
    data.deletedImageList = this.deletedImageList;
    data.deletedFileList = this.deletedFileList;
    if (data.validate()) {
      const formData = new FormData();
      const baseModel = data.getBaseModel();
      baseModel.id = this.newsId || this.draftId;
      for (const key in baseModel) {
        if (baseModel.hasOwnProperty(key)) {
          const item = baseModel[key];
          if (item) {
            if (key === 'image' && item.length) {
              item.forEach((img, index) => {
                formData.append(key, img);
              });
            } else if ((key === 'deletedImageList' || key === 'deletedFileList') && item.length) {
              item.forEach((img, index) => {
                formData.append(`${key}[${index}]`, img);
              });
            } else {
              formData.append(key, item);
            }
          } else if (key === 'isActive' || key === 'shownInHome' || key === 'shownInSlider'|| key === 'dateIsShown') {
            formData.append(key, item);
          }
        }
      }
      const res = await Promise.all([
        NewsService.Main(formData),
        this.hyFiles && this.uploadFiles(this.hyFiles),
        this.enFiles && this.uploadFiles(this.enFiles),
      ]);

      if (res[0] && res[0].success) {
        const response = await NewsService.Save({ id: baseModel.id });
        if (response.success) HISTORY.goBack();
        // NotificationSystem.showNotification('Message', Messages.registrationSuccessMessage.en);
      } else {
        // NotificationSystem.showNotification('Message', res.message);
      }
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(HISTORY.location.search);
    this.draftId = params.get('draftId');
    this.newsId = params.get('id');
    if (this.newsId) this.getDetails();
  }

  render() {
    const { data, selectedLanguage, imagesUrl } = this.state;
    return (
      <div className="App-page App-add-page-news-event">
        <AppPageToolbar className="G-pl-2 G-pr-2 G-flex G-justify-between">
          <h6>News/ <span className="G-page-active-title">Add news</span></h6>
          <div className="G-flex G-align-center">
            <LanguageSelector
              languageList={languageCodeList}
              selectedLanguage={selectedLanguage}
              selectLanguage={value => this.setState({ selectedLanguage: value })}
            />
            <button className="G-btn App-save-btn G-ml-4" onClick={this.save}>Save</button>
          </div>
        </AppPageToolbar>
        <div className="App-page-content-box">
          <div className="App-page-content">

            <div className="block App-manage-event-news-image">
              <Images mainImage={this.mainImage} cover={this.coverImage} output={this.getMainImages}/>
            </div>

            <div className="line"/>

            <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
              <EventInfo
                info={{
                  title            : data.titleEn,
                  description      : data.descriptionEn,
                  shortDescription : data.shortDescriptionEn,
                }}
                ref={instance => { this.enInfo = instance }}
                getFullInfo={this.getFullInfoEn}
              />
            </div>

            <div className={classnames('G-hide', { 'G-show': selectedLanguage === LanguageEnum.hy })}>
              <EventInfo
                requiredFields
                info={{
                  title            : data.titleHy,
                  description      : data.descriptionHy,
                  shortDescription : data.shortDescriptionHy,
                }}
                ref={instance => { this.hyInfo = instance }}
                getFullInfo={this.getFullInfoHy} 
              />
            </div>

            <div className="App-manage-event-location-block G-flex G-justify-between G-half-input-box">
              <DatePicker
                title="Date"
                defaultValue={data.date}
                error={data.errors.date}
                onChange={date => this.getDate('date', date)}
              />
            </div>

            <div className="line"/>

            <div className="G-flex G-p-2">
              <ProgramDropDown selected={{
                _id  : data.programId,
                name : data.programName,
              }} output={this.getProgramId}/>
            </div>
            
            <div className="line"/>

            <div className={classnames('block', 'G-hide', { 'G-show': selectedLanguage === LanguageEnum.en })}>
              <AddFiles
                uploadedFiles={this.uploadedFilesEn}
                outputDeletedFiles={ids => this.getDeletedFiles(ids)}
                language={LanguageEnum.en}
                output={this.getEnFiles}
              />
            </div>

            <div className={classnames('block', 'G-hide', { 'G-show': selectedLanguage === LanguageEnum.hy })}>
              <AddFiles
                uploadedFiles={this.uploadedFilesHy}
                outputDeletedFiles={ids => this.getDeletedFiles(ids)}
                language={LanguageEnum.hy}
                output={this.getHyFiles}
              />
            </div>

            <div className="line"/>

            <div className="block">
              <div className="G-flex G-mb-2">
                <h6 className="title">Add image(s)</h6>
              </div>
              <MultiImageUpload
                images={imagesUrl}
                uniqueKey="multi-image-upload"
                output={this.getImages}
                getDeletedImagesId={this.getDeletedImagesId}
              />
            </div>

            <div className="line"/>

            <div className="block">
              <Action
                showDateSwitch
                defaultValue={true}
                isActive={data.isActive}
                shownInHome={data.shownInHome}
                shownInSlider={data.shownInSlider}
                dateIsShown={data.dateIsShown}
                onChange={this.getActionType}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}