export const NotificationEnum = {
  Success: 'success',
  Error  : 'error',
  Warning: 'warning',
  Info   : 'info'
};

export const LanguageEnum = {
  hy: 1,
  en: 2
};

export const UserTypeEnum = {
  user       : 1,
  admin      : 2,
  superAdmin : 3,
};

export const ProgramStepsEnum = {
  program: 1,
  globalStep: 2,
  objective: 3,
  activity: 4,
  task: 5,
  report: 6
};